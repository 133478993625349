import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import reducers from './redux/reducers';
import sagas from './redux/sagas';
import { createLogger } from 'redux-logger';

import { Iterable } from 'immutable';

const stateTransformer = (state) => {
  if (Iterable.isIterable(state.appState)) return state.appState.toJS();
  else return state.appState;
};

const reduxLoggerMiddleware = createLogger({
  collapsed: true,
  stateTransformer,
});
const sagaMiddleware = createSagaMiddleware();

// create a store with middlewares
const createStoreWithMiddleware = applyMiddleware(
  thunkMiddleware,
  sagaMiddleware,
  reduxLoggerMiddleware
)(createStore);
const store = createStoreWithMiddleware(reducers);

sagaMiddleware.run(sagas);

const origDispatch = store.dispatch;
store.dispatch = function (action) {
  if (!action.type) {
    action.type = '';
  }
  origDispatch(action);
};

export default store;
