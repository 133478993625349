import React from 'react';
import { useContext } from 'react';
import { getMilestoneName } from '../../../../utils';
import { WorkspaceContext } from '../../../../utils/contexts';
import { WS_DropdownIcon } from '../../../UtilComponents/Icons';
import { WSPopover } from '../../../UtilComponents/MUICustom';

export default function MilestoneSelectorMenu(props) {
  let {
    selected,
    handleUpdate,
    headerText,
    buttonDecorators = '',
    icon,
    type = 'button',
  } = props;

  let { allMilestonesMap } = useContext(WorkspaceContext);

  let currentMilestone = null;
  if (selected) {
    currentMilestone = allMilestonesMap[selected];
  }

  let currentMilestoneName = getMilestoneName(currentMilestone);

  return (
    <WSPopover
      ContentComponent={DropdownOptions}
      headerText={headerText}
      handleUpdate={handleUpdate}
      selected={selected}
      anchorDecorationClass="cm"
      decoratorClass="ws-surface-2"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {type === 'button' ? (
        <div
          className={`ws-dropdown-button flex items-center gap-1 ${buttonDecorators}`}
        >
          {icon ? { icon } : null}
          <div
            className={`flex-auto button-text ellipsis ${
              !selected ? 'unset' : ''
            }`}
          >
            {currentMilestoneName}
          </div>
          <div className="dropdown-icon">
            <WS_DropdownIcon aria-hidden="true" />
          </div>
        </div>
      ) : (
        <div className={`${buttonDecorators}`}>{currentMilestoneName}</div>
      )}
    </WSPopover>
  );
}

function DropdownOptions(props) {
  let { headerText, handleUpdate, handleClose, selected } = props;
  let { orderedMilestones, allMilestonesMap } = useContext(WorkspaceContext);

  function handleChange(newMilestoneId) {
    return (e) => {
      if (newMilestoneId !== selected) {
        handleUpdate(newMilestoneId);
      }
      handleClose(e);
    };
  }

  let showUnplannedOption = !!selected;

  let myMilestoneIds = orderedMilestones.filter(
    (eachMilestoneId) => allMilestonesMap[eachMilestoneId].isMine
  );

  let showEmptyMessage = false;
  if (!showUnplannedOption && !myMilestoneIds.length) {
    showEmptyMessage = true;
  }

  return (
    <div className="ws-dropdown">
      <div className="ws-dropdown-list member-dropdown">
        {headerText ? (
          <div className="dropdown-header">{headerText}</div>
        ) : null}
        {showEmptyMessage ? (
          <div className="text-center">No milestones to show</div>
        ) : null}
        {showUnplannedOption ? (
          <div
            className={`${
              !selected ? 'font-medium active' : ''
            } italic cursor-default select-none ws-dropdown-option px-4 py-2`}
            value={null}
            onClick={handleChange(null)}
          >
            Unplanned
          </div>
        ) : null}
        {myMilestoneIds.map((eachMilestoneId) => {
          let eachMilestone = allMilestonesMap[eachMilestoneId];
          if (selected === eachMilestoneId) {
            return null;
          }

          return (
            <div
              key={eachMilestone.id}
              className={`${
                selected === eachMilestone.id ? 'font-medium active' : ''
              } cursor-default select-none ws-dropdown-option px-4 py-2`}
              value={eachMilestone.id}
              onClick={handleChange(eachMilestone.id)}
            >
              {eachMilestone.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}
