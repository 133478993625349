import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import Autolink from '@ckeditor/ckeditor5-link/src/autolink.js';
import Autosave from '@ckeditor/ckeditor5-autosave/src/autosave.js';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder.js';
import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js';
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code.js';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle.js';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js';
import Mention from '@ckeditor/ckeditor5-mention/src/mention.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';
import Title from '@ckeditor/ckeditor5-heading/src/title.js';
import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui';
import ParagraphButtonUI from '@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui';

export class WSBaseEditor_Desc extends ClassicEditor {
  // Plugins to include in the build.
  static builtinPlugins = [
    Alignment,
    Autoformat,
    Autolink,
    Autosave,
    BlockQuote,
    Bold,
    CKFinder,
    CKFinderUploadAdapter,
    CloudServices,
    Code,
    CodeBlock,
    Essentials,
    // FontBackgroundColor,
    // FontColor,
    Heading,
    // HorizontalLine,
    Image,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    ListStyle,
    Mention,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    Strikethrough,
    // Table,
    // TableCellProperties,
    // TableProperties,
    // TableToolbar,
    TextTransformation,
    Underline,
  ];

  static getCustomConfig() {
    const editorConfiguration = {
      toolbar: {
        items: [
          'paragraph',
          'heading1',
          'bulletedList',
          'numberedList',
          'codeBlock',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'blockQuote',
          '|',
          'link',
          'imageUpload',
          '|',
          'alignment',
          'outdent',
          'indent',
          '|',
          'removeFormat',
        ],
      },
      plugins: WSBaseEditor_Desc.getCustomPlugins(),
      language: 'en',
      image: {
        styles: ['alignLeft', 'alignCenter', 'alignRight'],
        toolbar: [
          'imageStyle:alignLeft',
          'imageStyle:alignCenter',
          'imageStyle:alignRight',
          '|',
          'resizeImage',
        ],
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties',
        ],
      },
      codeBlock: {
        languages: [
          { language: 'plaintext', label: '' }, // The default language.
        ],
      },
      link: {
        addTargetToExternalLinks: true,
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'P',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h2',
            icon:
              '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="0.8em" width="0.8em" xmlns="http://www.w3.org/2000/svg"><path d="M448 96v320h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H320a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V288H160v128h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V96H32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16h-32v128h192V96h-32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16z"></path></svg>',
            title: 'H',
            class: 'ck-heading_heading',
          },
        ],
      },
    };

    return editorConfiguration;
  }

  static getCustomPlugins() {
    let allPlugins = [
      ...WSBaseEditor_Desc.builtinPlugins,
      Base64UploadAdapter,
      HeadingButtonsUI,
      ParagraphButtonUI,
    ];

    return allPlugins;
  }
}

export class WSBaseEditor_Doc extends ClassicEditor {
  // Plugins to include in the build.
  static builtinPlugins = [
    Alignment,
    Autoformat,
    Autolink,
    Autosave,
    BlockQuote,
    Bold,
    CKFinder,
    CKFinderUploadAdapter,
    CloudServices,
    Code,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    Heading,
    HorizontalLine,
    Image,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    ListStyle,
    Mention,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    Strikethrough,
    Table,
    TableCellProperties,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Title,
    Underline,
  ];

  static getCustomConfig() {
    const editorConfiguration = {
      toolbar: {
        toolbarLocation: 'bottom',
        items: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'paragraph',
          'heading1',
          'heading2',
          'heading3',
          'bulletedList',
          'numberedList',
          'codeBlock',
          '|',
          'blockQuote',
          '|',
          'horizontalLine',
          'link',
          'insertTable',
          'imageUpload',
          '|',
          'alignment',
          'outdent',
          'indent',
          '|',
          'removeFormat',
        ],
      },
      plugins: WSBaseEditor_Doc.getCustomPlugins(),
      language: 'en',
      image: {
        styles: ['alignLeft', 'alignCenter', 'alignRight'],
        toolbar: [
          'imageStyle:alignLeft',
          'imageStyle:alignCenter',
          'imageStyle:alignRight',
          '|',
          'resizeImage',
        ],
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties',
        ],
      },
      codeBlock: {
        languages: [
          { language: 'plaintext', label: '' }, // The default language.
        ],
      },
      link: {
        addTargetToExternalLinks: true,
      },
      title: {
        placeholder: 'Title',
      },
      placeholder: 'Body',
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'P',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h2',
            title: 'H1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h3',
            title: 'H2',
            class: 'ck-heading_heading2',
          },
          {
            model: 'heading3',
            view: 'h4',
            title: 'H3',
            class: 'ck-heading_heading3',
          },
        ],
      },
    };

    return editorConfiguration;
  }

  static getCustomPlugins() {
    let allPlugins = [
      ...WSBaseEditor_Doc.builtinPlugins,
      Base64UploadAdapter,
      HeadingButtonsUI,
      ParagraphButtonUI,
    ];

    return allPlugins;
  }
}

export class WSBaseEditor_Full extends ClassicEditor {
  // Plugins to include in the build.
  static builtinPlugins = [
    Alignment,
    Autoformat,
    Autolink,
    Autosave,
    BlockQuote,
    Bold,
    CKFinder,
    CKFinderUploadAdapter,
    CloudServices,
    Code,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    Heading,
    HorizontalLine,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    ListStyle,
    MediaEmbed,
    Mention,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    Strikethrough,
    Table,
    TableCellProperties,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Title,
    TodoList,
    Underline,
  ];

  static getCustomConfig() {
    const editorConfiguration = {
      toolbar: {
        items: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'fontColor',
          'fontBackgroundColor',
          'alignment',
          'outdent',
          'indent',
          'removeFormat',
          '|',
          // 'heading',
          'paragraph',
          'heading1',
          'heading2',
          'heading3',
          'bulletedList',
          'todoList',
          'numberedList',
          'codeBlock',
          '|',
          'blockQuote',
          '|',
          'horizontalLine',
          'link',
          'insertTable',
          'mediaEmbed',
          'imageUpload',
          '|',
          'undo',
          'redo',
        ],
      },
      plugins: WSBaseEditor_Doc.getCustomPlugins(),
      language: 'en',
      image: {
        toolbar: [
          'imageTextAlternative',
          'imageStyle:alignLeft',
          'imageStyle:alignCenter',
          'imageStyle:alignRight',
        ],
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties',
        ],
      },
      codeBlock: {
        languages: [
          { language: 'plaintext', label: '' }, // The default language.
          // { language: 'plaintext', label: 'Plain text' }, // The default language.
          // { language: 'c', label: 'C' },
          // { language: 'cs', label: 'C#' },
          // { language: 'cpp', label: 'C++' },
          // { language: 'css', label: 'CSS' },
          // { language: 'diff', label: 'Diff' },
          // { language: 'html', label: 'HTML' },
          // { language: 'java', label: 'Java' },
          // { language: 'javascript', label: 'JavaScript' },
          // { language: 'php', label: 'PHP' },
          // { language: 'python', label: 'Python' },
          // { language: 'ruby', label: 'Ruby' },
          // { language: 'typescript', label: 'TypeScript' },
          // { language: 'xml', label: 'XML' },
        ],
      },
      link: {
        addTargetToExternalLinks: true,
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'P',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h2',
            title: 'H1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h3',
            title: 'H2',
            class: 'ck-heading_heading2',
          },
          {
            model: 'heading3',
            view: 'h4',
            title: 'H3',
            class: 'ck-heading_heading3',
          },
        ],
      },
    };

    return editorConfiguration;
  }

  static getCustomPlugins() {
    let allPlugins = [
      ...WSBaseEditor_Full.builtinPlugins,
      Base64UploadAdapter,
      HeadingButtonsUI,
      ParagraphButtonUI,
    ];

    return allPlugins;
  }
}
