import React from 'react';
import {
  AppContext,
  PageContext,
  WorkspaceContext,
} from '../../utils/contexts';
import { useContext } from 'react';
import { WSSuccessSnackBar, WSAvatar } from './MUICustom';
import { WS_CloseIcon, WS_EditIcon, WS_Tick } from './Icons';
import { MOMENT_CUSTOM_CAL, SCHEDULE_SLOT_KEYS } from '../../utils/timeUtils';
import { NAV_KEYS, PAGES } from '../../utils/consts';
import BasicList from '../MemberArea/Components/Common/BasicList/BasicList';
import moment from 'moment';
import { getMilestoneName } from '../../utils';

export function ItemSuccessSnackBar(props) {
  let { params, setParams } = props;

  if (!params.workId) {
    return null;
  }

  function close() {
    setParams({ workId: null, message: '' });
  }

  return (
    <WSSuccessSnackBar open={true} close={close}>
      {params.message}
    </WSSuccessSnackBar>
  );
}

export function GenericSuccessSnackBar(props) {
  let { message, setMessage } = props;

  let open = Boolean(message);

  function close() {
    setMessage(null);
  }

  return (
    <WSSuccessSnackBar open={open} close={close}>
      {message}
    </WSSuccessSnackBar>
  );
}

export function SaveCancel(props) {
  let { isSubmitting, handleCancel } = props;

  return (
    <div className="flex gap-2">
      <button
        type="submit"
        className="tiny-save-button cm active"
        disabled={isSubmitting}
        onClick={(e) => e.stopPropagation()}
      >
        <WS_Tick style={{ fontSize: '1.3rem' }} />
      </button>
      {handleCancel ? <SquareCancelButton handleCancel={handleCancel} /> : null}
    </div>
  );
}

export function SquareCancelButton(props) {
  let { handleCancel, decorators = '' } = props;
  return (
    <button
      type="button"
      onClick={handleCancel}
      className={`tiny-cancel-button ${decorators}`}
    >
      <WS_CloseIcon style={{ fontSize: '1.3rem' }} />
    </button>
  );
}

export function Person(props) {
  let { membersMap } = useContext(WorkspaceContext);

  let { id, decorators = '' } = props;

  let name = '?';
  let member = membersMap[id];
  if (member) {
    name = member.relativeName;
  } else {
    // eslint-disable-next-line no-console
    console.warn('Member id', id, 'not found');
    // eslint-disable-next-line no-console
  }

  return (
    <div className={`person-name overflow-hidden ${decorators}`}>{name}</div>
  );
}

export function NoItemsInList() {
  return <div className="nothing-here cm">No Items</div>;
}

export function NoData() {
  return <div className="nothing-here cm">No Data</div>;
}

export function SectionHeader(props) {
  let { title } = props;

  return (
    <div className={'section-header'}>
      <span className="title">{title}</span>
    </div>
  );
}

export function ListGroupHeader(props) {
  let { title, itemCount } = props;

  return (
    <div className={`list-group-header ${!itemCount ? 'zero-items' : ''}`}>
      {title}&nbsp;({itemCount})
    </div>
  );
}

export function NotFound() {
  return (
    <div className="grid-y grid-frame cm">
      <div className="cell text-center text-xl">Not found</div>
    </div>
  );
}

export function SomeError() {
  return (
    <div className="grid-y grid-frame cm">
      <div className="cell text-center text-xl">Error!</div>
    </div>
  );
}

export function PersonAvatar(props) {
  function stringToHslColor(str) {
    let s = 40;
    let l = 65;

    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  let { id, large, xs, group } = props;

  if (!large) {
    large = false;
  }

  let size = 3.5;
  let fontSize = '.7rem';
  if (large) {
    size = 4.5;
    fontSize = '1rem';
  }

  if (xs) {
    size = 2.5;
  }

  let { membersMap } = useContext(WorkspaceContext);

  let member = membersMap[id];
  let memberEmail = member.email;

  let selfColor = stringToHslColor(memberEmail);

  return (
    <div>
      <WSAvatar
        size={size}
        fontSize={fontSize}
        selfColor={selfColor}
        group={group}
      >
        {memberEmail[0].toUpperCase()}
      </WSAvatar>
    </div>
  );
}

export function getDueByJSX(
  scheduleKey,
  scheduleTimestamp,
  todayStartTimeClient,
  scheduleSlotMap
) {
  if (SCHEDULE_SLOT_KEYS.UNSCHEDULED === scheduleKey) {
    return <>--</>;
  } else {
    return (
      <span
        className={`due-date ${
          scheduleTimestamp < todayStartTimeClient ? 'past' : ''
        }`}
      >
        {scheduleSlotMap[scheduleKey].text}
      </span>
    );
  }
}

export function ComingSoon() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div
        className="text-8xl font-semibold"
        style={{ transform: 'rotate(-20deg)' }}
      >
        COMING SOON
      </div>
    </div>
  );
}

export function WelcomeMessage() {
  return (
    <div className="home-welcome grid-x gap-6 items-center">
      <div className="cell auto flex flex-col gap-2">
        <p>Hi! Welcome to WorkStudio.</p>
        <p>
          Create tasks using the{' '}
          <span className="font-bold">"New Item" button on the left</span> and
          they will appear on the{' '}
          <span className="font-bold">list section on the right</span>.
        </p>
        <p>
          This middle section has all the filters. Click the cards below to
          filter things quickly and get to exactly what you are looking for.
        </p>
        <p>
          Rest of the things are fairly simple. Don't hesitate to reach us at{' '}
          <span className="font-bold">support@workstudio.app</span> if you would
          like a demo or any other help.
        </p>
        <div className="ps">
          PS: I will be on my way as soon as you create your first item.
        </div>
      </div>
    </div>
  );
}

export function WS_ToolTip(props) {
  let {
    children,
    contents,
    placement = 'right',
    open = false,
    className = '',
  } = props;

  return (
    <div className={'tooltip-container relative inline'}>
      {children}
      <div
        className={`tooltip flex flex-col justify-center items-center absolute ${placement} ${
          open ? '' : 'invisible'
        } ${className}`}
      >
        {contents}
      </div>
    </div>
  );
}

export function ScheduledItemsListOld() {
  let { scheduleSlotMap } = useContext(AppContext);
  let { groupedListEntries: groupedItems } = useContext(PageContext);

  return (
    <div className="flex flex-col">
      {Object.values(scheduleSlotMap).map((scheduleSlot) => {
        let title = scheduleSlot.text.toUpperCase();

        let items = groupedItems[scheduleSlot.key];
        if (!items) {
          return null;
        }

        return (
          <div className="relative" key={scheduleSlot.key}>
            <ListGroupHeader title={title} itemCount={items.length} />
            <div className={`${items.length ? 'mb-12' : 'mb-4'}`}>
              <BasicList
                itemIds={items}
                hideZeroText={true}
                listContext={scheduleSlot.key}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export function ScheduledItemsList() {
  let { groupedListEntries: groupedItems } = useContext(PageContext);

  let itemList = groupedItems[NAV_KEYS.SCHEDULED.key][NAV_KEYS.ALL_ITEMS.key];

  return (
    <div className="relative flex flex-col gap-4">
      <MissingItemPlaceholder itemList={itemList} />
      <NoItems itemList={itemList} navKey={NAV_KEYS.SCHEDULED.key} />
      <div key={NAV_KEYS.SCHEDULED.key}>
        <BasicList
          itemIds={itemList}
          hideZeroText={true}
          listContext={NAV_KEYS.SCHEDULED.key}
        />
      </div>
    </div>
  );
}

function NoItems(props) {
  let { itemList = [], navKey } = props;

  if (itemList.length) {
    return null;
  }

  return (
    <div className="text-center">
      No {navKey ? NAV_KEYS[navKey].text.toLowerCase() : ''} items
    </div>
  );
}

function MissingItemPlaceholder(props) {
  let { itemList = [] } = props;
  let { activeItemState } = useContext(WorkspaceContext);

  let currentWorkId = activeItemState.workId;

  if (!currentWorkId || itemList.indexOf(currentWorkId) !== -1) {
    return null;
  }

  return (
    <div className="primary-bg sticky top-0 text-center semi-active rounded-md p-4 mx-4 z-10">
      Opened item not present in this list
    </div>
  );
}

export function UnplannedItemsList() {
  let {
    groupedListEntries: groupedItems,
    filterChannelId = NAV_KEYS.ALL_ITEMS.key,
  } = useContext(PageContext);

  let itemList = groupedItems[NAV_KEYS.UNPLANNED.key][filterChannelId];
  return (
    <div className="relative flex flex-col gap-4">
      <MissingItemPlaceholder itemList={itemList} />
      <NoItems itemList={itemList} navKey={NAV_KEYS.UNPLANNED.key} />
      <div className="relative" key={NAV_KEYS.UNPLANNED.key}>
        <BasicList
          itemIds={itemList}
          hideZeroText={true}
          listContext={NAV_KEYS.UNPLANNED.key}
        />
      </div>
    </div>
  );
}

export function UnorganisedItemsList() {
  let { groupedListEntries: groupedItems } = useContext(PageContext);

  let itemList = groupedItems[NAV_KEYS.UNORGANISED.key]
    ? groupedItems[NAV_KEYS.UNORGANISED.key][NAV_KEYS.ALL_ITEMS.key]
    : [];
  return (
    <div className="relative flex flex-col gap-4">
      <MissingItemPlaceholder itemList={itemList} />
      <NoItems itemList={itemList} navKey={NAV_KEYS.UNORGANISED.key} />
      <div className="relative" key={NAV_KEYS.UNORGANISED.key}>
        <BasicList
          itemIds={itemList}
          hideZeroText={true}
          listContext={NAV_KEYS.UNORGANISED.key}
        />
      </div>
    </div>
  );
}

export function PlannedItemsList() {
  let { allMilestonesMap, orderedMilestones } = useContext(WorkspaceContext);
  let { groupedListEntries: groupedItems, pageKey } = useContext(PageContext);

  let plannedItems = groupedItems[NAV_KEYS.PLANNED.key];

  let allItemsList = [];
  Object.values(plannedItems).map((eachItemList) => {
    if (!eachItemList) {
      eachItemList = [];
    }
    allItemsList = allItemsList.concat(eachItemList);
  });

  return (
    <div className="flex flex-col gap-4">
      <MissingItemPlaceholder itemList={allItemsList} />
      <NoItems itemList={allItemsList} navKey={NAV_KEYS.PLANNED.key} />
      {orderedMilestones.map((milestoneId) => {
        let milestone = allMilestonesMap[milestoneId];

        let items = plannedItems[milestoneId];
        if (!items) {
          return null;
        }

        let milestoneName = getMilestoneName(milestone);

        return (
          <div className="relative" key={milestoneId}>
            {
              // hide milestone name if this is the milestone page
              pageKey !== PAGES.MILESTONE.key ? (
                <ListGroupMilestoneHeader
                  title={milestoneName}
                  itemCount={items.length}
                  endDateTs={milestone.isMine ? milestone.endDateTs : null}
                />
              ) : null
            }
            <div className={`${items.length ? 'mb-12' : 'mb-4'}`}>
              <BasicList
                itemIds={items}
                hideZeroText={true}
                listContext={`milestone_${milestoneId}`}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export function RecentItemsList() {
  let { groupedListEntries: groupedActivities } = useContext(PageContext);

  let { backwardTimeGroupsMap } = useContext(AppContext);

  let allItemList = [];
  let groupedItems = {};
  Object.keys(groupedActivities).map((eachGroupKey) => {
    groupedItems[eachGroupKey] = {};
    let activities = groupedActivities[eachGroupKey];

    // note: this is not overriding activities per-item since CLM is\
    // supposed to guarantee that only one activity is received per item
    let itemIds = activities.map((eachAct) => {
      groupedItems[eachGroupKey][eachAct.workId] = eachAct;
      return eachAct.workId;
    });

    allItemList = allItemList.concat(itemIds);
  });

  return (
    <div className="relative flex flex-col gap-4">
      <MissingItemPlaceholder itemList={allItemList} />
      <NoItems itemList={allItemList} />
      {allItemList.length ? (
        <>
          {Object.keys(groupedItems).map((eachGroupKey) => {
            let eachGroup = backwardTimeGroupsMap[eachGroupKey];
            let title = eachGroup.text.toUpperCase();

            let itemActivityMap = groupedItems[eachGroupKey];
            let itemIds = Object.keys(itemActivityMap);

            return (
              <div key={eachGroupKey}>
                <ListGroupHeader title={title} itemCount={itemIds.length} />
                <div className="mb-12">
                  <BasicList
                    itemIds={itemIds}
                    itemActivityMap={itemActivityMap}
                    hideZeroText={true}
                    listContext={eachGroupKey}
                  />
                </div>
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
}

function ListGroupMilestoneHeader(props) {
  let { title, itemCount, endDateTs } = props;

  return (
    <div
      className={`flex justify-between list-group-header ${
        !itemCount ? 'zero-items' : ''
      }`}
    >
      <div>
        {title}&nbsp;({itemCount})
      </div>
      <div>
        {endDateTs ? moment(endDateTs).calendar(null, MOMENT_CUSTOM_CAL) : ''}
      </div>
    </div>
  );
}

export function WSDescriptionContent(props) {
  let { description, handleEditClick, descRef } = props;

  return (
    <div className="desc-container h-full">
      <div
        className="ws-document ck-content"
        dangerouslySetInnerHTML={{ __html: description }}
        ref={descRef}
      />
      <div className="desc-edit-link absolute">
        <button onClick={handleEditClick} className="small">
          <WS_EditIcon style={{ fontSize: '1.1rem' }} />
        </button>
      </div>
    </div>
  );
}
