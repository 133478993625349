import { getInitialAppState } from './helpers';
import { handleWorkActivity } from './helpers/workState';
import { handleChannelActivity } from './helpers/channelState';
import { ACTIVITY_CATEGORY } from '../../utils/activityUtils';
import { handleMilestoneActivity } from './helpers/milestoneState';
import { handleClientAction } from './helpers/clientState';
import { handleMemberActivity } from './memberState';
import { FRESH_WORK, GEN_NOTIF } from '../actions/AppActionTypes';

export default function appState(state = getInitialAppState(), action) {
  if (
    !action.activity ||
    action.type === FRESH_WORK ||
    action.type === GEN_NOTIF
  ) {
    return handleClientAction(state, action);
  }

  let category = action.activity.category;

  switch (category) {
    case ACTIVITY_CATEGORY.MEMBER: {
      return handleMemberActivity(state, action);
    }

    case ACTIVITY_CATEGORY.WORK: {
      return handleWorkActivity(state, action);
    }

    case ACTIVITY_CATEGORY.CHANNEL: {
      return handleChannelActivity(state, action);
    }

    case ACTIVITY_CATEGORY.MILESTONE: {
      return handleMilestoneActivity(state, action);
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn('unknown category in appstate', category);
      return state;
    }
  }
}
