import { combineReducers } from 'redux';
import alerts from './alerts';
import appState from './appState';

const rootReducer = combineReducers({
  alerts,
  appState,
});

export default rootReducer;
