import {
  CWP,
  IAP,
  IP,
  addWorkParticipant,
  saveSeenActivity,
  logInvalidWork,
} from '.';
import { STATUS_OPTIONS_MAP } from '../../../utils/consts';
import { UNSCHEDULED_TIMESTAMP } from '../../../utils/timeUtils';
import centralisedListManager from './CentralisedListManager';
import { WORK_ACTIVITY_TYPE } from '../../../utils/activityUtils';

const Immutable = require('immutable');

export function handleWorkActivity(state, action) {
  let { activity } = action;

  switch (activity.type) {
    case WORK_ACTIVITY_TYPE.NEW_WORK.key: {
      return handleNewWork(state, action);
    }

    case WORK_ACTIVITY_TYPE.ADD_TO_CHANNEL.key: {
      return addWorkToChannel(state, action);
    }

    case WORK_ACTIVITY_TYPE.REMOVE_FROM_CHANNEL.key: {
      return removeWorkFromChannel(state, action);
    }

    case WORK_ACTIVITY_TYPE.DESCRIPTION_ADD.key:
    case WORK_ACTIVITY_TYPE.DESCRIPTION_EDIT.key: {
      return updateWorkDescription(state, action);
    }

    case WORK_ACTIVITY_TYPE.SUBJECT_EDIT.key: {
      return updateWorkSubject(state, action);
    }

    case WORK_ACTIVITY_TYPE.SCHEDULE_EDIT.key: {
      return updateWorkSchedule(state, action);
    }

    case WORK_ACTIVITY_TYPE.MILESTONE_EDIT.key: {
      return updateWorkMilestone(state, action);
    }

    case WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key: {
      return updateWorkAssignee(state, action);
    }

    case WORK_ACTIVITY_TYPE.OWNER_EDIT.key: {
      return updateWorkOwner(state, action);
    }

    case WORK_ACTIVITY_TYPE.NOTE_ADD.key: {
      return addNote(state, action);
    }

    case WORK_ACTIVITY_TYPE.NOTE_EDIT.key: {
      return updateNote(state, action);
    }

    case WORK_ACTIVITY_TYPE.STATUS_EDIT.key: {
      return updateWorkStatus(state, action);
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn(
        'unknown action type',
        activity.type,
        '(category:',
        activity.category,
        ')'
      );
      return state;
    }
  }
}

function updateWorkSubject(state, action) {
  let { activity } = action;

  let { workId, newVal } = activity;

  let oldWork = state.getIn(IP(state, workId));
  if (!oldWork) {
    logInvalidWork();
    return state;
  }
  oldWork = oldWork.toJS();

  state = state.setIn(IAP(state, workId, 'subject'), newVal);
  state = state.updateIn(IAP(state, workId, 'activity'), (list) =>
    list.unshift(Immutable.fromJS(activity))
  );

  state = addWorkParticipant(state, workId, activity.createdBy);

  state = updatePageData(state, oldWork, activity);

  state = saveSeenActivity(state, activity);

  return state;
}

function updateWorkDescription(state, action) {
  let { activity } = action;

  let { workId, newVal } = activity;

  let oldWork = state.getIn(IP(state, workId));
  if (!oldWork) {
    logInvalidWork();
    return state;
  }
  oldWork = oldWork.toJS();

  state = state.setIn(IAP(state, workId, 'description'), newVal);

  state = state.updateIn(IAP(state, workId, 'activity'), (list) =>
    list.unshift(Immutable.fromJS(activity))
  );

  state = addWorkParticipant(state, workId, activity.createdBy);

  state = updatePageData(state, oldWork, activity);

  state = saveSeenActivity(state, activity);

  return state;
}

function updateWorkStatus(state, action) {
  let { activity, itemPlaceholderPosId } = action;

  let { workId, newVal } = activity;

  let oldWork = state.getIn(IP(state, workId));
  if (!oldWork) {
    logInvalidWork();
    return state;
  }
  oldWork = oldWork.toJS();

  state = state.setIn(IAP(state, workId, 'status'), newVal);
  state = state.setIn(
    IAP(state, workId, 'statusUpdateTime'),
    activity.createdAt
  );

  if (newVal === STATUS_OPTIONS_MAP.CLOSED.key) {
    state = state.setIn(IAP(state, workId, 'archived'), true);
  } else if (newVal === STATUS_OPTIONS_MAP.REOPENED.key) {
    state = state.setIn(IAP(state, workId, 'archived'), false);
  }

  state = state.updateIn(IAP(state, workId, 'activity'), (list) =>
    list.unshift(Immutable.fromJS(activity))
  );

  state = addWorkParticipant(state, workId, activity.createdBy);

  // state = setRefresher(state);

  state = updatePageData(state, oldWork, activity);

  state = setRelocationPlaceholderId(state, workId, itemPlaceholderPosId);

  state = saveSeenActivity(state, activity);

  return state;
}

function updateWorkSchedule(state, action) {
  let { activity, itemPlaceholderPosId } = action;

  let { workId, newVal } = activity;

  let oldWork = state.getIn(IP(state, workId));
  if (!oldWork) {
    logInvalidWork();
    return state;
  }
  oldWork = oldWork.toJS();

  state = state.setIn(IAP(state, workId, 'scheduleTimestamp'), newVal);
  state = state.updateIn(IAP(state, workId, 'activity'), (list) =>
    list.unshift(Immutable.fromJS(activity))
  );

  state = addWorkParticipant(state, workId, activity.createdBy);

  state = state.setIn(
    IAP(state, workId, 'statusUpdateTime'),
    activity.createdAt
  );

  // state = setRefresher(state);
  state = setRelocationPlaceholderId(state, workId, itemPlaceholderPosId);

  state = updatePageData(state, oldWork, activity);

  state = saveSeenActivity(state, activity);

  return state;
}

function updateWorkMilestone(state, action) {
  let { activity, isPrivate = false, itemPlaceholderPosId } = action;

  let { workId, newVal } = activity;

  let oldWork = state.getIn(IP(state, workId));
  if (!oldWork) {
    logInvalidWork();
    return state;
  }
  oldWork = oldWork.toJS();

  state = state.setIn(IAP(state, workId, 'milestoneId'), newVal);
  state = state.setIn(IAP(state, workId, 'isPrivate'), isPrivate);
  state = state.updateIn(IAP(state, workId, 'activity'), (list) =>
    list.unshift(Immutable.fromJS(activity))
  );

  state = addWorkParticipant(state, workId, activity.createdBy);

  // state = setRefresher(state);

  state = updatePageData(state, oldWork, activity);

  state = setRelocationPlaceholderId(state, workId, itemPlaceholderPosId);

  state = saveSeenActivity(state, activity);

  return state;
}

function updateWorkAssignee(state, action) {
  let { activity, itemPlaceholderPosId } = action;

  let { workId, newVal: newAssignee, createdAt } = activity;

  if (!workId) {
    logInvalidWorkId();
    return state;
  }

  let oldWork = state.getIn(IP(state, workId));
  if (!oldWork) {
    logInvalidWork();
    return state;
  }
  oldWork = oldWork.toJS();

  state = state.setIn(IAP(state, workId, 'assignedTo'), newAssignee);
  state = state.setIn(IAP(state, workId, 'statusUpdateTime'), createdAt);
  state = state.updateIn(IAP(state, workId, 'activity'), (list) =>
    list.unshift(Immutable.fromJS(activity))
  );

  state = state.setIn(IAP(state, workId, 'isPrivate'), false);

  let newScheduleTime = UNSCHEDULED_TIMESTAMP; // unset schedule

  state = state.setIn(IAP(state, workId, 'scheduleTimestamp'), newScheduleTime);
  state = addWorkParticipant(state, workId, newAssignee);
  state = addWorkParticipant(state, workId, activity.createdBy);

  // state = setRefresher(state);
  state = setRelocationPlaceholderId(state, workId, itemPlaceholderPosId);

  state = updatePageData(state, oldWork, activity);

  state = saveSeenActivity(state, activity);

  return state;
}

function updateWorkOwner(state, action) {
  let { activity, itemPlaceholderPosId } = action;

  let { workId, newVal: newOwner } = activity;

  if (!workId) {
    logInvalidWorkId();
    return state;
  }

  let oldWork = state.getIn(IP(state, workId));
  if (!oldWork) {
    logInvalidWork();
    return state;
  }
  oldWork = oldWork.toJS();

  state = state.updateIn(IAP(state, workId, 'activity'), (list) =>
    list.unshift(Immutable.fromJS(activity))
  );

  state = state.setIn(IAP(state, workId, 'owner'), newOwner);

  state = state.setIn(IAP(state, workId, 'isPrivate'), false);

  state = addWorkParticipant(state, workId, newOwner);
  state = addWorkParticipant(state, workId, activity.createdBy);

  state = updatePageData(state, oldWork, activity);

  state = setRelocationPlaceholderId(state, workId, itemPlaceholderPosId);

  state = saveSeenActivity(state, activity);

  return state;
}

function removeWorkFromChannel(state, action) {
  let { activity } = action;

  let { workId, newVal: channelId } = activity;

  if (!state.hasIn(IP(state, workId))) {
    logInvalidWork();
    return state;
  }

  let oldWork = state.getIn(IP(state, workId));
  if (!oldWork) {
    logInvalidWork();
    return state;
  }
  oldWork = oldWork.toJS();

  state = state.updateIn(IAP(state, workId, 'channelIds'), (list) => {
    let channelIndex = list.indexOf(channelId);
    if (channelIndex !== -1) {
      list = list.delete(channelIndex);
    }

    return list;
  });

  state = state.updateIn(IAP(state, workId, 'activity'), (list) =>
    list.unshift(Immutable.fromJS(activity))
  );

  state = addWorkParticipant(state, workId, activity.createdBy);

  state = updatePageData(state, oldWork, activity);

  state = saveSeenActivity(state, activity);

  return state;
}

function addWorkToChannel(state, action) {
  let { activity, isPrivate = false } = action;

  let { workId, newVal: channelId } = activity;

  if (!workId) {
    logInvalidWorkId();
    return state;
  }

  let oldWork = state.getIn(IP(state, workId));
  if (!oldWork) {
    logInvalidWork();
    return state;
  }
  oldWork = oldWork.toJS();

  let existingChannelIds = state.getIn(IAP(state, workId, 'channelIds'));
  if (existingChannelIds.indexOf(channelId) !== -1) {
    // eslint-disable-next-line no-console
    console.log('channel already exists on the work item, nothing to do');
    return;
  }

  state = state.setIn(IAP(state, workId, 'isPrivate'), isPrivate);
  state = state.updateIn(IAP(state, workId, 'channelIds'), (list) =>
    list.push(channelId)
  );

  state = state.updateIn(IAP(state, workId, 'activity'), (list) =>
    list.unshift(Immutable.fromJS(activity))
  );

  state = addWorkParticipant(state, workId, activity.createdBy);

  state = updatePageData(state, oldWork, activity);

  state = saveSeenActivity(state, activity);

  return state;
}

function addNote(state, action) {
  let { note, activity } = action;

  let workId = note.workId;

  let oldWork = state.getIn(IP(state, workId));
  if (!oldWork) {
    logInvalidWork();
    return state;
  }
  oldWork = oldWork.toJS();

  state = state.updateIn(IAP(state, workId, 'notes'), (list) =>
    list.unshift(Immutable.fromJS(note))
  );

  state = state.updateIn(IAP(state, workId, 'activity'), (list) =>
    list.unshift(Immutable.fromJS(activity))
  );

  if (activity.mentions && activity.mentions.length) {
    state = state.setIn(IAP(state, workId, 'isPrivate'), false);
  }

  state = addWorkParticipant(state, workId, note.createdBy);
  note.mentions.map((each) => {
    state = addWorkParticipant(state, workId, each);
  });

  state = updatePageData(state, oldWork, activity);

  state = saveSeenActivity(state, activity);

  return state;
}

function updateNote(state, action) {
  let { activity } = action;
  let { workId, noteId, content } = activity;

  state = state.updateIn(IAP(state, workId, 'notes'), (list) => {
    // find the relevant note in work notes list and update it
    let noteIndex = list.findIndex((eachNote) => eachNote.get('id') === noteId);
    if (noteIndex !== -1) {
      list = list.setIn([noteIndex, 'content'], content);
    } else {
      // eslint-disable-next-line no-console
      console.warn('note update failed; note not found');
    }

    return list;
  });

  return state;
}

function setRelocationPlaceholderId(state, workId, itemPlaceholderPosId) {
  if (itemPlaceholderPosId) {
    // makes sure this is not a pusher update
    // although not received for all updates
    state = state.setIn(
      CWP(state).concat(['lastUpdatedWork', 'prevPosKeyInSourceList']),
      itemPlaceholderPosId
    );

    state = state.setIn(CWP(state).concat(['lastUpdatedWork', 'id']), workId);
  }

  return state;
}

function updatePageData(state, oldWork, activity) {
  let updatedWork = state.getIn(IP(state, activity.workId)).toJS();
  centralisedListManager.updatePageDataForActivity(
    state,
    updatedWork,
    oldWork,
    activity
  );
  state = centralisedListManager.saveState(state);

  return state;
}

function handleNewWork(state, action) {
  let { work } = action;

  if (!work.id) {
    // eslint-disable-next-line no-console
    console.log('received add work action without an id, skipping');
    return state;
  }

  let activity = work.activity[0];

  state = saveSeenActivity(state, activity);

  state = state.setIn(IP(state, work.id), Immutable.fromJS(work));

  state = updatePageData(state, null, activity);

  return state;
}

function logInvalidWorkId() {
  // eslint-disable-next-line no-console
  console.log(
    'Work id not found in activity, possibly because of no change at server?'
  );
}

/*
function setRefresher(state) {
  // refresh lists
  return state.setIn(
    CWP(state).concat(['refreshListFlag']),
    !state.getIn(CWP(state).concat(['refreshListFlag']))
  );
}
*/
