import { useContext, useState } from 'react';
import { PAGES, NAV_KEYS } from '../../../utils/consts';
import { PageContext, WorkspaceContext } from '../../../utils/contexts';
import { NavLink } from 'react-router-dom';
import V2PageWithItemList from './_Layout/V2PageWithItemList';
import { NotificationsList } from '../../UtilComponents/NotificationsList';
import { useEffect } from 'react';

export default function Notifications(props) {
  let { match } = props;

  let { l1Nav = null } = match.params;

  let { notifListGroupedByImportance } = useContext(WorkspaceContext);

  const [derivedL1Nav, setL1Nav] = useState(l1Nav);

  useEffect(() => {
    if (!l1Nav) {
      if (
        !notifListGroupedByImportance[NAV_KEYS.NOTIF_IMP.key].length &&
        notifListGroupedByImportance[NAV_KEYS.NOTIF_GENERAL.key].length
      ) {
        l1Nav = NAV_KEYS.NOTIF_GENERAL.key;
      } else {
        l1Nav = NAV_KEYS.NOTIF_IMP.key;
      }
    }
    setL1Nav(l1Nav);
  }, [l1Nav]);

  if (!derivedL1Nav) {
    return null;
  }

  let l1NavLinksAndIds = [
    // NAV_KEYS.ALL.key,
    NAV_KEYS.NOTIF_IMP.key,
    NAV_KEYS.NOTIF_GENERAL.key,
    // NAV_KEYS.NOTIF_READ.key,
  ];

  let pageContext = {
    pageKey: PAGES.NOTIF.key,
    filteredNotifList: notifListGroupedByImportance[derivedL1Nav],
    blockKey: { type: derivedL1Nav },
  };

  return (
    <div className="flex flex-col px-4 py-4 gap-2 h-full">
      <div className="nav-strip my-work-main-nav">
        {l1NavLinksAndIds.map((eachNavKey) => {
          return (
            <NavLink
              to={PAGES.NOTIF.link + '/' + eachNavKey}
              className={`cursor-pointer hovered-active ${
                derivedL1Nav === eachNavKey ? 'active' : ''
              }`}
              key={eachNavKey}
            >
              {NAV_KEYS[eachNavKey].text}
              &nbsp;({notifListGroupedByImportance[eachNavKey].length})
            </NavLink>
          );
        })}
      </div>
      <PageContext.Provider value={pageContext}>
        <V2PageWithItemList
          MainArea={() => <div />}
          ListArea={NotificationsList}
        />
      </PageContext.Provider>
    </div>
  );
}
