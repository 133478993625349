import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { WORK_ACTIVITY_TYPE } from './activityUtils';
import {
  NON_BUSINESS_EMAIL_DOMAINS,
  UNKNOWN_CHANNEL,
  NAV_KEYS,
  PAGES,
} from './consts';
import { MOMENT_CUSTOM_CAL } from './timeUtils';

function ucwords(str) {
  // obtained from https://locutus.io/php/strings/ucwords/
  return (str + '').replace(/^(.)|\s+(.)/g, function ($1) {
    return $1.toUpperCase();
  });
}

export function getMemberDisplayNames(personEmail, myEmail) {
  let mydomain = myEmail.split('@')[1];

  let personEmailParts = personEmail.split('@');

  let displayName = personEmailParts[0];
  let personDomain = personEmailParts[1];

  let relativeName = displayName;

  if (personEmail === myEmail) {
    relativeName = 'Me';
  } else {
    let iAmBusinessDomain = NON_BUSINESS_EMAIL_DOMAINS.indexOf(mydomain) === -1;
    if (iAmBusinessDomain && personDomain !== mydomain) {
      // append a marker to indicate different domain user
      let marker = ''; // '*';
      relativeName = displayName + marker;
    }
  }

  return {
    displayName: ucwords(displayName),
    relativeName: ucwords(relativeName),
    domainName: personDomain,
  };
}

export function handleShiftEnter(submitForm) {
  return (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      submitForm();
      event.preventDefault();
      return false;
    }
  };
}

export function getMyLastActivityPerItem(itemIdList, workDetailsMap, meId) {
  let myLastActivityPerItem = {};
  itemIdList.map((eachItemId) => {
    let eachItem = workDetailsMap[eachItemId];
    let eachItemActivity = eachItem.activity;
    let found = false;
    for (const eachActivity of eachItemActivity) {
      if (eachActivity.createdBy === meId) {
        found = true;
      } else if (
        eachActivity.type === WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key ||
        eachActivity.type === WORK_ACTIVITY_TYPE.OWNER_EDIT.key
      ) {
        if (eachActivity.oldVal === meId) {
          found = true;
        } else if (eachActivity.newVal === meId) {
          found = true;
        }
      } else if (eachActivity.type === WORK_ACTIVITY_TYPE.NEW_WORK.key) {
        if (eachActivity.assignedTo === meId) {
          found = true;
        } else if (eachActivity.owner === meId) {
          found = true;
        }
      }

      myLastActivityPerItem[eachItem.id] = {
        activity: eachActivity,
        associationType: 'no-activity',
      }; //\
      // Activity is saved in the map irrespective of whether `found` is true or false\
      // When no activity is found at all (could be a mentioned-only case)\
      // item "creation" activity will be used to compare last activity times for ordering purposes\
      // with association type of 'no-activity'

      if (found === true) {
        myLastActivityPerItem[eachItem.id].associationType = 'some-activity';
        break;
      }
    }
  });

  return myLastActivityPerItem;
}

export function getPathHashWorkId() {
  const location = useLocation();
  const pathHash = location.hash;

  let workId = null;
  if (pathHash.indexOf('#work_') === 0) {
    // proper
    workId = location.hash.substring(6);
  }

  return workId;
}

export function getChannelNameFromChannelKey(key, allChannelsMap) {
  if (key === UNKNOWN_CHANNEL.key) {
    return UNKNOWN_CHANNEL.name;
  }

  let channelName = UNKNOWN_CHANNEL.name;
  let channel = allChannelsMap[key];
  if (channel) {
    channelName = channel.name;
  }

  return channelName;
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function getSearchResults(searchTerm, workDetailsMap) {
  searchTerm = searchTerm.toLowerCase();

  var allWork = Object.values(workDetailsMap);
  var searchResults = { active: [], archived: [] };
  allWork.map((item) => {
    if (item.subject.toLowerCase().match('.*' + searchTerm + '.*')) {
      if (item.archived) {
        searchResults.archived.push(item.id);
      } else {
        searchResults.active.push(item.id);
      }
    }
  });

  return searchResults;
}

export function getPriorityGroupCounts(groupedEntries) {
  let counts = {
    [NAV_KEYS.SCHEDULED.key]: 0,
    [NAV_KEYS.UNPLANNED.key]: 0,
    [NAV_KEYS.PLANNED.key]: 0,
    [NAV_KEYS.UNORGANISED.key]: 0,
  };
  Object.keys(groupedEntries).map((eachKey) => {
    let subGroupEntries = groupedEntries[eachKey];

    Object.keys(subGroupEntries).map((eachSubKey) => {
      counts[eachKey] += subGroupEntries[eachSubKey].length;
    });
  });
  return counts;
}

export function getDefaultPriorityGroupNav(priorityGroupCounts) {
  let defaultNavKey = NAV_KEYS.SCHEDULED.key;
  if (!priorityGroupCounts[defaultNavKey]) {
    defaultNavKey = NAV_KEYS.PLANNED.key;
    if (!priorityGroupCounts[defaultNavKey]) {
      defaultNavKey = NAV_KEYS.UNPLANNED.key;
      if (!priorityGroupCounts[defaultNavKey]) {
        defaultNavKey = NAV_KEYS.UNORGANISED.key;
        if (!priorityGroupCounts[defaultNavKey]) {
          defaultNavKey = NAV_KEYS.SCHEDULED.key;
        }
      }
    }
  }

  return defaultNavKey;
}

export function getMyStreamLocateLink(item, isMyWork, isScheduledItem, meId) {
  let myStreamLocateLink = null;
  if (item.assignedTo === meId) {
    myStreamLocateLink = PAGES.MY_WORK.link + '/' + NAV_KEYS.ASSIGNED_TO_ME.key;
  } else if (item.owner === meId) {
    myStreamLocateLink = PAGES.MY_WORK.link + '/' + NAV_KEYS.OWNED_BY_ME.key;
  } else if (item.createdBy === meId) {
    myStreamLocateLink =
      PAGES.SEARCH_PLUS.link + '/' + NAV_KEYS.MY_CREATIONS_OPEN.key;
  } else {
    myStreamLocateLink =
      PAGES.SEARCH_PLUS.link + '/' + NAV_KEYS.MY_PARTICIPATIONS_ALL.key;
  }

  if (isMyWork) {
    if (isScheduledItem) {
      myStreamLocateLink += '/' + NAV_KEYS.SCHEDULED.key;
    } else if (item.milestoneId) {
      myStreamLocateLink += '/' + NAV_KEYS.PLANNED.key;
    } else if (item.channelIds.length) {
      myStreamLocateLink += '/' + NAV_KEYS.UNPLANNED.key;
    } else {
      myStreamLocateLink += '/' + NAV_KEYS.UNORGANISED.key;
    }
  }
  myStreamLocateLink += '?item=' + item.id;

  return myStreamLocateLink;
}

export function getMilestoneName(milestone) {
  let milestoneName = 'Unplanned';

  if (milestone) {
    let milestoneDateText = milestone.endDateTs
      ? moment(milestone.endDateTs).calendar(null, MOMENT_CUSTOM_CAL)
      : 'Undated';

    milestoneName = milestone.isMine
      ? milestone.name
      : `(${milestoneDateText})`;
  }
  return milestoneName;
}
