import React, { useEffect } from 'react';
import ApiClient from '../../utils/classes/ApiClient';
import { Formik } from 'formik';
import WSTextField from '../UtilComponents/WSTextField';
import history from '../../history';
import UserSession from '../../utils/classes/UserSession';
import { LOGIN_SUCCESS } from '../../redux/actions/AppActionTypes';
import store from '../../store';
import Header from './Header';
import { WSGuestButton } from '../UtilComponents/Buttons';
import { LANDING_URI } from '../../utils/consts';

export default function UserVerify(props) {
  let { appState } = props;

  const verifyUserId = appState.verifyUserId;
  useEffect(() => {
    if (!verifyUserId) {
      history.push(LANDING_URI);
    }
  }, [verifyUserId]);

  return (
    <>
      <Header />
      <div className="flex justify-center">
        <div className="flex flex-col justify-center items-center gap-24 pt-20">
          <h2 className="text-2xl active">Verify Account</h2>
          <VerifyAccountForm />
        </div>
      </div>
    </>
  );
}

function VerifyAccountForm() {
  function clearVerifyUserId() {
    UserSession.reset();
    location.reload();
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={(
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        ApiClient.post('guest/verifyAccount', {
          otp: values.otp,
          userId: UserSession.getVerifyUserId(),
        }).then(
          (res) => {
            setSubmitting(false);
            store.dispatch({
              type: LOGIN_SUCCESS,
              apiKey: res.apiKey,
              userId: res.userId,
            });
          },
          (error) => {
            if (error.response.status == 400) {
              // Some form error
              setErrors(error.response.data);
            } else {
              // reset session to prevent user from getting locked on this page
              UserSession.reset();
            }
            setSubmitting(false);
          }
        );
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4 items-center">
            <div className="active">
              Please enter the OTP received at your email
            </div>
            <div className="w-80">
              <WSTextField
                name="otp"
                label="Enter OTP"
                type="text"
                required
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.otp}
                autoFocus
              />
            </div>
            {errors.otp && <div className="formError">{errors.otp}</div>}
            <div>
              <WSGuestButton type="submit" size="sm">
                Submit
              </WSGuestButton>
            </div>
            <div>
              <span className="anchor" onClick={clearVerifyUserId}>
                Cancel
              </span>
            </div>
            {errors.form && <div className="formError">{errors.form}</div>}
          </div>
        </form>
      )}
    </Formik>
  );
}
