import {
  WSDescriptionContent,
  WS_ToolTip,
} from '../../../UtilComponents/TinyComponents';
import { useToggleState } from '../../../../utils/hook';
import { useContext, useEffect, useRef, useState } from 'react';
import { PageContext, WorkspaceContext } from '../../../../utils/contexts';
import Note from './ItemNote';
import { notesSorter } from '../../../../utils/sortUtils';
import { ItemActivity } from './ItemActivity';
import { WORK_ACTIVITY_TYPE } from '../../../../utils/activityUtils';
import CommentForm from './CommentForm';
import { DescImageDialog } from '../../../UtilComponents/MUICustom';
import {
  WS_CloseIcon,
  WS_Minimize,
  WS_PrivateChannelIcon,
  WS_ChannelIcon,
  // WS_CheckBoxChecked,
  // WS_CheckBoxUnchecked,
} from '../../../UtilComponents/Icons';
import EditSubjectForm from './EditSubjectForm';
import { PAGES } from '../../../../utils/consts';
import ApiClient from '../../../../utils/classes/ApiClient';
import store from '../../../../store';
import WSDescEditor from '../../../UtilComponents/WSDescEditor';
import history from '../../../../history';
import ChannelAutoSuggest from './ChannelAutoSuggest';
import L1Details from './L1Details';
import { TransparentButton } from '../../../UtilComponents/Buttons';

export default function ItemDetails(props) {
  let { itemId } = props;

  let { workDetailsMap } = useContext(WorkspaceContext);

  if (!itemId) {
    // eslint-disable-next-line no-console
    console.error('missing work id');
    return null;
  }

  let work = workDetailsMap[itemId];

  let { pageKey } = useContext(PageContext);
  let showClosePopupButton = pageKey !== PAGES.ITEM.key;

  function closeItemPopup() {
    history.push('?');
  }

  return (
    <div
      className={`relative flex flex-col w-full gap-8 work-details ${
        work.archived ? 'archived-item' : ''
      }`}
    >
      <div className="flex flex-col gap-4">
        <Header work={work} />
        <L1Details work={work} />
      </div>
      <div className="flex flex-col gap-2">
        <Channels work={work} />
      </div>
      <Body work={work} />
      {showClosePopupButton ? (
        <div className="absolute" style={{ left: 10, top: 2 }}>
          <WS_ToolTip contents={<div>Hide Details</div>} placement="left">
            <TransparentButton
              onClick={closeItemPopup}
              decorators="semi-active minimizer"
            >
              <WS_Minimize style={{ fontSize: '1.3rem' }} />
            </TransparentButton>
          </WS_ToolTip>
        </div>
      ) : null}
    </div>
  );
}

function Header(props) {
  let { work } = props;

  return (
    <div className="flex-auto flex gap-4 items-center">
      {work.isPrivate ? (
        <WS_ToolTip contents="Item is private" placement="left">
          <WS_PrivateChannelIcon />
        </WS_ToolTip>
      ) : null}
      <div className="flex-auto">
        <EditableSubject work={work} />
      </div>
    </div>
  );
}

function Body(props) {
  let { work } = props;

  const [descriptionEditMode, toggleDescriptionEditMode] = useToggleState(
    false
  );

  let [tabIndex, setTabIndex] = useState(0);

  let tabs = ['Description', `Comments (${work.notes.length})`, 'Activity'];

  return (
    <div className="flex-auto flex flex-col gap-4 mt-2 relative">
      <div className="flex gap-16 tab-header px-2">
        {tabs.map((each, idx) => {
          return (
            <div
              className={'field-header cursor-pointer hovered-semi-active'}
              onClick={() => setTabIndex(idx)}
              key={`tab_${idx}`}
            >
              <div className={`${tabIndex === idx ? 'active' : ''}`}>
                {each}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex-auto h-0 overflow-y-scroll">
        {tabIndex === 0 ? (
          <DescriptionSection
            work={work}
            descriptionEditMode={descriptionEditMode}
            toggleDescriptionEditMode={toggleDescriptionEditMode}
          />
        ) : null}
        {tabIndex === 1 ? <Notes work={work} key={'notes' + work.id} /> : null}
        {tabIndex === 2 ? (
          <AllItemActivities work={work} key={'activity' + work.id} />
        ) : null}
      </div>
    </div>
  );
}

function Channels(props) {
  let { work } = props;

  let { allChannelsMap, setItemSuccessSnackBarParams } = useContext(
    WorkspaceContext
  );

  let {
    isNewItem,
    suggestedChannelIds,
    updateSuggestionsForNewChannel,
  } = useContext(PageContext);

  let channelsToShow = work.channelIds;

  function handleRemoveFromChannel(channelId) {
    return ApiClient.post('work/removeFromChannel', {
      workId: work.id,
      channelId,
    }).then((res) => {
      store.dispatch({
        activity: res.activity,
      });
      setItemSuccessSnackBarParams({
        workId: work.id,
        message: 'Item updated successfully!',
      });
    });
  }

  function useSuggestion(newChannelId) {
    return () => {
      handleAddToChannel(newChannelId);
    };
  }

  function handleAddToChannel(channelId) {
    let isPrivate = work.isPrivate;
    if (allChannelsMap[channelId].memberIds.length > 1) {
      isPrivate = false;
    }

    return ApiClient.post('work/addToChannel', {
      workId: work.id,
      channelId,
      isPrivate,
    }).then((res) => {
      store.dispatch({
        activity: res.activity,
        isPrivate,
      });
      if (isNewItem) {
        updateSuggestionsForNewChannel(channelId);
      }

      setItemSuccessSnackBarParams({
        workId: work.id,
        message: 'Item updated successfully!',
      });
    });
  }

  let showSuggestion = isNewItem && suggestedChannelIds.length;

  return (
    <div className="flex items-center justify-between">
      <div className="flex-auto flex items-center semi-active">
        {showSuggestion ? (
          <div className="suggestion-box channel-suggestion">
            <div onClick={useSuggestion(suggestedChannelIds[0])}>
              {allChannelsMap[suggestedChannelIds[0]].name}?
            </div>
          </div>
        ) : null}
        {channelsToShow.map((eachChannelId) => (
          <Channel
            channel={allChannelsMap[eachChannelId]}
            handleRemoveFromChannel={handleRemoveFromChannel}
            key={`work_channel_${eachChannelId}`}
          />
        ))}
      </div>
      <div className="flex gap-2 items-center -ml-2">
        <ChannelAutoSuggest
          work={work}
          key={'channel-selector_' + work.id}
          handleAddToChannel={handleAddToChannel}
        />
      </div>
    </div>
  );
}

function Channel(props) {
  let { channel, handleRemoveFromChannel } = props;

  function removeWorkFromChannel(e) {
    handleRemoveFromChannel(channel.id);
    e.preventDefault();
  }

  return (
    <div className={'flex items-center item-channel gap-1'}>
      <div className={'flex items-center'}>
        {channel.isPrivate ? (
          <WS_PrivateChannelIcon
            style={{ fontSize: '0.7rem' }}
            className="mr-1"
          />
        ) : (
          <WS_ChannelIcon
            style={{
              fontSize: '0.9rem',
              verticalAlign: 'middle',
            }}
          />
        )}
        <div>{channel.name}</div>
      </div>
      <WS_CloseIcon
        onClick={removeWorkFromChannel}
        style={{ fontSize: '1rem' }}
        className="cursor-pointer close-icon"
      />
    </div>
  );
}

function DescriptionSection(props) {
  let { work, descriptionEditMode, toggleDescriptionEditMode } = props;

  const [open, toggle] = useToggleState();
  const [src, setSrc] = useState(null);

  let descRef = useRef(null);

  function onImgClick(e) {
    // eslint-disable-next-line no-console
    console.log('image was clicked', e);
    setSrc(e.target.src);
    toggle();
  }

  useEffect(() => {
    if (descRef.current) {
      let images = descRef.current.getElementsByTagName('img');
      for (let eachImg of images) {
        // remove if there was a listener - to handle case when new image is added
        eachImg.removeEventListener('click', onImgClick);
        // add new listener
        eachImg.addEventListener('click', onImgClick);
      }

      return () => {
        for (let eachImg of images) {
          eachImg.removeEventListener('click', onImgClick);
        }
      };
    }
  }, [work.description]);

  function handleDescriptionSave(newDesc) {
    ApiClient.post('work/editDescription', {
      description: newDesc,
      workId: work.id,
    }).then((res) => {
      store.dispatch({ activity: res.activity });
      toggleDescriptionEditMode();
    });
  }

  return (
    <div className="px-2">
      <div
        className={`description-content ${
          descriptionEditMode || !work.description ? 'hidden' : ''
        }`}
      >
        <WSDescriptionContent
          description={work.description}
          handleEditClick={toggleDescriptionEditMode}
          descRef={descRef}
        />
      </div>
      {!descriptionEditMode ? (
        !work.description ? (
          <div
            className="cursor-pointer unset w-32 h-full"
            onClick={toggleDescriptionEditMode}
          >
            Add Description
          </div>
        ) : null
      ) : (
        <WSDescEditor
          handleSave={handleDescriptionSave}
          handleCancel={toggleDescriptionEditMode}
          initialData={work.description}
        />
      )}
      <DescImageDialog
        handleDelete={toggle}
        handleClose={toggle}
        open={open}
        buttonText="Confirm"
        titleText="Leave Milestone?"
        className="ws-surface"
      >
        <ImageContent src={src} />
      </DescImageDialog>
    </div>
  );
}

function ImageContent(props) {
  let { src } = props;

  if (!src) {
    return null;
  }

  return (
    <img
      src={src}
      style={{ maxWidth: '100%', height: 'auto', maxHeight: '800px' }}
    />
  );
}

function Notes(props) {
  let { work } = props;

  let workNotes = work.notes;
  workNotes.sort(notesSorter);

  const [addCommentsMode, toggleAddCommentsMode] = useToggleState(true);

  if (!workNotes.length && !addCommentsMode) {
    return (
      <div
        className="empty-comments px-4 py-2 my-4 w-full"
        onClick={toggleAddCommentsMode}
      >
        Add a comment
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      {/* <div className="field-header darker pb-1">{`Comments (${workNotes.length})`}</div> */}
      {addCommentsMode ? <CommentForm workId={work.id} /> : null}
      {workNotes.map((note) => {
        return <Note note={note} key={note.id} />;
      })}
    </div>
  );
}

function AllItemActivities(props) {
  let { work } = props;

  let workActivity = work.activity;

  return (
    <div className="w-full item-activities">
      {/* <div className="field-header darker">Activity</div> */}
      <div className="flex flex-col w-full">
        {workActivity.map((act) => {
          if (
            act.type === WORK_ACTIVITY_TYPE.NOTE_ADD.key ||
            act.type === WORK_ACTIVITY_TYPE.NOTE_EDIT.key
          ) {
            return null;
          }
          return <ItemActivity act={act} key={'work_act_' + act.id} />;
        })}
      </div>
    </div>
  );
}

function EditableSubject(props) {
  let { work } = props;

  let [showEditForm, toggleEditFormState] = useToggleState();

  function toggleEditFormStateNoPropagate(e) {
    toggleEditFormState();
    if (e) {
      e.stopPropagation();
    }
  }

  if (work.archived) {
    return <div className="subject active">{work.subject}</div>;
  }

  if (showEditForm) {
    return (
      <div className="subject">
        <EditSubjectForm
          work={work}
          hideForm={toggleEditFormStateNoPropagate}
        />
      </div>
    );
  } else {
    return (
      <div
        className="subject cursor-pointer active"
        onClick={toggleEditFormStateNoPropagate}
      >
        {work.subject}
      </div>
    );
  }
}
