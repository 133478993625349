import React, { useEffect } from 'react';
import { useContext } from 'react';
import { WorkspaceContext } from '../../../../../utils/contexts';
import { Person, WS_ToolTip } from '../../../../UtilComponents/TinyComponents';
import { WS_PrivateChannelIcon } from '../../../../UtilComponents/Icons';
import moment from 'moment';
import {
  ACTIVITY_CONTEXTS,
  getActivityText,
} from '../../../../../utils/activityUtils';
import { STATUS_OPTIONS_MAP } from '../../../../../utils/consts';

export default function ItemCard(props) {
  let { work, activity, prevItemXKey } = props;

  let { activeItemState, setActiveItemCurrentPosition } = useContext(
    WorkspaceContext
  );
  let currentWorkId = activeItemState.workId;
  // const [hoverRef, isHovered] = useHoverState();

  useEffect(() => {
    if (currentWorkId === work.id) {
      // i am selected currently
      setActiveItemCurrentPosition(prevItemXKey);
    }
  }, [prevItemXKey, currentWorkId]);

  return (
    <div
      className={`
        item-card relative 
        ${currentWorkId === work.id ? 'highlighted' : ''} 
        ${work.archived ? 'archived-item' : ''}`}
      // ref={hoverRef}
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between gap-1">
          <div className="cell-auto flex gap-2">
            {work.isPrivate ? (
              <WS_ToolTip contents="Item is private" placement="left">
                <WS_PrivateChannelIcon />
              </WS_ToolTip>
            ) : null}
            <div className="cell-auto subject font-normal ellipsis pr-3 text-base">
              {work.subject}
            </div>
          </div>
        </div>
        {activity ? (
          <ActivityInfo activity={activity} />
        ) : (
          <SecondaryInfo work={work} />
        )}
      </div>
      {/* <div className="absolute top-1 right-1.5">
        <Pin isHovered={isHovered} work={work} />
      </div> */}
    </div>
  );
}

function SecondaryInfo(props) {
  let { work } = props;

  if (work.archived) {
    return null;
  }

  let showStatus =
    work.status === STATUS_OPTIONS_MAP.URGENT.key ||
    work.status === STATUS_OPTIONS_MAP.REOPENED.key;

  return (
    <div className="flex justify-between text-xs font-thin tracking-wide semi-active">
      <Person id={work.assignedTo} />
      {showStatus ? <div className="uppercase">{work.status}</div> : null}
    </div>
  );
}

function ActivityInfo(props) {
  let { activity } = props;

  let { meId } = useContext(WorkspaceContext);

  let activityText = getActivityText(
    activity,
    meId,
    ACTIVITY_CONTEXTS.ITEM_CARD.key
  );

  return (
    <div className="flex gap-1 font-thin text-xs inactive">
      <div>{activityText} by</div>
      <Person id={activity.createdBy} />
      <div>|</div>
      <div>{moment(activity.createdAt).fromNow()}</div>
    </div>
  );
}
