import { CWP } from '.';
import {
  activitySorter,
  WORK_ACTIVITY_TYPE,
} from '../../../utils/activityUtils';
import {
  LIST_TYPES,
  MY_STREAM_DATA_KEYS,
  CHANNEL_DATA_KEYS,
  PEOPLE_DATA_KEYS,
  PRIMARY_DATA_KEYS,
  MILESTONE_DATA_KEYS,
  NAV_KEYS,
  STATUS_OPTIONS_MAP,
} from '../../../utils/consts';
import { itemSorterByStatus } from '../../../utils/sortUtils';
import {
  BACKWARD_TIME_GROUP_KEY_MAP,
  getActivityTimeGroupKey,
  isScheduled,
} from '../../../utils/timeUtils';
const Immutable = require('immutable');

/*
 * Job of this class is to manage all the item and activity lists across all the pages\
 * of app, which is then also used to display all the stats
 * All changes to list and activity state must be processed here for accuracy across the pages
 * Used as singleton model
 * List state is stored in redux with 'pageData' key (see this.saveState)
 * Maintains a local mirror copy of pageData state as this.pageDataImmut
 */
class CentralisedListManager {
  pageDataImmut;
  defaultPageData = {
    [PRIMARY_DATA_KEYS.MY_STREAM]: {},
    [PRIMARY_DATA_KEYS.CHANNEL]: {},
    [PRIMARY_DATA_KEYS.MILESTONE]: {},
    [PRIMARY_DATA_KEYS.PEOPLE]: {},
  };

  myWorkItemListKeys = [
    MY_STREAM_DATA_KEYS.ASSIGNED_TO_ME.key,
    MY_STREAM_DATA_KEYS.OWNED_BY_ME.key,
  ];

  myWorkActivityListKeys = [
    MY_STREAM_DATA_KEYS.MY_WORK_HISTORY.key,
    MY_STREAM_DATA_KEYS.MY_CREATIONS_CLOSED.key,
    MY_STREAM_DATA_KEYS.MY_CREATIONS_OPEN.key,
    MY_STREAM_DATA_KEYS.MY_PARTICIPATIONS_ALL.key,
  ];

  itemListStruct = {};
  activityListStruct = {};
  scheduleSlotMap;
  backwardTimeGroupsMap;

  myPastAssignmentSeen = {};
  myPastCreationSeen = {};
  itemCloseSeen = {};
  myAnyActivitySeen = {};

  _initialised = false;

  myWorkKeysToRemove = [];
  channelPathsToRemove = [];
  milestonePathsToRemove = [];
  peoplePathsToRemove = [];
  myWorkKeysToUpdate = [];
  channelPathsToUpdate = [];
  milestonePathsToUpdate = [];
  peoplePathsToUpdate = [];

  constructor() {
    if (this._initialised) {
      return;
    }
    let { itemListStruct, activityListStruct } = getPageDataStructs();
    this.itemListStruct = itemListStruct;
    this.activityListStruct = activityListStruct;

    this._setupEmptyPageData();
  }

  reset() {
    this._initialised = false;

    this._setupEmptyPageData();
  }

  buildInitialPageData(
    workDetailsMap,
    scheduleSlotMap,
    backwardTimeGroupsMap,
    meId,
    sortedItemIds,
    allActivities
  ) {
    if (this._initialised) {
      // eslint-disable-next-line no-console
      console.log('List Manager already initialised, skipping');
      return;
    }

    this.scheduleSlotMap = scheduleSlotMap;
    this.backwardTimeGroupsMap = backwardTimeGroupsMap;

    this.meId = meId;

    // fill the priority lists
    sortedItemIds.map((itemId) => {
      let item = workDetailsMap[itemId];

      this._addItemToPriorityLists(item);
    });

    // fill the activity lists
    allActivities.sort(activitySorter);
    allActivities.map((eachAct) => {
      let workId = eachAct.workId;
      let work = workDetailsMap[workId];

      this._processActivityForRecentItemsLists(eachAct, work);
    });

    this.myPastAssignmentSeen = {};
    this.myPastCreationSeen = {};
    this.itemCloseSeen = {};
    this.myAnyActivitySeen = {};

    this._initialised = true;
  }

  saveState(state) {
    state = state.setIn(CWP(state).concat(['pageData']), this.pageDataImmut);
    return state;
  }

  registerFreshWork(state, work, activity) {
    let workDetailsMap = state
      .getIn(CWP(state).concat(['workDetailsMap']))
      .toJS();

    let assignee = work.assignedTo;
    let isAssignedToMe = assignee === this.meId;
    let owner = work.owner;
    let isOwnedByMe = owner === this.meId;

    if (isAssignedToMe) {
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.ASSIGNED_TO_ME.key);
    } else {
      this.peoplePathsToUpdate.push([assignee, PEOPLE_DATA_KEYS.OPEN.key]);
    }

    if (isOwnedByMe) {
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.OWNED_BY_ME.key);
    }

    for (let eachChannelId of work.channelIds) {
      this.channelPathsToUpdate.push([
        eachChannelId,
        CHANNEL_DATA_KEYS.OPEN.key,
      ]);
    }

    if (work.milestoneId) {
      this.milestonePathsToUpdate.push([
        work.milestoneId,
        MILESTONE_DATA_KEYS.OPEN.key,
      ]);
    }

    let actor = activity.createdBy;
    let isMyOwnAct = actor === this.meId;

    let isEditAssigneeAct =
      activity.type === WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key;

    let isEditOwnerAct = activity.type === WORK_ACTIVITY_TYPE.OWNER_EDIT.key;

    let IAmMadeAssignee = isEditAssigneeAct && activity.newVal === this.meId;

    let IAmMadeOwner = isEditOwnerAct && activity.newVal === this.meId;

    let isNoteAddedOrEditedAct =
      activity.type === WORK_ACTIVITY_TYPE.NOTE_ADD.key ||
      activity.type === WORK_ACTIVITY_TYPE.NOTE_EDIT.key;

    let IAmMentioned =
      isNoteAddedOrEditedAct && activity.mentions.indexOf(this.meId) !== -1;

    if (IAmMentioned || IAmMadeOwner || IAmMadeAssignee || isMyOwnAct) {
      // always attempt to remove from participations, in case there is already an entry
      this.myWorkKeysToRemove.push(
        MY_STREAM_DATA_KEYS.MY_PARTICIPATIONS_ALL.key
      );
      this.myWorkKeysToUpdate.push(
        MY_STREAM_DATA_KEYS.MY_PARTICIPATIONS_ALL.key
      );
    }

    this._processAllUpdates(work, null, workDetailsMap, activity);

    this._resetUpdatePaths();
  }

  updatePageDataForActivity(state, updatedWork, oldWork, activity) {
    let workDetailsMap = state
      .getIn(CWP(state).concat(['workDetailsMap']))
      .toJS();

    let activityType = activity.type;

    let isEditMilestoneAct =
      activityType === WORK_ACTIVITY_TYPE.MILESTONE_EDIT.key;
    let isEditAssigneeAct =
      activityType === WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key;
    let isAddChannelAct =
      activityType === WORK_ACTIVITY_TYPE.ADD_TO_CHANNEL.key;
    let isRemoveChannelAct =
      activityType === WORK_ACTIVITY_TYPE.REMOVE_FROM_CHANNEL.key;

    let isEditOwnerAct = activityType === WORK_ACTIVITY_TYPE.OWNER_EDIT.key;

    /// todo:
    let isEditStatusAct = activityType === WORK_ACTIVITY_TYPE.STATUS_EDIT.key;
    let isEditScheduleAct =
      isEditStatusAct &&
      activity.newVal !== STATUS_OPTIONS_MAP.CLOSED.key &&
      activity.newVal !== STATUS_OPTIONS_MAP.REOPENED.key;
    let isCloseAct =
      isEditStatusAct && activity.newVal === STATUS_OPTIONS_MAP.CLOSED.key;
    let isReopenAct =
      isEditStatusAct && activity.newVal === STATUS_OPTIONS_MAP.REOPENED.key;

    let isNewWorkAct = activityType === WORK_ACTIVITY_TYPE.NEW_WORK.key;

    if (isEditScheduleAct || isEditMilestoneAct) {
      this._processPriorityListUpdate(updatedWork, activity);
    } else if (isEditAssigneeAct) {
      this._processAssigneeUpdate(activity);
    } else if (isEditOwnerAct) {
      this._processOwnerUpdate(activity);
    } else if (isCloseAct) {
      this._processItemClose(updatedWork);
    } else if (isReopenAct) {
      this._processItemReopen(updatedWork);
    } else if (isNewWorkAct) {
      this._processNewWork(updatedWork);
    } else if (isAddChannelAct) {
      let channelId = activity.newVal;
      this.channelPathsToUpdate.push([channelId, CHANNEL_DATA_KEYS.OPEN.key]);
      if (updatedWork.channelIds.length === 1) {
        // first channel to be added, remove from unorganised
        this._processPriorityListUpdate(updatedWork, activity);
      }
    } else if (isRemoveChannelAct) {
      let channelId = activity.newVal;
      this.channelPathsToRemove.push([channelId, CHANNEL_DATA_KEYS.OPEN.key]);
      if (!updatedWork.channelIds.length) {
        // no more channels on the item, add to unorganised
        this._processPriorityListUpdate(updatedWork, activity);
      }
    }

    let meId = this.meId;
    let actor = activity.createdBy;
    let isMyOwnAct = actor === this.meId;

    let IAmMadeAssignee = isEditAssigneeAct && activity.newVal === meId;

    let IAmMadeOwner = isEditOwnerAct && activity.newVal === meId;

    let isNoteAddedOrEditedAct =
      activityType === WORK_ACTIVITY_TYPE.NOTE_ADD.key ||
      activityType === WORK_ACTIVITY_TYPE.NOTE_EDIT.key;

    let IAmMentioned =
      isNoteAddedOrEditedAct && activity.mentions.indexOf(this.meId) !== -1;
    if (IAmMentioned || IAmMadeOwner || IAmMadeAssignee || isMyOwnAct) {
      // always attempt to remove from participations, in case there is already an entry
      this.myWorkKeysToRemove.push(
        MY_STREAM_DATA_KEYS.MY_PARTICIPATIONS_ALL.key
      );
      this.myWorkKeysToUpdate.push(
        MY_STREAM_DATA_KEYS.MY_PARTICIPATIONS_ALL.key
      );
    }

    this._processAllUpdates(updatedWork, oldWork, workDetailsMap, activity);

    this._resetUpdatePaths();
  }

  processNewMember(memberId) {
    let { itemListStruct, activityListStruct } = getPageDataStructs();
    let emptyMemberPageData = getEmptyMemberPageData(
      itemListStruct,
      activityListStruct
    );

    this.pageDataImmut = this.pageDataImmut.setIn(
      [PRIMARY_DATA_KEYS.PEOPLE, memberId],
      Immutable.fromJS(emptyMemberPageData)
    );
  }

  processNewChannel(channelId) {
    let { itemListStruct, activityListStruct } = getPageDataStructs();
    let emptyChannelPageData = getEmptyChannelPageData(
      itemListStruct,
      activityListStruct
    );

    this.pageDataImmut = this.pageDataImmut.setIn(
      [PRIMARY_DATA_KEYS.CHANNEL, channelId],
      Immutable.fromJS(emptyChannelPageData)
    );
  }

  processNewMilestone(milestoneId) {
    let { itemListStruct, activityListStruct } = getPageDataStructs();
    let emptyMilestonePageData = getEmptyMilestonePageData(
      itemListStruct,
      activityListStruct
    );

    this.pageDataImmut = this.pageDataImmut.setIn(
      [PRIMARY_DATA_KEYS.MILESTONE, milestoneId],
      Immutable.fromJS(emptyMilestonePageData)
    );
  }

  _resetUpdatePaths() {
    this.myWorkKeysToRemove = [];
    this.channelPathsToRemove = [];
    this.milestonePathsToRemove = [];
    this.peoplePathsToRemove = [];
    this.myWorkKeysToUpdate = [];
    this.channelPathsToUpdate = [];
    this.milestonePathsToUpdate = [];
    this.peoplePathsToUpdate = [];
  }

  _processItemClose(updatedWork) {
    let assignee = updatedWork.assignedTo;
    let isAssignedToMe = assignee === this.meId;
    let owner = updatedWork.owner;
    let isOwnedByMe = owner === this.meId;
    let isCreatedByMe = updatedWork.createdBy === this.meId;
    let channelIds = updatedWork.channelIds;
    let milestoneId = updatedWork.milestoneId;

    if (milestoneId) {
      this.milestonePathsToRemove.push([
        milestoneId,
        MILESTONE_DATA_KEYS.OPEN.key,
      ]);

      this.milestonePathsToUpdate.push([
        milestoneId,
        MILESTONE_DATA_KEYS.CLOSED.key,
      ]);
    }

    if (isAssignedToMe) {
      this.myWorkKeysToRemove.push(MY_STREAM_DATA_KEYS.ASSIGNED_TO_ME.key);
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.MY_WORK_HISTORY.key);
    } else {
      this.peoplePathsToRemove.push([assignee, PEOPLE_DATA_KEYS.OPEN.key]);
    }

    if (isOwnedByMe) {
      this.myWorkKeysToRemove.push(MY_STREAM_DATA_KEYS.OWNED_BY_ME.key);
    }

    if (isCreatedByMe) {
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.MY_CREATIONS_CLOSED.key);
      this.myWorkKeysToRemove.push(MY_STREAM_DATA_KEYS.MY_CREATIONS_OPEN.key);
    }

    for (let eachChannelId of channelIds) {
      this.channelPathsToRemove.push([
        eachChannelId,
        CHANNEL_DATA_KEYS.OPEN.key,
      ]);
      this.channelPathsToUpdate.push([
        eachChannelId,
        CHANNEL_DATA_KEYS.CLOSED.key,
      ]);
    }
  }

  _processPriorityListUpdate(updatedWork, activity) {
    let assignee = updatedWork.assignedTo;
    let isAssignedToMe = assignee === this.meId;
    let owner = updatedWork.owner;
    let isOwnedByMe = owner === this.meId;
    let channelIds = updatedWork.channelIds;
    let milestoneId = updatedWork.milestoneId;

    if (isAssignedToMe) {
      this.myWorkKeysToRemove.push(MY_STREAM_DATA_KEYS.ASSIGNED_TO_ME.key);
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.ASSIGNED_TO_ME.key);
    } else {
      this.peoplePathsToRemove.push([assignee, PEOPLE_DATA_KEYS.OPEN.key]);
      this.peoplePathsToUpdate.push([assignee, PEOPLE_DATA_KEYS.OPEN.key]);
    }

    if (isOwnedByMe) {
      this.myWorkKeysToRemove.push(MY_STREAM_DATA_KEYS.OWNED_BY_ME.key);
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.OWNED_BY_ME.key);
    }

    let isAddChannelAct =
      activity.type === WORK_ACTIVITY_TYPE.ADD_TO_CHANNEL.key;
    let isRemoveChannelAct =
      activity.type === WORK_ACTIVITY_TYPE.REMOVE_FROM_CHANNEL.key;

    if (isAddChannelAct || isRemoveChannelAct) {
      // fired for add/remove channel to fix organised / unorganised update
      return;
    }

    for (let eachChannelId of channelIds) {
      this.channelPathsToUpdate.push([
        eachChannelId,
        CHANNEL_DATA_KEYS.OPEN.key,
      ]);
      this.channelPathsToRemove.push([
        eachChannelId,
        CHANNEL_DATA_KEYS.OPEN.key,
      ]);
    }

    let oldMilestoneId = milestoneId;
    let newMilestoneId = milestoneId; // remove from, then add to the same milestone
    let isEditMilestoneAct =
      activity.type === WORK_ACTIVITY_TYPE.MILESTONE_EDIT.key;

    if (isEditMilestoneAct) {
      // for edit milestone case, these values are different
      oldMilestoneId = activity.oldVal;
      newMilestoneId = activity.newVal;
    }

    if (oldMilestoneId) {
      this.milestonePathsToRemove.push([
        oldMilestoneId,
        MILESTONE_DATA_KEYS.OPEN.key,
      ]);
    }

    if (newMilestoneId) {
      this.milestonePathsToUpdate.push([
        newMilestoneId,
        MILESTONE_DATA_KEYS.OPEN.key,
      ]);
    }
  }

  _processAssigneeUpdate(activity) {
    let meId = this.meId;

    let previousAssignee = activity.oldVal;
    let newAssignee = activity.newVal;
    if (previousAssignee === meId) {
      // i was previous assignee
      this.myWorkKeysToRemove.push(MY_STREAM_DATA_KEYS.ASSIGNED_TO_ME.key);
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.MY_WORK_HISTORY.key);
    } else {
      // update previous assignee's open items
      this.peoplePathsToRemove.push([
        previousAssignee,
        PEOPLE_DATA_KEYS.OPEN.key,
      ]);
    }

    if (newAssignee === meId) {
      // i am new assignee
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.ASSIGNED_TO_ME.key);
      // just in case this was part of my work history
      this.myWorkKeysToRemove.push(MY_STREAM_DATA_KEYS.MY_WORK_HISTORY.key);
    } else {
      // somebody else's new assignment
      this.peoplePathsToUpdate.push([newAssignee, PEOPLE_DATA_KEYS.OPEN.key]);
    }
  }

  _processOwnerUpdate(activity) {
    let meId = this.meId;

    let previousOwner = activity.oldVal;
    let newOwner = activity.newVal;
    if (previousOwner === meId) {
      // i was previous owner
      this.myWorkKeysToRemove.push(MY_STREAM_DATA_KEYS.OWNED_BY_ME.key);
    }

    if (newOwner === meId) {
      // i am new owner
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.OWNED_BY_ME.key);
    }
  }

  _processItemReopen(updatedWork) {
    let assignee = updatedWork.assignedTo;
    let isAssignedToMe = assignee === this.meId;
    let owner = updatedWork.owner;
    let isOwnedByMe = owner === this.meId;
    let isCreatedByMe = updatedWork.createdBy === this.meId;
    let channelIds = updatedWork.channelIds;
    let milestoneId = updatedWork.milestoneId;

    // All cases where item needs to be added
    if (isAssignedToMe) {
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.ASSIGNED_TO_ME.key);
      this.myWorkKeysToRemove.push(MY_STREAM_DATA_KEYS.MY_WORK_HISTORY.key);
    } else {
      this.peoplePathsToUpdate.push([assignee, PEOPLE_DATA_KEYS.OPEN.key]);
    }

    if (isOwnedByMe) {
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.OWNED_BY_ME.key);
    }

    if (isCreatedByMe) {
      this.myWorkKeysToRemove.push(MY_STREAM_DATA_KEYS.MY_CREATIONS_CLOSED.key);
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.MY_CREATIONS_OPEN.key);
    }

    for (let eachChannelId of channelIds) {
      this.channelPathsToUpdate.push([
        eachChannelId,
        CHANNEL_DATA_KEYS.OPEN.key,
      ]);

      this.channelPathsToRemove.push([
        eachChannelId,
        CHANNEL_DATA_KEYS.CLOSED.key,
      ]);
    }

    if (milestoneId) {
      this.milestonePathsToUpdate.push([
        milestoneId,
        MILESTONE_DATA_KEYS.OPEN.key,
      ]);

      this.milestonePathsToRemove.push([
        milestoneId,
        MILESTONE_DATA_KEYS.CLOSED.key,
      ]);
    }
  }

  _processNewWork(updatedWork) {
    let assignee = updatedWork.assignedTo;
    let isAssignedToMe = assignee === this.meId;
    let owner = updatedWork.owner;
    let isOwnedByMe = owner === this.meId;
    let isCreatedByMe = updatedWork.createdBy === this.meId;

    // handling for other owners, assignees, and creators are needed\
    // because this might have been fired through syncer update
    if (isAssignedToMe) {
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.ASSIGNED_TO_ME.key);
    } else {
      this.peoplePathsToUpdate.push([assignee, PEOPLE_DATA_KEYS.OPEN.key]);
    }

    if (isOwnedByMe) {
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.OWNED_BY_ME.key);
    }

    if (isCreatedByMe) {
      this.myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.MY_CREATIONS_OPEN.key);
    }
  }

  _processAllUpdates(updatedWork, oldWork, workDetailsMap, activity) {
    let itemId = updatedWork.id;

    let itemOldRankArr;
    if (oldWork) {
      itemOldRankArr = this.getItemRankArr(oldWork);
    }

    let itemNewRankArr = this.getItemRankArr(updatedWork);

    this._updateMyWorkPage(
      workDetailsMap,
      activity,
      itemNewRankArr,
      itemOldRankArr,
      itemId
    );

    this._updatePeoplePages(
      workDetailsMap,
      itemNewRankArr,
      itemOldRankArr,
      itemId
    );

    this._updateChannelPages(
      workDetailsMap,
      activity,
      itemNewRankArr,
      itemOldRankArr,
      itemId
    );

    this._updateMilestonePages(
      workDetailsMap,
      activity,
      itemNewRankArr,
      itemOldRankArr,
      itemId
    );
  }

  _addItemToPriorityLists(item) {
    let isClosed = item.archived;
    if (isClosed) {
      return;
    }

    let channelIds = item.channelIds;
    let assignee = item.assignedTo;
    let owner = item.owner;
    let milestoneId = item.milestoneId;

    let isAssignedToMe = assignee === this.meId;
    let isOwnedByMe = owner === this.meId;

    let myWorkKeysToUpdate = [];
    let channelKeysToUpdate = [];
    let milestoneKeysToUpdate = [];

    let assigneeKeysToUpdate = [];

    for (let eachChannelId of channelIds) {
      channelKeysToUpdate.push([eachChannelId, CHANNEL_DATA_KEYS.OPEN.key]);
    }

    if (milestoneId) {
      milestoneKeysToUpdate.push([milestoneId, MILESTONE_DATA_KEYS.OPEN.key]);
    }

    if (isAssignedToMe) {
      myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.ASSIGNED_TO_ME.key);
    } else {
      assigneeKeysToUpdate.push([assignee, PEOPLE_DATA_KEYS.OPEN.key]);
    }

    if (isOwnedByMe) {
      myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.OWNED_BY_ME.key);
    }

    let pathsToUpdate = [];
    let itemRankArr = this.getItemRankArr(item);

    for (let key of myWorkKeysToUpdate) {
      pathsToUpdate.push(
        [PRIMARY_DATA_KEYS.MY_STREAM, key].concat(itemRankArr)
      );
    }

    for (let path of channelKeysToUpdate) {
      pathsToUpdate.push(
        [PRIMARY_DATA_KEYS.CHANNEL].concat(path).concat(itemRankArr)
      );
    }

    for (let path of milestoneKeysToUpdate) {
      pathsToUpdate.push(
        [PRIMARY_DATA_KEYS.MILESTONE].concat(path).concat(itemRankArr)
      );
    }

    for (let path of assigneeKeysToUpdate) {
      pathsToUpdate.push(
        [PRIMARY_DATA_KEYS.PEOPLE].concat(path).concat(itemRankArr)
      );
    }

    for (let path of pathsToUpdate) {
      this.pageDataImmut = this.pageDataImmut.updateIn(path, (list) => {
        if (!list) {
          // in case of milestone if, the list may not exist, so create it on the fly
          list = Immutable.List();
        }

        return list.push(item.id);
      });
    }
  }

  // only stores one, most recent activity for each item
  _processActivityForRecentItemsLists(activity, item) {
    let isCloseAct = activity.type === WORK_ACTIVITY_TYPE.CLOSE.key;
    let isEditAssigneeAct =
      activity.type === WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key;
    let isEditOwnerAct = activity.type === WORK_ACTIVITY_TYPE.OWNER_EDIT.key;
    let isNewWorkAct = activity.type === WORK_ACTIVITY_TYPE.NEW_WORK.key;
    let isClosed = item.archived;
    let assignee = item.assignedTo;
    let isAssignedToMe = assignee === this.meId;
    let actor = activity.createdBy;
    let isMyOwnAct = actor === this.meId;
    let wasItemCreatedByMe = item.createdBy === this.meId;
    let itemId = item.id;

    let myWorkKeysToUpdate = [];
    let assigneePathsToUpdate = [];

    let channelIds = item.channelIds;
    let channelKeysToUpdate = [];

    let milestoneId = item.milestoneId;
    let milestoneKeysToUpdate = [];

    if (isNewWorkAct && this.meId === actor && !item.archived) {
      myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.MY_CREATIONS_OPEN.key);
    }

    if (isCloseAct && isClosed) {
      if (isAssignedToMe) {
        // work history should be recorded only once (latest one)\
        // but can turn up more than once (edit assignee).
        // Tracking that using this.myPastAssignmentSeen
        if (!this.myPastAssignmentSeen[itemId]) {
          myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.MY_WORK_HISTORY.key);
          this.myPastAssignmentSeen[itemId] = true;
        }
      }

      // item can be closed multiple times (reopend)\
      // but activity should turn up only once.
      // Tracking that using this.myPastCreationSeen
      if (wasItemCreatedByMe && !this.myPastCreationSeen[itemId]) {
        myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.MY_CREATIONS_CLOSED.key);
        this.myPastCreationSeen[itemId] = true;
      }

      if (!this.itemCloseSeen[itemId]) {
        for (let eachChannelId of channelIds) {
          channelKeysToUpdate.push([
            eachChannelId,
            CHANNEL_DATA_KEYS.CLOSED.key,
          ]);
        }

        if (milestoneId) {
          milestoneKeysToUpdate.push([
            milestoneId,
            MILESTONE_DATA_KEYS.CLOSED.key,
          ]);
        }
      }
      this.itemCloseSeen[itemId] = true;
    }

    if (isEditAssigneeAct) {
      let previousAssignee = activity.oldVal;
      let iWasPreviousAssignee = previousAssignee === this.meId;

      if (iWasPreviousAssignee) {
        if (!this.myPastAssignmentSeen[itemId] && !isAssignedToMe) {
          myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.MY_WORK_HISTORY.key);
          this.myPastAssignmentSeen[itemId] = true;
        }
      }
    }

    let isNoteAddedOrEditedAct =
      activity.type === WORK_ACTIVITY_TYPE.NOTE_ADD.key ||
      activity.type === WORK_ACTIVITY_TYPE.NOTE_EDIT.key;
    let mentioned =
      isNoteAddedOrEditedAct && activity.mentions.indexOf(this.meId) !== -1;

    let madeOwnerOrAssigneeViaEdit =
      (isEditOwnerAct || isEditAssigneeAct) &&
      activity.newVal === this.meId &&
      !isMyOwnAct;

    let madeOwnerOrAssigneeViaNewWork =
      isNewWorkAct &&
      !isMyOwnAct &&
      (activity.assignedTo === this.meId || activity.owner === this.meId);

    let inclusionByOthers =
      madeOwnerOrAssigneeViaEdit || madeOwnerOrAssigneeViaNewWork || mentioned;

    if ((isMyOwnAct || inclusionByOthers) && !this.myAnyActivitySeen[itemId]) {
      myWorkKeysToUpdate.push(MY_STREAM_DATA_KEYS.MY_PARTICIPATIONS_ALL.key);
      this.myAnyActivitySeen[itemId] = true;
    }

    let activityTimeGroupKey = getActivityTimeGroupKey(
      activity,
      this.backwardTimeGroupsMap
    );

    for (let key of myWorkKeysToUpdate) {
      this.pageDataImmut = this.pageDataImmut.updateIn(
        [PRIMARY_DATA_KEYS.MY_STREAM, key, activityTimeGroupKey],
        (list) => list.push(activity)
      );
    }

    for (let paths of assigneePathsToUpdate) {
      this.pageDataImmut = this.pageDataImmut.updateIn(
        [PRIMARY_DATA_KEYS.PEOPLE].concat(paths).concat([activityTimeGroupKey]),
        (list) => list.push(activity)
      );
    }

    for (let path of channelKeysToUpdate) {
      let channelId = path[0];
      let key = path[1];
      this.pageDataImmut = this.pageDataImmut.updateIn(
        [PRIMARY_DATA_KEYS.CHANNEL, channelId, key, activityTimeGroupKey],
        (list) => list.push(activity)
      );
    }

    for (let path of milestoneKeysToUpdate) {
      let milestoneId = path[0];
      let key = path[1];
      this.pageDataImmut = this.pageDataImmut.updateIn(
        [PRIMARY_DATA_KEYS.MILESTONE, milestoneId, key, activityTimeGroupKey],
        (list) => list.push(activity)
      );
    }
  }

  _updateMyWorkPage(
    workDetailsMap,
    activity,
    itemNewRankArr,
    itemOldRankArr,
    itemId
  ) {
    // remove first, then add
    for (let key of this.myWorkKeysToRemove) {
      if (MY_STREAM_DATA_KEYS[key].listType === LIST_TYPES.ACTIVITY) {
        this._removeActivityFromPath(
          [PRIMARY_DATA_KEYS.MY_STREAM, key],
          activity.workId
        );
      } else if (
        itemOldRankArr &&
        MY_STREAM_DATA_KEYS[key].listType === LIST_TYPES.PRIORITY
      ) {
        this._removeWorkIdFromPath(
          [PRIMARY_DATA_KEYS.MY_STREAM, key],
          itemOldRankArr,
          itemId
        );
      }
    }

    for (let key of this.myWorkKeysToUpdate) {
      if (MY_STREAM_DATA_KEYS[key].listType === LIST_TYPES.ACTIVITY) {
        this._addActivityToPath([PRIMARY_DATA_KEYS.MY_STREAM, key], activity);
      } else if (MY_STREAM_DATA_KEYS[key].listType === LIST_TYPES.PRIORITY) {
        this._addWorkIdToPath(
          workDetailsMap,
          [PRIMARY_DATA_KEYS.MY_STREAM, key],
          itemNewRankArr,
          itemId
        );
      }
    }
  }

  _updatePeoplePages(workDetailsMap, itemNewRankArr, itemOldRankArr, itemId) {
    // remove first, then add

    for (let path of this.peoplePathsToRemove) {
      let fullPath = [PRIMARY_DATA_KEYS.PEOPLE].concat(path);
      if (itemOldRankArr) {
        this._removeWorkIdFromPath(fullPath, itemOldRankArr, itemId);
      }
    }

    for (let path of this.peoplePathsToUpdate) {
      let fullPath = [PRIMARY_DATA_KEYS.PEOPLE].concat(path);
      this._addWorkIdToPath(workDetailsMap, fullPath, itemNewRankArr, itemId);
    }
  }

  _updateChannelPages(
    workDetailsMap,
    activity,
    itemNewRankArr,
    itemOldRankArr,
    itemId
  ) {
    for (let path of this.channelPathsToRemove) {
      let key = path[1];
      let fullPath = [PRIMARY_DATA_KEYS.CHANNEL, path[0], key];
      if (CHANNEL_DATA_KEYS[key].listType === LIST_TYPES.ACTIVITY) {
        this._removeActivityFromPath(fullPath, activity.workId);
      } else if (
        itemOldRankArr &&
        CHANNEL_DATA_KEYS[key].listType === LIST_TYPES.PRIORITY
      ) {
        this._removeWorkIdFromPath(fullPath, itemOldRankArr, itemId);
      }
    }

    for (let path of this.channelPathsToUpdate) {
      let key = path[1];
      let fullPath = [PRIMARY_DATA_KEYS.CHANNEL, path[0], key];
      if (CHANNEL_DATA_KEYS[key].listType === LIST_TYPES.ACTIVITY) {
        this._addActivityToPath(fullPath, activity);
      } else if (CHANNEL_DATA_KEYS[key].listType === LIST_TYPES.PRIORITY) {
        this._addWorkIdToPath(workDetailsMap, fullPath, itemNewRankArr, itemId);
      }
    }
  }

  _updateMilestonePages(
    workDetailsMap,
    activity,
    itemNewRankArr,
    itemOldRankArr,
    itemId
  ) {
    for (let path of this.milestonePathsToRemove) {
      let key = path[1];
      let fullPath = [PRIMARY_DATA_KEYS.MILESTONE, path[0], key];
      if (MILESTONE_DATA_KEYS[key].listType === LIST_TYPES.ACTIVITY) {
        this._removeActivityFromPath(fullPath, activity.workId);
      } else if (
        itemOldRankArr &&
        MILESTONE_DATA_KEYS[key].listType === LIST_TYPES.PRIORITY
      ) {
        this._removeWorkIdFromPath(fullPath, itemOldRankArr, itemId);
      }
    }

    for (let path of this.milestonePathsToUpdate) {
      let key = path[1];
      let fullPath = [PRIMARY_DATA_KEYS.MILESTONE, path[0], key];
      if (MILESTONE_DATA_KEYS[key].listType === LIST_TYPES.ACTIVITY) {
        this._addActivityToPath(fullPath, activity);
      } else if (MILESTONE_DATA_KEYS[key].listType === LIST_TYPES.PRIORITY) {
        this._addWorkIdToPath(workDetailsMap, fullPath, itemNewRankArr, itemId);
      }
    }
  }

  _addActivityToPath(path, activity) {
    let activityTimeGroupKey = BACKWARD_TIME_GROUP_KEY_MAP.THIS_WEEK;
    this.pageDataImmut = this.pageDataImmut.updateIn(
      path.concat([activityTimeGroupKey]),
      (list) => list.unshift(activity) // latest activity always goes on top
    );
  }

  _addWorkIdToPath(workDetailsMap, path, itemNewRankArr, itemId) {
    this.pageDataImmut = this.pageDataImmut.updateIn(
      path.concat(itemNewRankArr),
      (list) => {
        if (!list) {
          // in case of milestone if, the list may not exist, so create it on the fly
          list = Immutable.List();
        }
        list = list.push(itemId);
        list = list.sort(itemSorterByStatus(workDetailsMap)); // sort again by due date
        return list;
      }
    );
  }

  _removeActivityFromPath(path, workId) {
    // search for timegroup key
    let {
      exisingTimeGroupKeyForItem,
      existingActivityIndex,
    } = this._findEntryInExistingActivities(path, workId);
    // remove, if this item's entry is already there
    if (exisingTimeGroupKeyForItem) {
      this.pageDataImmut = this.pageDataImmut.updateIn(
        path.concat([exisingTimeGroupKeyForItem]),
        (list) => list.splice(existingActivityIndex, 1)
      );
    }
  }

  _removeWorkIdFromPath(path, itemRankArr, workId) {
    if (!this.pageDataImmut.hasIn(path.concat(itemRankArr))) {
      // this case comes up when a previously unknown item is received\
      // via pusher. One known case is of assignment to current member\
      // where CentralisedListManager tries to remove item from previous assignee\
      // but can not find it because item was just received.
      // eslint-disable-next-line no-console
      console.log('CLM: nothing exists at removal path');
      return;
    }
    this.pageDataImmut = this.pageDataImmut.updateIn(
      path.concat(itemRankArr),
      (list) => {
        let index = list.indexOf(workId);
        if (index > -1) {
          return list.splice(index, 1);
        }

        return list;
      }
    );
  }

  _findEntryInExistingActivities(metricPath, workId) {
    let exisingMetricActivities_Grouped = this.pageDataImmut
      .getIn(metricPath)
      .toJS();

    let exisingTimeGroupKeyForItem = null;
    let existingActivityIndex = -1;
    for (let eachTimeGroupKey in BACKWARD_TIME_GROUP_KEY_MAP) {
      let eachTimeGroup_MetricActivities =
        exisingMetricActivities_Grouped[eachTimeGroupKey];

      existingActivityIndex = eachTimeGroup_MetricActivities.findIndex(
        (each) => each.workId === workId
      );

      if (existingActivityIndex > -1) {
        exisingTimeGroupKeyForItem = eachTimeGroupKey;
        break;
      }
    }

    return {
      exisingTimeGroupKeyForItem,
      existingActivityIndex,
    };
  }

  _setupEmptyPageData() {
    let pageData = { ...this.defaultPageData };

    for (let key of this.myWorkItemListKeys) {
      pageData[PRIMARY_DATA_KEYS.MY_STREAM][key] = { ...this.itemListStruct };
    }

    for (let key of this.myWorkActivityListKeys) {
      pageData[PRIMARY_DATA_KEYS.MY_STREAM][key] = {
        ...this.activityListStruct,
      };
    }

    // My All Participations
    pageData[PRIMARY_DATA_KEYS.MY_STREAM][
      MY_STREAM_DATA_KEYS.MY_PARTICIPATIONS_ALL.key
    ] = {
      ...this.activityListStruct,
    };

    // Convert to immutable
    this.pageDataImmut = Immutable.fromJS(pageData);
  }

  getItemRankArr(item) {
    let mainRank = NAV_KEYS.UNORGANISED.key; // fallback
    let subRank = NAV_KEYS.ALL_ITEMS.key;

    if (isScheduled(item)) {
      mainRank = NAV_KEYS.SCHEDULED.key;
      // old time based scheduling
      // if (dueTime <= this.scheduleSlotMap.THIS_WEEK.endTime) {
      //   subRank = this.scheduleSlotMap.THIS_WEEK.key;
      // } else if (dueTime <= this.scheduleSlotMap.NEXT_WEEK.endTime) {
      //   subRank = this.scheduleSlotMap.NEXT_WEEK.key;
      // } else if (dueTime <= this.scheduleSlotMap.WEEK_3.endTime) {
      //   subRank = this.scheduleSlotMap.WEEK_3.key;
      // } else if (dueTime <= this.scheduleSlotMap.WEEK_4.endTime) {
      //   subRank = this.scheduleSlotMap.WEEK_4.key;
      // }
    } else if (item.milestoneId) {
      // planned item
      mainRank = NAV_KEYS.PLANNED.key;
      subRank = item.milestoneId;
    } else if (item.channelIds.length) {
      mainRank = NAV_KEYS.UNPLANNED.key;
    }

    return [mainRank, subRank];
  }
}

function getEmptyChannelPageData(itemListStruct, activityListStruct) {
  let channelPageData = {};
  channelPageData[CHANNEL_DATA_KEYS.OPEN.key] = {
    ...itemListStruct,
  };
  channelPageData[CHANNEL_DATA_KEYS.CLOSED.key] = {
    ...activityListStruct,
  };

  return channelPageData;
}

function getEmptyMilestonePageData(itemListStruct, activityListStruct) {
  let milestonePageData = {};
  milestonePageData[MILESTONE_DATA_KEYS.OPEN.key] = {
    ...itemListStruct,
  };
  milestonePageData[MILESTONE_DATA_KEYS.CLOSED.key] = {
    ...activityListStruct,
  };

  return milestonePageData;
}

function getPageDataStructs() {
  let itemListStruct = {
    [NAV_KEYS.UNPLANNED.key]: {},
    [NAV_KEYS.SCHEDULED.key]: {},
    [NAV_KEYS.PLANNED.key]: {},
    [NAV_KEYS.UNORGANISED.key]: {},
  };
  let activityListStruct = {};

  for (let eachBackwardTimeKey in BACKWARD_TIME_GROUP_KEY_MAP) {
    activityListStruct[eachBackwardTimeKey] = [];
  }

  return { itemListStruct, activityListStruct };
}

function getEmptyMemberPageData(itemListStruct) {
  let memberPageData = {};
  memberPageData[PEOPLE_DATA_KEYS.OPEN.key] = {
    ...itemListStruct,
  };

  return memberPageData;
}

const centralisedListManager = new CentralisedListManager();
export default centralisedListManager;
