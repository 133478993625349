import moment from 'moment';
import DatePicker from 'react-datepicker';
import { WS_EventIcon } from './Icons';
import { MOMENT_CUSTOM_CAL } from '../../utils/timeUtils';

export default function DateSelector(props) {
  let { processUpdate, timestamp } = props;

  function clearDate(e) {
    handleDateChange(null, e);
  }

  function handleDateChange(newDate) {
    let dateText = moment(newDate).format('YYYY-MM-DD');
    processUpdate(dateText);
  }

  let selectedDate = null;

  return (
    <div className="date-selector">
      <DatePicker
        customInput={
          <div className="flex gap-1 cursor-pointer text-base">
            <WS_EventIcon style={{ fontSize: '1.3rem' }} />
            <div
              className={`hovered-active ${
                timestamp ? 'semi-active' : 'unset'
              }`}
            >
              {timestamp
                ? moment(timestamp).calendar(null, MOMENT_CUSTOM_CAL)
                : 'No Date'}
            </div>
          </div>
        }
        onChange={handleDateChange}
        selected={selectedDate}
        minDate={new Date()}
        dateFormat="YYYY-MM-dd"
        popperPlacement="bottom-end"
      >
        <div className="text-center anchor" style={{ minHeight: 20 }}>
          <a onClick={clearDate}>Remove Date</a>
        </div>
      </DatePicker>
    </div>
  );
}
