import React from 'react';

export default function PageFrame(props) {
  let { children } = props;

  return (
    <div className="grid-y grid-frame relative">
      <div className="cell auto">{children}</div>
    </div>
  );
}
