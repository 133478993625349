import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useState } from 'react';
import { WS_Tick } from './Icons';
import { SquareCancelButton } from './TinyComponents';
import { WSBaseEditor_Desc } from './WSBaseEditors';

export default function WSDescEditor(props) {
  let { initialData, handleCancel, handleSave } = props;

  let [contents, setContents] = useState(initialData);

  function handleSaveLocal() {
    handleSave(contents);
  }

  return (
    <div className="ws-document">
      <div className="flex justify-end items-center gap-2">
        <div className="flex flex-col gap-2 absolute desc-form-buttons">
          <button
            type="submit"
            className="tiny-save-button cm active"
            onClick={handleSaveLocal}
          >
            <WS_Tick style={{ fontSize: '1.3rem' }} />
          </button>
          {handleCancel ? (
            <SquareCancelButton handleCancel={handleCancel} />
          ) : null}
        </div>
        {/* <button
          highlighted={changed}
          onClick={handleSaveLocal}
          className="tiny-save-text-button"
        >
          Save
        </button>
        {handleCancel ? (
          <SquareCancelButton handleCancel={handleCancel} />
        ) : null}  */}
      </div>
      <div className="ws-editor">
        <div className="ws-desc-editor__toolbar" />
        <div className="ws-desc-editor__editable-container">
          <CKEditor
            editor={WSBaseEditor_Desc}
            data={contents}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log('Editor is ready to use!', editor);
              window.editor = editor;
              editor.focus();

              // Add these two lines to properly position the toolbar
              const toolbarContainer = document.querySelector(
                '.ws-desc-editor__toolbar'
              );
              toolbarContainer.appendChild(editor.ui.view.toolbar.element);
            }}
            onChange={(event, editor) => {
              let newContent = editor.getData();
              setContents(newContent);
              // console.log({ event, editor, data });
            }}
            // onBlur={(event, editor) => {
            //   // console.log('Blur.', editor);
            // }}
            // onFocus={(event, editor) => {
            //   // console.log('Focus.', editor);
            // }}
            config={WSBaseEditor_Desc.getCustomConfig()}
          />
        </div>
      </div>
      {/* <div
        className="border mt-8 p-8"
        dangerouslySetInnerHTML={{ __html: contents }}
      /> */}
      {/* <div className="border mt-8 p-8">{contents}</div> */}
    </div>
  );
}
