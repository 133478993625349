import { CWP, saveSeenActivity } from '.';
import { CHANNEL_ACTIVITY_TYPE } from '../../../utils/activityUtils';
import centralisedListManager from './CentralisedListManager';

const Immutable = require('immutable');

export function handleChannelActivity(state, action) {
  switch (action.activity.type) {
    case CHANNEL_ACTIVITY_TYPE.NEW_CHANNEL.key: {
      return handleNewChannel(state, action);
    }

    case CHANNEL_ACTIVITY_TYPE.UPDATE_CHANNEL_NAME.key: {
      return editChannelName(state, action);
    }

    case CHANNEL_ACTIVITY_TYPE.ADD_CHANNEL_MEMBER.key: {
      return addChannelMember(state, action);
    }

    case CHANNEL_ACTIVITY_TYPE.REMOVE_CHANNEL_MEMBER.key: {
      return removeChannelMember(state, action);
    }

    case CHANNEL_ACTIVITY_TYPE.UPDATE_CHANNEL_DESCRIPTION.key: {
      return updateChannelDescription(state, action);
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn(
        'unknown action type',
        action.activity.type,
        '(category:',
        action.activity.category,
        ')'
      );
      return state;
    }
  }
}

function editChannelName(state, action) {
  let { activity } = action;

  let { channelId, newVal: newName } = activity;

  state = state.setIn(
    CWP(state).concat(['allChannelsMap', channelId, 'name']),
    newName
  );

  state = saveChannelsOrder(state);

  state = saveSeenActivity(state, activity);

  return state;
}

function addChannelMember(state, action) {
  let { activity } = action;

  let { channelId, newVal: memberId } = activity;

  let channelMembers = state.getIn(
    CWP(state).concat(['allChannelsMap', channelId, 'memberIds'])
  );

  if (channelMembers.indexOf(memberId) === -1) {
    state = state.updateIn(
      CWP(state).concat(['allChannelsMap', channelId, 'memberIds']),
      (list) => list.push(memberId)
    );

    state = updateVisibility(state, channelId);
  }

  state = saveSeenActivity(state, activity);

  return state;
}

export function addChannel(state, channel) {
  channel.isPrivate = channel.memberIds.length === 1;
  state = state.setIn(
    CWP(state).concat(['allChannelsMap', channel.id]),
    Immutable.fromJS(channel)
  );

  centralisedListManager.processNewChannel(channel.id);
  state = centralisedListManager.saveState(state);

  return state;
}

function removeChannelMember(state, action) {
  let { activity } = action;

  let { channelId, newVal: memberId } = activity;

  let meId = state.getIn(CWP(state).concat(['meId']));
  if (memberId === meId) {
    state = state.deleteIn(CWP(state).concat(['allChannelsMap', channelId]));
  } else {
    state = state.updateIn(
      CWP(state).concat(['allChannelsMap', channelId, 'memberIds']),
      (list) => list.delete(list.indexOf(memberId))
    );

    let memberIds = state.getIn(
      CWP(state).concat(['allChannelsMap', channelId, 'memberIds'])
    );

    if (memberIds.size === 1) {
      state = state.setIn(
        CWP(state).concat(['allChannelsMap', channelId, 'isPrivate']),
        true
      );
    }
  }

  state = saveSeenActivity(state, activity);

  state = saveChannelsOrder(state);

  return state;
}

function updateChannelDescription(state, action) {
  let { activity } = action;

  let { channelId, newVal } = activity;

  if (!state.hasIn(CWP(state).concat(['allChannelsMap', channelId]))) {
    // eslint-disable-next-line no-console
    console.log('action received for non existing channel');
    return state;
  }

  state = state.setIn(
    CWP(state).concat(['allChannelsMap', channelId, 'description']),
    newVal
  );

  state = saveSeenActivity(state, activity);

  return state;
}

// example js sort for ascending order
// if a is less than b, return -1
// If b is less than a, return 1
function channelSorter(a, b) {
  if (a.name < b.name) {
    return -1;
  } else {
    return 1;
  }
}

export function saveChannelsOrder(state) {
  // sort and build new map
  let allChannelsMap = state
    .getIn(CWP(state).concat(['allChannelsMap']))
    .toJS();
  let allChannels = Object.values(allChannelsMap);
  allChannels.sort(channelSorter);

  let orderedChannels = [];
  for (let channel of allChannels) {
    orderedChannels.push(channel.id);
  }

  state = state.setIn(
    CWP(state).concat(['orderedChannels']),
    Immutable.fromJS(orderedChannels)
  );

  return state;
}

function updateVisibility(state, channelId) {
  let isPrivate = state.getIn(
    CWP(state).concat(['allChannelsMap', channelId, 'isPrivate'])
  );

  if (!isPrivate) {
    // already shared, nothing to do
    return state;
  }

  // update milestone.isPrivate
  state = state.setIn(
    CWP(state).concat(['allChannelsMap', channelId, 'isPrivate']),
    false
  );

  // update all work items
  let workDetailsMap = state
    .getIn(CWP(state).concat(['workDetailsMap']))
    .toJS();
  let allWork = Object.values(workDetailsMap);
  let channelWork = allWork.filter(
    (each) => each.channelIds.indexOf(channelId) !== -1
  );
  for (let each of channelWork) {
    state = state.setIn(
      CWP(state).concat(['workDetailsMap', each.id, 'isPrivate']),
      false
    );
  }

  return state;
}

function handleNewChannel(state, action) {
  let { channel, activity } = action;

  state = addChannel(state, channel);

  state = saveChannelsOrder(state);

  state = saveSeenActivity(state, activity);

  return state;
}
