import { Formik } from 'formik';
import React from 'react';
import ApiClient from '../../../../utils/classes/ApiClient';
import store from '../../../../store';
import WSTextField from '../../../UtilComponents/WSTextField';
import { SaveCancel } from '../../../UtilComponents/TinyComponents';

export default function MilestoneRenameForm(props) {
  let { milestone, hideForm } = props;

  let milestoneName = milestone.name;

  return (
    <Formik
      initialValues={{
        name: milestoneName,
      }}
      onSubmit={(
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        if (values.name === milestoneName) {
          setSubmitting(false);
          return;
        }

        ApiClient.post('milestone/rename', {
          name: values.name,
          milestoneId: milestone.id,
        }).then(
          (res) => {
            store.dispatch({
              activity: res.activity,
            });
            setSubmitting(false);
            hideForm();
          },
          (error) => {
            if (error.response.status == 400) {
              setErrors(error.response.data);
            }
            setSubmitting(false);
          }
        );
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex items-center gap-4">
            <div className="flex flex-col">
              <WSTextField
                required
                autoFocus={true}
                autoComplete="off"
                name="name"
                label="New Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              {errors.name && <div className="formError">{errors.name}</div>}
            </div>
            <SaveCancel handleCancel={hideForm} isSubmitting={isSubmitting} />
          </div>
        </form>
      )}
    </Formik>
  );
}
