import React, { useContext } from 'react';
import moment from 'moment';
import { Person, WS_ToolTip } from '../../../UtilComponents/TinyComponents';
import { STATUS_OPTIONS_MAP, UNKNOWN_CHANNEL } from '../../../../utils/consts';
import { AppContext, WorkspaceContext } from '../../../../utils/contexts';
import { getActIcon } from '../../../UtilComponents/Icons';
import {
  getActivityText,
  WORK_ACTIVITY_TYPE,
} from '../../../../utils/activityUtils';
import { UNSCHEDULED_TIMESTAMP } from '../../../../utils/timeUtils';

export function ItemActivity(props) {
  let { act } = props;

  let { allMilestonesMap, allChannelsMap, meId } = useContext(WorkspaceContext);

  let { scheduleSlotMap } = useContext(AppContext);

  let [activityText, primaryInfo, secondaryInfo, highlight] = getActivityInfo(
    act,
    meId,
    allChannelsMap,
    allMilestonesMap,
    scheduleSlotMap
  );

  let icon = getActIcon(act.type);

  if (!activityText) {
    return null;
  }

  let isMyActivity = act.createdBy === meId;

  return (
    <div
      className={`item-activity flex middle gap-4 py-2 px-2 ${
        isMyActivity ? 'my-act' : ''
      }`}
    >
      <div className="opacity-60 w-4">{icon}</div>
      <div className="flex-1 flex items-end gap-1">
        <div className={` ${highlight ? '' : ''}`}>{activityText}</div>
        {primaryInfo}
        {secondaryInfo ? (
          <WS_ToolTip
            contents={<div className="activity-sec-info">{secondaryInfo}</div>}
          >
            <div className="ml-2 helper-text text-xs inactive i activity-more-info-link underline">
              more
            </div>
          </WS_ToolTip>
        ) : null}
      </div>
      <Person id={act.createdBy} />
      <div className="w-1/12 flex justify-end">
        <WS_ToolTip
          contents={<span>{moment(act.createdAt).format('D MMM, YYYY')}</span>}
          placement="left"
        >
          <div className="text-xs font-light">
            {moment(act.createdAt).fromNow()}
          </div>
        </WS_ToolTip>
      </div>
    </div>
  );
}

function getActivityInfo(
  act,
  meId,
  allChannelsMap,
  allMilestonesMap,
  scheduleSlotMap
) {
  let primaryInfo = '';
  let secondaryInfo = null;
  let highlight = false;

  let activityType = act.type;
  if (!activityType) {
    // eslint-disable-next-line no-console
    console.warn('Activity type not found, ignoring activity');
    return null;
  }

  let activityText = getActivityText(act);

  switch (activityType) {
    case WORK_ACTIVITY_TYPE.NOTE_ADD.key: {
      // ignore addition activities
      activityText = null;
      break;
    }

    case WORK_ACTIVITY_TYPE.NOTE_EDIT.key: {
      // ignore addition activities
      activityText = null;
      break;
    }

    case WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key: {
      if (act.newVal === meId) {
        highlight = true;
      }

      primaryInfo = (
        <div className="flex gap-1">
          <div>to</div>
          <Person id={act.newVal} />
        </div>
      );

      secondaryInfo = (
        <div>
          <i>Previous:</i> <Person id={act.oldVal} />
        </div>
      );

      break;
    }

    case WORK_ACTIVITY_TYPE.SUBJECT_EDIT.key: {
      secondaryInfo = (
        <div>
          <i>New:</i>
          <br />
          {act.newVal}
          <br />
          <br />
          <i>Previous:</i>
          <br />
          {act.oldVal}
        </div>
      );
      break;
    }

    case WORK_ACTIVITY_TYPE.OWNER_EDIT.key: {
      primaryInfo = (
        <div className="flex gap-1">
          <div>to</div>
          <Person id={act.newVal} />
        </div>
      );

      if (act.newVal === meId) {
        highlight = true;
      }

      secondaryInfo = (
        <div>
          <i>Previous:</i> <Person id={act.oldVal} />
        </div>
      );

      break;
    }

    case WORK_ACTIVITY_TYPE.SCHEDULE_EDIT.key: {
      let newValText;
      if (act.newVal === UNSCHEDULED_TIMESTAMP) {
        newValText = scheduleSlotMap.UNSCHEDULED.text;
      } else {
        newValText = moment(act.newVal).format('Do MMM');
      }

      primaryInfo = <div>to {newValText}</div>;

      let oldValText;
      if (act.oldVal === UNSCHEDULED_TIMESTAMP) {
        oldValText = scheduleSlotMap.UNSCHEDULED.text;
      } else {
        oldValText = moment(act.oldVal).format('Do MMM');
      }
      secondaryInfo = (
        <div>
          <i>Previous:</i> {oldValText}
        </div>
      );

      break;
    }

    case WORK_ACTIVITY_TYPE.MILESTONE_EDIT.key: {
      if (act.newVal) {
        primaryInfo = <div>to {allMilestonesMap[act.newVal].name}</div>;
      }

      if (act.oldVal) {
        secondaryInfo = (
          <div>
            <i>Previous:</i> {allMilestonesMap[act.oldVal].name}
          </div>
        );
      }

      break;
    }

    case WORK_ACTIVITY_TYPE.STATUS_EDIT.key: {
      primaryInfo = <div>to {STATUS_OPTIONS_MAP[act.newVal].text}</div>;
      secondaryInfo = (
        <div>
          <i>Previous:</i>
          <br />
          {STATUS_OPTIONS_MAP[act.oldVal].text}
        </div>
      );
      break;
    }

    case WORK_ACTIVITY_TYPE.ADD_TO_CHANNEL.key: {
      let newChannelName = UNKNOWN_CHANNEL.name;

      let newChannel = allChannelsMap[act.newVal];

      if (newChannel) {
        newChannelName = newChannel.name;
      } else {
        activityText = null;

        // eslint-disable-next-line no-console
        console.log(
          'new channel not found for activity id',
          act.id,
          'channel id',
          act.newVal
        );

        break;
      }

      primaryInfo = <div>{newChannelName}</div>;

      break;
    }

    case WORK_ACTIVITY_TYPE.REMOVE_FROM_CHANNEL.key: {
      let newChannelName = UNKNOWN_CHANNEL.name;

      let newChannel = allChannelsMap[act.newVal];

      if (newChannel) {
        newChannelName = newChannel.name;
      } else {
        activityText = null;

        // eslint-disable-next-line no-console
        console.log(
          'new channel not found for activity id',
          act.id,
          'channel id',
          act.newVal
        );

        break;
      }

      primaryInfo = <div>{newChannelName}</div>;

      break;
    }
  }

  return [activityText, primaryInfo, secondaryInfo, highlight];
}
