import { hot } from 'react-hot-loader/root';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import MemberArea from './MemberArea/Index';
import { connect } from 'react-redux';
import AlertContainer from './UtilComponents/AlertContainer.jsx';
import GuestArea from './GuestArea/Index';
import UserSession from '../utils/classes/UserSession';
import UserArea from './UserArea/Index';
import { ERR_ALERT, INIT_PROFILE } from '../redux/actions/AppActionTypes';
import ApiClient from '../utils/classes/ApiClient';
import { useEffect } from 'react';
import store from '../store';
import history from '../history';
import { AppContext } from '../utils/contexts';
import { usePrevious } from '../utils/hook';
import UserVerify from './GuestArea/UserVerify';
import SysAdminArea from './SysAdminArea/Index';
import cssVars from '../styles/export.module.scss';
import { ApiLoader } from './UtilComponents/MUICustom';
import { getTodayClientStartTime } from '../utils/timeUtils';

function App(props) {
  let { appState } = props;

  useEffect(() => {
    document.body.classList.add('dark');
  }, []);

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <ApiLoader show={appState.showAppLoader} />
      {
        <Switch>
          <Route
            path="/verify"
            render={(props) => <UserVerify {...props} appState={appState} />}
          />
          <Route
            path="/guest"
            render={(props) => <GuestArea {...props} appState={appState} />}
          />
          <Route
            path="*"
            render={(props) => <AuthRoutes {...props} appState={appState} />}
          />
        </Switch>
      }
      <AlertContainer />
    </div>
  );
}

function AuthRoutes(props) {
  let { appState } = props;

  if (UserSession.getVerifyUserId()) {
    history.push('/verify');
    return null;
  }

  if (!UserSession.getApiKey()) {
    history.push('/guest/login');
    return null;
  }

  const currentUserId = appState.currentUserId;
  const prevCurrentUserId = usePrevious(currentUserId);

  useEffect(() => {
    if (currentUserId === prevCurrentUserId) {
      // this if condition is needed to avoid refiring this effect on hot reload.
      // Check out link below for more insight:
      // https://stackoverflow.com/questions/58374124/hot-reload-not-working-properly-with-react-redux-and-hooks
      return;
    }

    // api key is set (user session exists); fetch membership info
    ApiClient.get('user/profile', true).then(
      (profileData) => {
        store.dispatch({ type: INIT_PROFILE, profileData });
      },
      () => {
        store.dispatch({
          type: ERR_ALERT,
        });
      }
    );
  }, [currentUserId]);

  let minWidth = 1100;
  let minHeight = 400;

  // device detecttion example
  let [isMobile, setIsMobile] = useState(
    window.innerWidth <= minWidth || window.innerHeight <= minHeight
  );

  function updateIsMobile() {
    setIsMobile(
      window.innerWidth <= minWidth || window.innerHeight <= minHeight
    );
  }

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  const [todayStartTimeClient, setTodayStartTimeClient] = useState(
    appState.todayStartTimeClient
  );

  function updateClientStartTimes() {
    setTodayStartTimeClient(getTodayClientStartTime());
  }

  useEffect(() => {
    // This effect works in a loop:\
    // timeout callback updates todayStartTimeClient\
    // which them fires this effect, setting up a new timeout.
    //
    // Check every 5 minutes if the day has changed and\
    // update client't notion of today if needed\
    // to force a refresh
    const TEN_MINS = 1000 * 60 * 10;
    let timer = setTimeout(updateClientStartTimes, TEN_MINS);
    return () => {
      clearTimeout(timer);
    };
  }, [todayStartTimeClient]);

  if (!appState.userProfile.id) {
    // profile not fetched yet
    return <div className="cm mt-8">Loading Profile...</div>;
  }

  var isWindows = navigator.userAgent.indexOf('Windows') !== -1;

  let appContext = {
    seenIntroStep: appState.userProfile.seenIntroStep,
    membershipMap: appState.membershipMap,
    isMobile,
    backwardTimeGroupsMap: appState.backwardTimeGroupsMap,
    cssVars,
    todayStartTimeClient: appState.todayStartTimeClient,
    thisWeekMondayStartTime_Client: appState.thisWeekMondayStartTime_Client,
    scheduleSlotMap: appState.scheduleSlotMap,
    isWindows,
  };

  return (
    <AppContext.Provider value={appContext}>
      <Switch>
        <Route
          path="/user"
          render={(props) => <UserArea {...props} appState={appState} />}
        />
        <Route
          path="/sysadmin"
          render={(props) => (
            <SysAdminArea {...props} userProfile={appState.userProfile} />
          )}
        />
        <Route
          path="/"
          render={(props) => <MemberArea {...props} appState={appState} />}
        />
      </Switch>
    </AppContext.Provider>
  );
}

function mapStateToProps(state) {
  return {
    appState: state.appState.toJS(), // this is passed only to trigger rerender post login/signup; required
  };
}

export default connect(mapStateToProps)(hot(App));
