import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import ApiClient from '../../utils/classes/ApiClient';
import WSTextField from '../UtilComponents/WSTextField';
import {
  LOGIN_SUCCESS,
  REQUIRE_VERIFY,
} from '../../redux/actions/AppActionTypes';
import store from '../../store';
import { WSGuestButton } from '../UtilComponents/Buttons';

export default function Login() {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center items-center gap-24 pt-20">
        <h2 className="text-2xl active">Login</h2>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={(
            values,
            { setSubmitting, setErrors /* setValues and other goodies */ }
          ) => {
            ApiClient.post('guest/login', {
              email: values.email,
              password: values.password,
            }).then(
              (res) => {
                setSubmitting(false);

                if (res.verifyUserId) {
                  store.dispatch({
                    type: REQUIRE_VERIFY,
                    verifyUserId: res.verifyUserId,
                  });
                } else {
                  store.dispatch({
                    type: LOGIN_SUCCESS,
                    apiKey: res.apiKey,
                    userId: res.userId,
                  });
                }
              },
              (error) => {
                if (error.response.status == 400) {
                  setErrors(error.response.data);
                }
                setSubmitting(false);
              }
            );
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col items-center gap-4">
                <div className="w-80">
                  <WSTextField
                    name="email"
                    placeholder="Email"
                    type="text"
                    required
                    autoFocus
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </div>
                {errors.email && (
                  <div className="formError">{errors.email}</div>
                )}
                <div className="w-80">
                  <WSTextField
                    name="password"
                    placeholder="Password"
                    type="password"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    autoFocus={false}
                  />
                </div>
                {errors.password && (
                  <div className="formError">{errors.password}</div>
                )}
                <div>
                  <WSGuestButton type="submit" size="sm">
                    Submit
                  </WSGuestButton>
                </div>
                {errors.form && <div className="formError">{errors.form}</div>}
              </div>
            </form>
          )}
        </Formik>
        <div className="flex justify-between w-full text-base semi-active">
          <Link to="/guest/signup" className="anchor">
            Sign Up
          </Link>
          <Link to="/guest/forgotpass" className="anchor">
            Forgot Password
          </Link>
        </div>
      </div>
    </div>
  );
}
