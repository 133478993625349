import * as Types from '../../actions/AppActionTypes';
import UserSession from '../../../utils/classes/UserSession';
import {
  CWP,
  IP,
  saveSeenActivity,
  getInitialAppState,
  addMemberToWorkspace,
  logInvalidWork,
} from '.';
import { activitySorter } from '../../../utils/activityUtils';
import { STATUS_OPTIONS_MAP } from '../../../utils/consts';
import { itemSorterByStatus } from '../../../utils/sortUtils';
import { addChannel, saveChannelsOrder } from './channelState';
import { addMilestone, saveMilestonesOrder } from './milestoneState';
import centralisedListManager from './CentralisedListManager';

const Immutable = require('immutable');

export function handleClientAction(state, action) {
  switch (action.type) {
    case Types.REQUIRE_VERIFY: {
      let { verifyUserId } = action;
      state = state.set('verifyUserId', verifyUserId);
      UserSession.setVerifyUser(verifyUserId);
      return state;
    }

    case Types.RECON_ACTIVITY: {
      let { newActivity } = action;

      let activitiesSinceLastSync = state
        .getIn(CWP(state).concat(['activitiesSinceLastSync']))
        .toJS();

      for (let eachAct of newActivity) {
        if (!activitiesSinceLastSync[eachAct.id]) {
          // eslint-disable-next-line no-console
          console.log(
            'unseen activity found:',
            eachAct.id,
            '- Setting out of sync'
          );

          // reset workspace
          state = clearWorkspace(state);
          break;
        }
      }

      return state;
    }

    case Types.RESYNC_WORKSPACE: {
      state = clearWorkspace(state);

      return state;
    }

    case Types.LOGIN_SUCCESS: {
      state = state.set('currentUserId', action.userId);
      state = state.set('verifyUserId', null);
      UserSession.setCurrentUser(action.userId, action.apiKey);
      return state;
    }

    case Types.INIT_PROFILE: {
      let { profileData } = action;

      let { user: userProfile, membership: membershipArr } = profileData;

      state = state.set('userProfile', Immutable.fromJS(userProfile));

      if (!membershipArr.length) {
        // eslint-disable-next-line no-console
        console.log('no membership found');
        UserSession.clearMembership();
        return state;
      }

      let workspaces = {};
      let membershipMap = {};
      membershipArr.map((memberShip) => {
        let { self: member, workspace } = memberShip;
        member.workspace = workspace;
        membershipMap[member.id] = member;

        // following declaration can not be outside loop
        // related to lazy evaluation of map - everything is set to final state of modified var
        let workspaceInitData = initialiseWorkspace(
          member.workspaceId,
          member.id
        );

        workspaces[member.workspaceId] = workspaceInitData;
      });

      state = state.set('membershipMap', Immutable.fromJS(membershipMap));
      state = state.set('workspaces', Immutable.fromJS(workspaces));

      let sessionMemberId = UserSession.getCurrentMemberId();
      let sessionMembership;

      if (sessionMemberId && !membershipMap[sessionMemberId]) {
        // deactivated account
        sessionMemberId = null;
        UserSession.clearMembership();
      }

      if (sessionMemberId) {
        sessionMembership = membershipMap[sessionMemberId];
      } else if (membershipArr.length === 1) {
        sessionMembership = membershipArr[0].self;
      } else {
        // limbo case - no workspace saved in session and multiple workspaces exist
        // do nothing
      }

      if (sessionMembership) {
        state = setActiveWorkspace(state, sessionMembership);
      }

      return state;
    }

    case Types.SELECT_WORKSPACE: {
      let { memberId } = action;

      let activeMembership = state.getIn(['membershipMap', memberId]).toJS();
      state = setActiveWorkspace(state, activeMembership);

      return state;
    }

    case Types.DESELECT_WORKSPACE: {
      state = state.set('currentWorkspaceId', null);
      state = state.set('currentMemberId', null);
      UserSession.clearMembership();

      return state;
    }

    case Types.INIT_WORKSPACE_STATE: {
      let { workspaceData } = action;

      state = loadState(state, workspaceData);

      // just in case this was a out-of-sync reload case
      state = state.setIn(CWP(state).concat(['outOfSync']), false);

      return state;
    }

    case Types.LOGOUT: {
      UserSession.reset();
      return getInitialAppState();
    }

    case Types.SEEN_INTRO: {
      let { stepNum } = action;

      state = state.setIn(['userProfile', 'seenIntroStep'], stepNum);

      return state;
    }

    case Types.FRESH_WORK: {
      return registerFreshWork(state, action);
    }

    case Types.ARCHIVE_NOTIF: {
      let { activityId } = action;

      state = state.updateIn(CWP(state).concat(['notifList']), (list) =>
        list.delete(
          list.findIndex((eachAct) => eachAct.get('id') === activityId)
        )
      );

      return state;
    }

    case Types.GEN_NOTIF: {
      let { activity } = action;

      state = state.updateIn(CWP(state).concat(['notifList']), (list) =>
        list.unshift(Immutable.fromJS(activity))
      );

      return state;
    }

    // legacy - might be useful code if we go back to block based feeds
    case Types.ARCHIVE_FEED: {
      let { workIds, activityTypes, blockKeyType } = action;
      if (
        !Array.isArray(workIds) ||
        !Array.isArray(activityTypes) ||
        !blockKeyType
      ) {
        // eslint-disable-next-line no-console
        console.log('invalid parameter');
        return state;
      }

      for (let activityType of activityTypes) {
        state = state.deleteIn(
          CWP(state).concat(['feedMap', blockKeyType, activityType])
        );
      }

      return state;
    }

    case Types.TOGGLE_LOADER: {
      return state.set('showAppLoader', !state.get('showAppLoader'));
    }

    case Types.CLOSE_LOADER: {
      return state.set('showAppLoader', false);
    }

    case Types.CLEAR_MOVED_ITEM_PLACEHOLDER: {
      state = state.setIn(
        CWP(state).concat(['lastUpdatedWork', 'prevPosKeyInSourceList']),
        null
      );
      state = state.setIn(CWP(state).concat(['lastUpdatedWork', 'id']), null);
      return state;
    }

    case Types.PIN_ITEM: {
      return pinItem(state, action);
    }

    case Types.UNPIN_ITEM: {
      return unpinItem(state, action);
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn(
        'unknown action type',
        action.type,
        '(category:',
        action.category,
        ')'
      );
      return state;
    }
  }
}

function setActiveWorkspace(state, activeMembership) {
  state = state.set('currentWorkspaceId', activeMembership.workspaceId);
  state = state.set('currentMemberId', activeMembership.id);
  UserSession.setCurrentMember(
    activeMembership.id,
    activeMembership.workspaceId,
    activeMembership.token
  );

  return state;
}

function initialiseWorkspace(workspaceId, meId) {
  return {
    id: workspaceId,
    initFetched: false,
    meId: meId,
    membersMap: {},
    workDetailsMap: {},
    pageData: {},
    feedMap: {},
    notifsList: [],
    // channel
    allChannelsMap: {},
    // milestone
    allMilestonesMap: {},
    searchTerm: '',
    lastActivitySyncTime: +new Date(),
    activitiesSinceLastSync: {},
    outOfSync: false,
    refreshListFlag: true,
    pinsMap: {},
    lastUpdatedWork: {
      prevPosKeyInSourceList: null,
      id: null,
    },
  };
}

function unpinItem(state, action) {
  let { itemId } = action;

  if (!state.hasIn(IP(state, itemId))) {
    logInvalidWork();
    return state;
  }

  state = state.deleteIn(CWP(state).concat(['pinsMap', itemId]));

  // todo: update centralisedListManager

  return state;
}

function pinItem(state, action) {
  let { itemId, createdAt } = action;

  if (!state.hasIn(IP(state, itemId))) {
    logInvalidWork();
    return state;
  }

  state = state.setIn(CWP(state).concat(['pinsMap', itemId]), createdAt);

  // todo: update centralisedListManager

  return state;
}

function registerFreshWork(state, action) {
  let { freshWork, activity: changerActivity } = action;

  if (!freshWork.id) {
    // eslint-disable-next-line no-console
    console.log('received add work action without an id, skipping');
    return state;
  }

  if (state.hasIn(IP(state, freshWork.id))) {
    // sync delivery handling for new participants
    // avoids re-adding work that already exists
    // eslint-disable-next-line no-console
    console.log('add work received for an existing work');
    return state;
  }

  state = state.setIn(IP(state, freshWork.id), Immutable.fromJS(freshWork));

  centralisedListManager.registerFreshWork(state, freshWork, changerActivity);
  state = centralisedListManager.saveState(state);

  state = saveSeenActivity(state, changerActivity);

  return state;
}

function clearWorkspace(state, outOfSync = true) {
  let workspaceInitData = initialiseWorkspace(
    UserSession.getCurrentWorkspaceId(),
    UserSession.getCurrentMemberId()
  );
  workspaceInitData.outOfSync = outOfSync;

  state = state.setIn(CWP(state), Immutable.fromJS(workspaceInitData));
  centralisedListManager.reset();

  state = state.setIn(
    CWP(state).concat(['activitiesSinceLastSync']),
    Immutable.fromJS({})
  );
  state = state.setIn(CWP(state).concat(['lastActivitySyncTime']), +new Date());

  return state;
}

function loadState(state, workspaceData) {
  let {
    work,
    notifActs,
    members,
    channels,
    myPinsList,
    milestones,
  } = workspaceData;

  let meId = UserSession.getCurrentMemberId();

  // Pins List
  let pinsMap = {};
  myPinsList.map((each) => {
    pinsMap[each.workId] = each.createdAt;
  });

  state = state.setIn(
    CWP(state).concat(['pinsMap']),
    Immutable.fromJS(pinsMap)
  );

  // CHANNELS
  channels.map((channel) => {
    state = addChannel(state, channel);
  });
  state = saveChannelsOrder(state);

  // MILESTONES
  milestones.map((eachMilestone) => {
    state = addMilestone(state, eachMilestone);
  });
  state = saveMilestonesOrder(state);

  // WORK DETAILS
  let workDetailsMap = {};

  let allActivities = [];
  let allItemIds = work.map((eachItem) => {
    eachItem.activity.sort(activitySorter);

    allActivities = allActivities.concat(eachItem.activity);

    if (eachItem.status === STATUS_OPTIONS_MAP.CLOSED.key) {
      eachItem.archived = true;
    }

    // add to top level map
    workDetailsMap[eachItem.id] = eachItem;

    return eachItem.id;
  });

  allItemIds.sort(itemSorterByStatus(workDetailsMap));

  // set membersMap
  members.map((member) => {
    state = addMemberToWorkspace(state, member);
  });

  let scheduleSlotMap = state.get('scheduleSlotMap').toJS();
  let backwardTimeGroupsMap = state.get('backwardTimeGroupsMap').toJS();

  centralisedListManager.buildInitialPageData(
    workDetailsMap,
    scheduleSlotMap,
    backwardTimeGroupsMap,
    meId,
    allItemIds,
    allActivities
  );

  state = centralisedListManager.saveState(state);

  // state = state.setIn(
  //   CWP(state).concat(['feedMap']),
  //   Immutable.fromJS(getFeedMapOld(notifActs /*, meId, workDetailsMap*/))
  // );

  notifActs.sort(activitySorter);

  state = state.setIn(
    CWP(state).concat(['notifList']),
    Immutable.fromJS(notifActs)
  );

  state = state.setIn(
    CWP(state).concat(['workDetailsMap']),
    Immutable.fromJS(workDetailsMap)
  );

  // set flag for inital fetch done
  state = state.setIn(CWP(state).concat(['initFetched']), true);

  return state;
}
