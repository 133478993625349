import { useContext } from 'react';
import { PAGES, NAV_KEYS, PRIMARY_DATA_KEYS } from '../../../utils/consts';
import { NavLink } from 'react-router-dom';
import V2PageWithItemList from './_Layout/V2PageWithItemList';
import { RecentItemsList } from '../../UtilComponents/TinyComponents';
import { PageContext, WorkspaceContext } from '../../../utils/contexts';
import BasicList from '../Components/Common/BasicList/BasicList';
import { ListGroupHeader } from '../../UtilComponents/TinyComponents';
import { WS_CloseIcon, WS_SearchIcon } from '../../UtilComponents/Icons';

export default function SearchPlus(props) {
  let { match } = props;

  const { searchObject, resetSearchTerm } = useContext(WorkspaceContext);
  let { l1Nav = NAV_KEYS.MY_PARTICIPATIONS_ALL.key } = match.params;

  let pageContext = {
    pageKey: PAGES.SEARCH_PLUS.key,
    blockKey: { type: l1Nav },
  };
  let ListAreaComponent;

  let l1NavLinks = [
    NAV_KEYS.MY_PARTICIPATIONS_ALL.key,
    NAV_KEYS.MY_CREATIONS_OPEN.key,
    NAV_KEYS.MY_CREATIONS_CLOSED.key,
    NAV_KEYS.MY_WORK_HISTORY.key,
  ];

  let searchStarted = searchObject.searchTerm.length;

  if (searchStarted) {
    ListAreaComponent = SearchList;
    l1Nav = null;
  } else {
    let { pageData } = useContext(WorkspaceContext);

    let myPageData = pageData[PRIMARY_DATA_KEYS.MY_STREAM];
    let groupedEntries = myPageData[l1Nav];

    pageContext.groupedListEntries = groupedEntries;
    ListAreaComponent = RecentItemsList;
  }

  return (
    <PageContext.Provider value={pageContext}>
      <div className="flex flex-col px-4 py-4 gap-2 h-full">
        <div className="nav-strip seach-plus">
          <SearchBar />
          {l1NavLinks.map((eachNavKey) => {
            return (
              <NavLink
                onClick={resetSearchTerm}
                to={PAGES.SEARCH_PLUS.link + '/' + eachNavKey}
                className={`cursor-pointer hovered-active ${
                  l1Nav === eachNavKey ? 'active' : ''
                }`}
                key={eachNavKey}
              >
                {NAV_KEYS[eachNavKey].text}
              </NavLink>
            );
          })}
        </div>
        <V2PageWithItemList
          MainArea={() => <div />}
          ListArea={ListAreaComponent}
        />
      </div>
    </PageContext.Provider>
  );
}

function SearchList() {
  const { searchObject } = useContext(WorkspaceContext);

  let showHelper = !searchObject.searchDone;
  if (showHelper) {
    return (
      <div className="text-center">
        Type at least three characters to begin search
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {searchObject.searchDone ? (
        <div className="flex flex-col gap-16">
          <div>
            <ListGroupHeader
              title="OPEN ITEMS"
              itemCount={searchObject.activeItems.length}
            />
            <BasicList
              itemIds={searchObject.activeItems}
              listContext="open"
              hideZeroText
            />
          </div>
          <div>
            <ListGroupHeader
              title="CLOSED ITEMS"
              itemCount={searchObject.archivedItems.length}
            />
            <BasicList
              itemIds={searchObject.archivedItems}
              listContext="closed"
              hideZeroText
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

function SearchBar() {
  const {
    updateSearchTerm: onChange,
    searchObject,
    resetSearchTerm,
  } = useContext(WorkspaceContext);

  let searchStarted = searchObject.searchTerm.length;

  return (
    <div className="flex flex-col gap-2 items-center search-box">
      <div className="relative w-full">
        <input
          placeholder="Search All Items"
          value={searchObject.searchTerm}
          onChange={onChange}
          autoFocus
          type="text"
          className="simple"
          id="global-search-input"
          autoComplete="off"
        />
        <div
          className={`absolute search-icon ${searchStarted ? 'active' : ''}`}
        >
          <WS_SearchIcon style={{ fontSize: '1.3rem' }} />
        </div>
        <div
          className={`absolute flex items-center justify-center search-close-icon ${
            searchStarted ? 'visible' : 'invisible'
          }`}
          onClick={resetSearchTerm}
        >
          <WS_CloseIcon style={{ fontSize: '1rem' }} />
        </div>
      </div>
    </div>
  );
}
