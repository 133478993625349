import { STATUS_OPTIONS_MAP } from './consts';

export function memberSorter(membersMap, meId) {
  return (a, b) => {
    if (a === meId) {
      return -1;
    } else if (b === meId) {
      return 1;
    } else {
      if (membersMap[a].displayName < membersMap[b].displayName) {
        return -1;
      } else {
        return 1;
      }
    }
  };
}

export function notesSorter(a, b) {
  let aCreatedAt = a.createdAt;
  let bCreatedAt = b.createdAt;

  if (aCreatedAt > bCreatedAt) {
    return -1;
  } else {
    return 1;
  }
}

export function itemSorterByMyLastInvolvement(myLastActivityPerItem) {
  return (a, b) => {
    let myLastActivityTimeFor__A = myLastActivityPerItem[a].activity.createdAt;
    let myLastActivityTimeFor__B = myLastActivityPerItem[b].activity.createdAt;

    if (myLastActivityTimeFor__A > myLastActivityTimeFor__B) {
      return -1;
    } else {
      return 1;
    }
  };
}

export function itemSorterByStatus(workDetailsMap) {
  return (a, b) => {
    // priority item first
    let aIsPriority =
      workDetailsMap[a].status === STATUS_OPTIONS_MAP.URGENT.key;
    let bIsPriority =
      workDetailsMap[b].status === STATUS_OPTIONS_MAP.URGENT.key;

    if (aIsPriority && !bIsPriority) {
      return -1;
    } else if (!aIsPriority && bIsPriority) {
      return 1;
    }

    // reopened item next
    let aIsReopened =
      workDetailsMap[a].status === STATUS_OPTIONS_MAP.REOPENED.key;
    let bIsReopened =
      workDetailsMap[b].status === STATUS_OPTIONS_MAP.REOPENED.key;

    if (aIsReopened && !bIsReopened) {
      return -1;
    } else if (!aIsReopened && bIsReopened) {
      return 1;
    }

    // scheduled item next
    let aIsScheduled =
      workDetailsMap[a].status === STATUS_OPTIONS_MAP.SCHEDULED.key;
    let bIsScheduled =
      workDetailsMap[b].status === STATUS_OPTIONS_MAP.SCHEDULED.key;

    if (aIsScheduled && !bIsScheduled) {
      return -1;
    } else if (!aIsScheduled && bIsScheduled) {
      return 1;
    }

    // using statusUpdateTime is both the priority and schedule status is same
    let statusUpdateTime__A = workDetailsMap[a].statusUpdateTime;
    let statusUpdateTime__B = workDetailsMap[b].statusUpdateTime;

    if (statusUpdateTime__A < statusUpdateTime__B) {
      return -1;
    } else {
      return 1;
    }
  };
}

export function itemSorterByScheduleTimestamp(workDetailsMap) {
  return (a, b) => {
    // scheduleTimestamps are equal, priority item first
    let aIsPriority =
      workDetailsMap[a].status === STATUS_OPTIONS_MAP.URGENT.key;
    let bIsPriority =
      workDetailsMap[b].status === STATUS_OPTIONS_MAP.URGENT.key;

    if (aIsPriority && !bIsPriority) {
      return -1;
    } else if (!aIsPriority && bIsPriority) {
      return 1;
    }

    // both or not pinned, lower scheduleTimestamp first
    let scheduleTimestamp__A = workDetailsMap[a].scheduleTimestamp;
    let scheduleTimestamp__B = workDetailsMap[b].scheduleTimestamp;

    if (scheduleTimestamp__A < scheduleTimestamp__B) {
      return -1;
    } else if (scheduleTimestamp__A > scheduleTimestamp__B) {
      return 1;
    }

    // same priority, lower statusUpdateTime first
    let statusUpdateTime__A = workDetailsMap[a].statusUpdateTime;
    let statusUpdateTime__B = workDetailsMap[b].statusUpdateTime;

    if (statusUpdateTime__A < statusUpdateTime__B) {
      return -1;
    } else {
      return 1;
    }
  };
}
