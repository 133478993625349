import React from 'react';

export default function WSTextField(props) {
  let { onChange, onBlur, value, ...others } = props;

  return (
    <input
      type="text"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      {...others}
    />
  );
}
