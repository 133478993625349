import React from 'react';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { WorkspaceContext } from '../../../../utils/contexts';
import {
  WS_AddIcon,
  WS_MilestoneIcon,
  WS_PrivateChannelIcon,
} from '../../../UtilComponents/Icons';
import { WSPopover } from '../../../UtilComponents/MUICustom';
import CreateMilestoneForm from './CreateMilestoneForm';

export default function MilestoneBlock() {
  let { allMilestonesMap, orderedMilestones } = useContext(WorkspaceContext);

  let myMilestones = [];
  for (let eachMilestoneId of orderedMilestones) {
    if (allMilestonesMap[eachMilestoneId].isMine) {
      myMilestones.push(eachMilestoneId);
    }
  }

  return (
    <div className="nav-unit py-2 relative">
      <div
        className="flex items-center justify-between w-full text-xs px-4"
        id="milestone-section-head"
      >
        <div>MILESTONES</div>
        <WSPopover ContentComponent={CreateMilestoneForm}>
          <button className="transparent vsmall" id="milestone-create-link">
            <WS_AddIcon color="inherit" style={{ fontSize: '1.1rem' }} />
          </button>
        </WSPopover>
      </div>
      <MilestoneList myMilestones={myMilestones} />
    </div>
  );
}

function MilestoneList(props) {
  let { myMilestones } = props;
  let { handleNavClick, allMilestonesMap } = useContext(WorkspaceContext);

  return (
    <>
      {myMilestones.map((milestoneId) => {
        let milestone = allMilestonesMap[milestoneId];
        return (
          <NavLink
            to={'/milestone/' + milestone.id + '/'}
            className="nav-link hovered-active"
            activeClassName="active glass"
            key={milestone.id}
            onClick={handleNavClick}
          >
            <MilestoneLink milestone={milestone} />
          </NavLink>
        );
      })}
    </>
  );
}

function MilestoneLink(props) {
  let { milestone } = props;

  return (
    <div className="grid-x justify-between items-center">
      <div className="cell shrink mr-2 -mt-1">
        {milestone.isPrivate ? (
          <WS_PrivateChannelIcon style={{ fontSize: '0.9rem' }} />
        ) : (
          <WS_MilestoneIcon
            style={{
              fontSize: '0.9rem',
              verticalAlign: 'middle',
            }}
          />
        )}
      </div>
      <div className="cell auto">{milestone.name}</div>
    </div>
  );
}
