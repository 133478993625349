import { Route, Switch } from 'react-router-dom';
import store from '../../store';
import history from '../../history';
import UserSession from '../../utils/classes/UserSession';
import { LOGOUT, SELECT_WORKSPACE } from '../../redux/actions/AppActionTypes';
import moment from 'moment';

export default function UserArea(props) {
  let { appState } = props;

  return (
    <Switch>
      <Route
        path="/user/limbo"
        render={(props) => <Limbo {...props} appState={appState} />}
      />
      <Route
        path="*"
        render={() => (
          <div className="grid-y grid-frame cm text-center">
            Invalid address!
          </div>
        )}
      />
    </Switch>
  );
}

function Limbo(props) {
  let { appState } = props;

  if (UserSession.getCurrentMemberId()) {
    history.push('/');
    return null;
  }

  let userProfile = appState.userProfile;

  let logoutHandler = () => {
    store.dispatch({ type: LOGOUT });
    history.push('/guest/login');
  };

  let memberships = Object.values(appState.membershipMap);
  if (!memberships.length) {
    return (
      <div className="scroll-please cm grid-y grid-frame">
        <div className="cell">
          Your account is not attached to any workspaces.
        </div>
        <div>You are logged in with {userProfile.email} account</div>
        <div className="cell anchor" onClick={logoutHandler}>
          Login with a different account?
        </div>
      </div>
    );
  }

  function selectWorkspace(memberId) {
    return () => {
      store.dispatch({ type: SELECT_WORKSPACE, memberId });
    };
  }

  let i = 0;

  memberships.sort((a, b) => {
    if (a.workspace.createdAt < b.workspace.createdAt) {
      return -1;
    } else {
      return 1;
    }
  });

  return (
    <div className="flex w-full h-screen items-center justify-center">
      <div className="flex flex-col gap-4 semi-active items-center justify-center w-1/3">
        <div className="border-bottom text-lg text-center">
          Select a workspace
        </div>
        {memberships.map((each) => {
          return (
            <div
              className="cursor-pointer p-2 flex flex-col items-left gap-1 hovered-active"
              key={each.id}
              onClick={selectWorkspace(each.id)}
            >
              <div className="text-base">Workspace {i++}</div>
              <div className="semi-active">
                Created on{' '}
                {moment(each.workspace.createdAt).format('MMM D, YYYY')}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
