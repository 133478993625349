// APP / UX / FLOWS / GENERAL
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const CLOSE_LOADER = 'CLOSE_LOADER';
export const CREATE_ALERT = 'CREATE_ALERT';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const ERR_ALERT = 'ERR_ALERT'; // dummy action type to initiate errorFlow.js code
export const GEN_NOTIF = 'GEN_NOTIF';
export const SEEN_INTRO = 'SEEN_INTRO';
export const CLEAR_MOVED_ITEM_PLACEHOLDER = 'CLEAR_MOVED_ITEM_PLACEHOLDER';

// AUTH
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const RESYNC_WORKSPACE = 'RESYNC_WORKSPACE';
export const REQUIRE_VERIFY = 'REQUIRE_VERIFY';

// WORKSPACE
export const SELECT_WORKSPACE = 'SELECT_WORKSPACE';
export const DESELECT_WORKSPACE = 'DESELECT_WORKSPACE';
export const INIT_WORKSPACE_STATE = 'INIT_WORKSPACE_STATE';
export const RECON_ACTIVITY = 'RECON_ACTIVITY';
export const INIT_PROFILE = 'INIT_PROFILE';
export const FRESH_WORK = 'FRESH_WORK';
export const PIN_ITEM = 'PIN_ITEM';
export const UNPIN_ITEM = 'UNPIN_ITEM';

// SUBITEMS
export const ADD_SUB_ITEM = 'ADD_SUB_ITEM';
export const ARCHIVE_SUB_ITEM = 'ARCHIVE_SUB_ITEM';
export const REOPEN_SUB_ITEM = 'REOPEN_SUB_ITEM';

// MEMBER
export const ARCHIVE_FEED = 'ARCHIVE_FEED';
export const ARCHIVE_NOTIF = 'ARCHIVE_NOTIF';
