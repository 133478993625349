import WSDescEditor from '../../UtilComponents/WSDescEditor';

export default function WIP() {
  return (
    <div className="h-screen">
      <div className="px-16 py-16 scroll-please">
        <div className="px-80 mt-32">
          <WSDescEditor initialData={initialDataDesc} />
        </div>
      </div>
    </div>
  );
}

const initialDataDesc = `
  <p>I am with you</p>
  <h2>in h2 headers</h2>
  <h3>and h3</h3>
  <h4>and h4 too</h4>
  <ol><li>in</li><li>order</li></ol>
  <ul><li>or</li><li>disorder</li></ul>
  <p>&nbsp;</p><p>&nbsp;</p>
  <p>with multiple blank lines</p>
  <blockquote><p>with quotes</p></blockquote>
  <p><span style="background-color:hsl(270,75%,60%);color:hsl(0,0%,100%);">with highlights and font colors</span></p>
  <p><s>during strikes</s> <strong>in bold</strong> <i>or italics</i> or even <u>underlines</u></p><p>
  <code>in inline code</code></p>
  <pre><code class="language-plaintext">Or Code Blocks</code></pre>
  <p style="margin-left:40px;">with indents</p>
  <p style="text-align:center;">centered texts</p>
  <p style="text-align:right;">and right aligned too</p>
  <figure class="table"><table><tbody>
    <tr><td>with</td><td>tables</td></tr><tr><td>and</td><td>cells</td></tr>
  </tbody></table></figure>
  <hr>
  <p>with links like <a target="_blank" rel="noopener noreferrer" href="https://google.com">this</a></p>
`;
