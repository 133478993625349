import Pusher from 'pusher-js';
import config from 'config';
import store from '../../store';
import {
  GEN_NOTIF,
  FRESH_WORK,
  RESYNC_WORKSPACE,
} from '../../redux/actions/AppActionTypes';
import UserSession from './UserSession';

export class PusherHandler {
  static pusher;

  static initPusher(userId) {
    if (this.pusher) {
      // eslint-disable-next-line no-console
      console.log('pusher already initialized; nothing to do');
      return;
    }

    // eslint-disable-next-line no-console
    console.log('Initialising pusher');

    // Enable pusher logging if not prod
    if (!config.isProd) {
      Pusher.logToConsole = true;
    }

    const pusherKey = config.pusherKey;
    // eslint-disable-next-line no-undef
    let pusherObj = new Pusher(pusherKey, {
      cluster: 'ap2',
      forceTLS: true,
    });

    const channel = pusherObj.subscribe(userId);
    channel.bind('update', messageHandler);

    this.pusher = pusherObj;
  }
}

function messageHandler(payload) {
  if (payload.workspaceId !== UserSession.getCurrentWorkspaceId()) {
    // eslint-disable-next-line no-console
    console.log('WS update received for a different workspace, skipping');
    return;
  }

  // eslint-disable-next-line no-console
  console.log('processing pusher update');

  let data = payload.data;

  if (data.resync) {
    store.dispatch({ type: RESYNC_WORKSPACE });
    return;
  }

  let activity = data.activity;
  if (!activity) {
    // eslint-disable-next-line no-console
    console.warn('activity not found in sync event');
    return;
  }

  let dispatchData = { ...data };
  if (data.freshWork) {
    // received work with the expectation that current member does not have it yet
    // (new participant)
    dispatchData.type = FRESH_WORK;
  }

  store.dispatch(dispatchData);

  let genFeed =
    activity.notifieeIds &&
    activity.notifieeIds.indexOf(UserSession.getCurrentMemberId()) !== -1;
  if (genFeed) {
    store.dispatch({ type: GEN_NOTIF, activity });
  }
}
