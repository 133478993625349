import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotFound } from '../../UtilComponents/TinyComponents';
import { WorkspaceContext } from '../../../utils/contexts';
import { useContext } from 'react';
import { PAGES, PRIMARY_DATA_KEYS } from '../../../utils/consts';
import MilestoneHome from '../Pages/MilestoneHome';

export default function MilestoneRoutesResolver(props) {
  let { match } = props;

  let { allMilestonesMap, setNewItemGoBackContext, pageData } = useContext(
    WorkspaceContext
  );

  let milestoneId = match.params.milestoneId;

  if (!milestoneId) {
    return <NotFound />;
  }

  let milestone = allMilestonesMap[milestoneId];

  if (!milestone || !milestone.isMine) {
    return <NotFound />;
  }

  useEffect(() => {
    setNewItemGoBackContext({
      key: PAGES.MILESTONE.key,
      path: '/milestone/' + milestone.id,
      title: milestone.name,
      pageId: milestone.id,
    });
  }, [milestone.id]);

  return (
    <Switch>
      <Route
        path="/milestone/:milestoneId/:l1Nav?"
        render={(props) => (
          <MilestoneHome
            {...props}
            milestone={milestone}
            pageData={pageData[PRIMARY_DATA_KEYS.MILESTONE][milestoneId]}
            key={'milestone_home_' + milestoneId}
          />
        )}
      />
    </Switch>
  );
}
