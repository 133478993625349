import React, { useContext } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { PageContext, WorkspaceContext } from '../../../../../utils/contexts';
import ItemCard from './ItemCard';
import { NoItemsInList } from '../../../../UtilComponents/TinyComponents';
import history from '../../../../../history';

export default function BasicList(props) {
  let {
    itemIds = [],
    hideZeroText,
    itemActivityMap = {},
    listContext = '',
  } = props;

  let {
    workDetailsMap,
    activeItemState,
    searchObject,
    lastUpdatedWork,
  } = useContext(WorkspaceContext);

  let { prevPosKeyInSourceList, id: lastUpdatedWorkId } = lastUpdatedWork;

  let { pageKey, pageId = '', blockKey } = useContext(PageContext);

  let searchOn = searchObject.on;

  let highlightedRef = useRef();
  let currentWorkId = activeItemState.workId;

  useEffect(() => {
    if (!searchOn && currentWorkId && highlightedRef.current) {
      highlightedRef.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }, [currentWorkId]);

  function handleItemClick(itemId) {
    return () => {
      if (currentWorkId && itemId === currentWorkId) {
        history.push('?');
      } else {
        history.push('?item=' + itemId);
      }
    };
  }

  let listBasePath = pageKey + pageId + blockKey.type;
  listBasePath = listBasePath + listContext;
  let listPath = listBasePath + '__';

  let previousItemId = '0';
  let placeHolderKeyMap = {};
  for (let eachId of itemIds) {
    let placeholderPath = listPath + previousItemId;
    placeHolderKeyMap[eachId] = placeholderPath;
    previousItemId = eachId;
  }

  let len = itemIds.length;

  let breakpointIndex = -1;
  let count = len;
  let showPlaceholder = false;
  let movedItemBasePath =
    prevPosKeyInSourceList && prevPosKeyInSourceList.split('__')[0];
  if (movedItemBasePath === listBasePath) {
    // last updated item belonged to this list
    if (itemIds.indexOf(lastUpdatedWorkId) === -1) {
      // last updated item is presently not in this list
      // definitely show placeholder
      showPlaceholder = true;
    } else {
      // check if position has changed
      if (placeHolderKeyMap[lastUpdatedWorkId] !== prevPosKeyInSourceList) {
        showPlaceholder = true;
      }
    }

    let i = 0;
    for (let eachId of itemIds) {
      let placeholderPath = placeHolderKeyMap[eachId];

      if (placeholderPath === prevPosKeyInSourceList) {
        breakpointIndex = i;
      }
      i++;
    }

    if (breakpointIndex !== -1) {
      // prevPosKeyInSourceList was never encountered, must have been the last item
      count = breakpointIndex;
    }
  }

  // split itemIds into two lists: prePlaceHolder and postPlaceHolder
  let secondGroup = [...itemIds];
  let firstGroup = secondGroup.splice(0, count);

  if (!len && !showPlaceholder && !hideZeroText) {
    return <NoItemsInList />;
  }

  return (
    <div className="flex flex-col gap-0.5 basic-list">
      {firstGroup.map((eachItemId) => {
        let work = workDetailsMap[eachItemId];
        if (!work) {
          // eslint-disable-next-line no-console
          console.log('Item not found for item id', eachItemId);
          return null;
        }

        let ref =
          currentWorkId && currentWorkId === work.id ? highlightedRef : null;

        return (
          <div
            className="item-card-container"
            ref={ref}
            onClick={handleItemClick(work.id)}
            key={work.id}
          >
            {
              <ItemCard
                work={work}
                activity={itemActivityMap[work.id]}
                prevItemXKey={placeHolderKeyMap[work.id]}
              />
            }
          </div>
        );
      })}
      {showPlaceholder ? <MovedItemPlaceHolder /> : null}
      {secondGroup.map((eachItemId) => {
        let work = workDetailsMap[eachItemId];
        if (!work) {
          // eslint-disable-next-line no-console
          console.log('Item not found for item id', eachItemId);
          return null;
        }

        let ref =
          currentWorkId && currentWorkId === work.id ? highlightedRef : null;

        return (
          <div
            className="cell item-card-container"
            ref={ref}
            onClick={handleItemClick(work.id)}
            key={work.id}
          >
            {
              <ItemCard
                work={work}
                activity={itemActivityMap[work.id]}
                prevItemXKey={placeHolderKeyMap[work.id]}
              />
            }
          </div>
        );
      })}
    </div>
  );
}

function MovedItemPlaceHolder() {
  return (
    <div className="moved-item-placeholder mx-3">
      <span>Item Relocated</span>
    </div>
  );
}
