import React from 'react';
import { STATUS_OPTIONS_MAP } from '../../../../utils/consts';
import { WS_DropdownIcon } from '../../../UtilComponents/Icons';
import { WSPopover } from '../../../UtilComponents/MUICustom';

export default function ItemStatusSelector(props) {
  let { selectedId, selectHandler, buttonDecorators = '', icon } = props;

  const handleChange = (newId) => {
    if (newId === selectedId) {
      return;
    }

    selectHandler(newId)();
  };

  return (
    <WSPopover
      ContentComponent={DropdownOptions}
      handleUpdate={handleChange}
      selected={selectedId}
      decoratorClass="ws-surface-2"
      anchorDecorationClass="cm"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div
        className={`ws-dropdown-button flex items-center gap-1 ${buttonDecorators}`}
      >
        {icon ? { icon } : null}
        <div
          className={`flex-auto ellipsis button-text ${
            selectedId === STATUS_OPTIONS_MAP.UNSCHEDULED.key ? 'unset' : ''
          }`}
        >
          {STATUS_OPTIONS_MAP[selectedId].text}
        </div>
        <div className="dropdown-icon">
          <WS_DropdownIcon aria-hidden="true" />
        </div>
      </div>
    </WSPopover>
  );
}

function DropdownOptions(props) {
  let { handleUpdate, handleClose, selected } = props;
  function handleChange(newStatus) {
    return (e) => {
      if (newStatus !== selected) {
        handleUpdate(newStatus);
      }
      handleClose(e);
    };
  }

  let statusOptions = Object.values(STATUS_OPTIONS_MAP);
  if (selected === STATUS_OPTIONS_MAP.CLOSED.key) {
    statusOptions = [STATUS_OPTIONS_MAP.REOPENED];
  } else {
    statusOptions = [
      STATUS_OPTIONS_MAP.UNSCHEDULED,
      STATUS_OPTIONS_MAP.SCHEDULED,
      STATUS_OPTIONS_MAP.URGENT,
      STATUS_OPTIONS_MAP.CLOSED,
    ];
  }

  return (
    <div className="ws-dropdown">
      <div className="ws-dropdown-list member-dropdown">
        <div className="dropdown-header">Status</div>
        {statusOptions.map((each) => {
          if (selected === each.key) {
            return null;
          }
          return (
            <div
              key={each.key}
              className={`${
                selected === each.key ? 'font-medium active' : ''
              } ${
                each.key === STATUS_OPTIONS_MAP.UNSCHEDULED.key ? 'italic' : ''
              } cursor-default select-none ws-dropdown-option px-4 py-2`}
              value={each.key}
              onClick={handleChange(each.key)}
            >
              {each.text}
            </div>
          );
        })}
      </div>
    </div>
  );
}
