import { OTHER_FEEDS, NAV_KEYS, STATUS_OPTIONS_MAP } from './consts';

export const ACTIVITY_CATEGORY = {
  BROWSER: 'BROWSER',
  MEMBER: 'MEMBER',
  WORK: 'WORK',
  CHANNEL: 'CHANNEL',
  MILESTONE: 'MILESTONE',
};

export const CHANNEL_ACTIVITY_TYPE = {
  NEW_CHANNEL: { key: 'NEW_CHANNEL' },
  UPDATE_CHANNEL_NAME: { key: 'UPDATE_CHANNEL_NAME' },
  REMOVE_CHANNEL_MEMBER: { key: 'REMOVE_CHANNEL_MEMBER' },
  ADD_CHANNEL_MEMBER: { key: 'ADD_CHANNEL_MEMBER' },
  UPDATE_CHANNEL_DESCRIPTION: { key: 'UPDATE_CHANNEL_DESCRIPTION' },
};

export const MEMBER_ACTIVITY_TYPE = {
  NEW_MEMBER: { key: 'NEW_MEMBER' },
  MEMBER_DEACTIVATE: { key: 'MEMBER_DEACTIVATE' },
};

export const MILESTONE_ACTIVITY_TYPE = {
  NEW_MILESTONE: { key: 'NEW_MILESTONE' },
  UPDATE_MILESTONE_NAME: { key: 'UPDATE_MILESTONE_NAME' },
  UPDATE_MILESTONE_OWNER: { key: 'UPDATE_MILESTONE_OWNER' },
  UPDATE_MILESTONE_DATE: { key: 'UPDATE_MILESTONE_DATE' },
  REMOVE_MILESTONE_MEMBER: { key: 'REMOVE_MILESTONE_MEMBER' },
  ADD_MILESTONE_MEMBER: { key: 'ADD_MILESTONE_MEMBER' },
  UPDATE_MILESTONE_DESCRIPTION: { key: 'UPDATE_MILESTONE_DESCRIPTION' },
};

export const WORK_ACTIVITY_TYPE = {
  NEW_WORK: {
    key: 'NEW_WORK',
    text: 'Created',
  },
  ASSIGNEE_EDIT: {
    key: 'ASSIGNEE_EDIT',
    text: 'Assigned',
  },
  OWNER_EDIT: {
    key: 'OWNER_EDIT',
    text: 'Owner Changed',
  },
  CLOSE: {
    key: 'CLOSE',
    text: 'Closed',
  },
  REOPEN: {
    key: 'REOPEN',
    text: 'Reopened',
  },
  SUBJECT_EDIT: {
    key: 'SUBJECT_EDIT',
    text: 'Subject updated',
  },
  STATUS_EDIT: {
    key: 'STATUS_EDIT',
    text: 'Status Updated',
  },
  ADD_TO_CHANNEL: {
    key: 'ADD_TO_CHANNEL',
    text: 'Applied tag',
  },
  REMOVE_FROM_CHANNEL: {
    key: 'REMOVE_FROM_CHANNEL',
    text: 'Removed tag',
  },
  DESCRIPTION_ADD: {
    key: 'DESCRIPTION_ADD',
    text: 'Description added',
  },
  DESCRIPTION_EDIT: {
    key: 'DESCRIPTION_EDIT',
    text: 'Description updated',
  },
  SCHEDULE_EDIT: {
    key: 'SCHEDULE_EDIT',
    text: 'Schedule updated',
  },
  NOTE_ADD: {
    key: 'NOTE_ADD',
    text: 'New note',
  },
  NOTE_EDIT: {
    key: 'NOTE_EDIT',
    text: 'Note edit',
  },
  MILESTONE_EDIT: {
    key: 'MILESTONE_EDIT',
    text: 'Milestone updated',
  },
  // NEW_ASSIGNMENT_FOR_ME: {
  //   derived: true,
  //   derivedFromKeys: [
  //     WORK_ACTIVITY_TYPE.NEW_WORK.key,
  //     WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key,
  //   ],
  //   key: 'NEW_ASSIGNMENT_FOR_ME',
  //   text: 'New Assignment',
  // },
  // NEW_OWNERSHIP_FOR_ME: {
  //   derived: true,
  //   derivedFromKeys: [
  //     WORK_ACTIVITY_TYPE.NEW_WORK.key,
  //     WORK_ACTIVITY_TYPE.OWNER_EDIT.key,
  //   ],
  //   key: 'NEW_OWNERSHIP_FOR_ME',
  //   text: 'New Ownership',
  // },
};

export function getFeedBlockKey(work, meId) {
  let blockKey = OTHER_FEEDS;
  if (work.assignedTo === meId) {
    blockKey = NAV_KEYS.ASSIGNED_TO_ME.key;
  } else if (work.owner === meId) {
    blockKey = NAV_KEYS.OWNED_BY_ME.key;
  } else if (work.createdBy === meId) {
    blockKey = NAV_KEYS.CREATED_BY_ME.key;
  }

  return blockKey;
}

export function getFeedNavKey(work, meId) {
  let navKey = NAV_KEYS.MY_PARTICIPATIONS_ALL.key;
  // assignment is given preference, so that a feed will come only\
  // to assigned-to-me block and not in owned-by-me block if I am\
  // both owner and assignee
  if (work.assignedTo === meId) {
    navKey = NAV_KEYS.ASSIGNED_TO_ME.key;
  } else if (work.owner === meId) {
    navKey = NAV_KEYS.OWNED_BY_ME.key;
  }

  return navKey;
}

export function activitySorter(a, b) {
  if (a.createdAt < b.createdAt) {
    return 1;
  } else {
    return -1;
  }
}

export function activitySorterOldestFirst(a, b) {
  if (a.createdAt > b.createdAt) {
    return 1;
  } else {
    return -1;
  }
}

export const ACTIVITY_CONTEXTS = {
  NOTIFICATION: {
    key: 'NOTIFICATION',
  },
  ITEM_DETAILS: {
    key: 'ITEM_DETAILS',
  },
  ITEM_CARD: {
    key: 'ITEM_CARD',
  },
};

export function getActivityText(
  activity,
  meId,
  context = ACTIVITY_CONTEXTS.ITEM_DETAILS.key
) {
  let activityType = activity.type;
  if (!activityType) {
    // eslint-disable-next-line no-console
    console.warn('Activity type not found, ignoring activity');
    return null;
  }

  let activityText = WORK_ACTIVITY_TYPE[activityType]
    ? WORK_ACTIVITY_TYPE[activityType].text
    : activityType;

  switch (activityType) {
    case WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key: {
      if (context === ACTIVITY_CONTEXTS.NOTIFICATION.key) {
        if (activity.newVal === meId) {
          activityText = 'New assignment for Me';
        } else {
          activityText = 'Item reassigned';
        }
      } else if (context === ACTIVITY_CONTEXTS.ITEM_CARD.key) {
        if (activity.newVal === meId) {
          activityText = { activityText } + ' To Me';
        } else if (activity.oldVal === meId) {
          activityText = 'Reassigned';
        }
      }
      break;
    }

    case WORK_ACTIVITY_TYPE.OWNER_EDIT.key: {
      if (activity.newVal === meId) {
        activityText = 'Owner set to Me';
      }
      break;
    }

    case WORK_ACTIVITY_TYPE.NOTE_EDIT.key:
    case WORK_ACTIVITY_TYPE.NOTE_ADD.key: {
      if (context === ACTIVITY_CONTEXTS.NOTIFICATION.key) {
        activityText = 'New Mention';
      } else if (context === ACTIVITY_CONTEXTS.ITEM_CARD.key) {
        activityText = 'Mentioned';
      }
      break;
    }

    case WORK_ACTIVITY_TYPE.MILESTONE_EDIT.key: {
      if (!activity.oldVal) {
        activityText = 'Milestone set';
      } else if (!activity.newVal) {
        activityText = 'Milestone unset';
      }

      break;
    }

    case WORK_ACTIVITY_TYPE.STATUS_EDIT.key: {
      if (activity.newVal === STATUS_OPTIONS_MAP.SCHEDULED.key) {
        activityText = 'Item Scheduled';
      } else if (activity.newVal === STATUS_OPTIONS_MAP.REOPENED.key) {
        activityText = 'Item Reopened';
      } else if (activity.newVal === STATUS_OPTIONS_MAP.CLOSED.key) {
        activityText = 'Item Closed';
      } else if (activity.newVal === STATUS_OPTIONS_MAP.UNSCHEDULED.key) {
        activityText = 'Item Unscheduled';
      } else if (activity.newVal === STATUS_OPTIONS_MAP.URGENT.key) {
        activityText = 'Item set to Urgent';
      }

      break;
    }
  }

  return activityText;
}

export function getMyDerivedFeed(feedMap, feedParentBlockKey) {
  let myFeeds = feedMap[feedParentBlockKey];

  // Merge new work and new assignment keys as a single new assignment key
  let needsMerging =
    feedParentBlockKey === NAV_KEYS.ASSIGNED_TO_ME.key ||
    feedParentBlockKey === NAV_KEYS.OWNED_BY_ME.key;

  let myDerivedFeed = {};
  let skipFeedKeys = [];
  if (needsMerging) {
    // sort our new_work first for assigned-to-me and owned-by-me sections
    let myNewWorkFeed = myFeeds[WORK_ACTIVITY_TYPE.NEW_WORK.key];

    let blockFeedsToMerge = {};
    let derivedFeedKey;
    if (feedParentBlockKey === NAV_KEYS.ASSIGNED_TO_ME.key) {
      blockFeedsToMerge = myFeeds[WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key];
      derivedFeedKey = WORK_ACTIVITY_TYPE.NEW_ASSIGNMENT_FOR_ME.key;
      skipFeedKeys = [
        WORK_ACTIVITY_TYPE.NEW_WORK.key,
        WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key,
      ];
    } else {
      blockFeedsToMerge = myFeeds[WORK_ACTIVITY_TYPE.OWNER_EDIT.key];
      derivedFeedKey = WORK_ACTIVITY_TYPE.NEW_OWNERSHIP_FOR_ME.key;
      skipFeedKeys = [
        WORK_ACTIVITY_TYPE.NEW_WORK.key,
        WORK_ACTIVITY_TYPE.OWNER_EDIT.key,
      ];
    }

    if (myNewWorkFeed || blockFeedsToMerge) {
      myDerivedFeed[derivedFeedKey] = {
        ...myNewWorkFeed,
        ...blockFeedsToMerge,
      };
    }
  }

  Object.keys(myFeeds).map((feedKey) => {
    if (skipFeedKeys.indexOf(feedKey) !== -1) {
      return;
    }
    myDerivedFeed[feedKey] = myFeeds[feedKey];
  });

  return myDerivedFeed;
}
