import React, { useEffect, useRef } from 'react';
import { useContext } from 'react';
import { WorkspaceContext } from '../../../../utils/contexts';
import ItemDetails from '../../Components/ItemDetails/ItemDetails';

// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { useRef } from 'react';

// element scroll example
// example useEffect
export default function V2PageWithItemList(props) {
  let { MainArea, ListArea } = props;

  // below code taken from here: https://gist.github.com/romanonthego/223d2efe17b72098326c82718f283adb
  // just run the effect on pathname and/or search change
  // const refContainer = useRef();
  // const location = useLocation();
  // useEffect(() => {
  //   refContainer.current.scrollTo({
  //     top: 0,
  //     behavior: 'smooth',
  //   });
  // }, [location]);

  let { activeItemState } = useContext(WorkspaceContext);

  let currentWorkId = activeItemState.workId;

  const statAreaContainerRef = useRef();
  useEffect(() => {
    // scroll to top when item popup is closed
    statAreaContainerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [currentWorkId]);

  /*
    grid-y works here but flex-col does not
    - header gets cut off when item page is loaded (and list scroll is needed)
  */
  return (
    <div className="flex flex-auto w-full gap-2 min-h-0">
      <div
        className={`list-container flex-col lg:flex ${
          currentWorkId ? 'hidden' : ''
        }`}
      >
        {/* <div className="list-area-header">{<UnityListHeader />}</div> */}
        <div
          className={`scroll-please list-area ${
            currentWorkId ? 'has-item-selected' : ''
          }`}
        >
          {ListArea ? <ListArea /> : null}
        </div>
      </div>
      <div className="flex-auto" ref={statAreaContainerRef}>
        {currentWorkId ? (
          <ItemDetails itemId={currentWorkId} key={currentWorkId} />
        ) : MainArea ? (
          <MainArea />
        ) : null}
      </div>
    </div>
  );
}
