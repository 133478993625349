import { all, takeEvery } from 'redux-saga/effects';
import { PusherHandler } from '../../utils/classes/PusherHandler';
import { INIT_PROFILE } from '../actions/AppActionTypes';

export default function* postAuthFlow() {
  yield all([takeEvery(INIT_PROFILE, initializePusher)]);
}

function* initializePusher(action) {
  let userId = action.profileData.user.id;

  try {
    PusherHandler.initPusher(userId);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  yield null;
}
