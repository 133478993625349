export const loaderDialogPaperStyles = {
  background: 'none',
  boxShadow: 'none',
};

export function getLoaderStyles(size) {
  let marginLeft = -size;

  let containerExtraMargin = 10;
  if (size <= 20) {
    containerExtraMargin = 5;
  }

  let containerSize = size + containerExtraMargin;

  let loader2 = {
    marginLeft,
  };

  let container = {
    width: containerSize,
    height: containerSize,
  };

  return {
    loader2,
    container,
  };
}

export function getAvatarStyler(size, fontSize, selfColor) {
  return (theme) => ({
    colors: {
      backgroundColor: selfColor,
      color: '#fff',
      width: theme.spacing(size),
      height: theme.spacing(size),
      fontSize,
      fontWeight: 300,
      border: '1px solid white',
    },
  });
}
