import React from 'react';
import { Link } from 'react-router-dom';
import ApiClient from '../../utils/classes/ApiClient';
import { Formik } from 'formik';
import WSTextField from '../UtilComponents/WSTextField';
import { REQUIRE_VERIFY } from '../../redux/actions/AppActionTypes';
import store from '../../store';
import { WSGuestButton } from '../UtilComponents/Buttons';

export default function Signup() {
  let selectedPlan = new URLSearchParams(location.search).get('plan');
  if (!selectedPlan) {
    selectedPlan = 'starter';
  }

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center items-center gap-24 pt-20">
        <h2 className="text-2xl active">Sign Up</h2>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={(
            values,
            { setSubmitting, setErrors /* setValues and other goodies */ }
          ) => {
            ApiClient.post('guest/register', {
              email: values.email,
              password: values.password,
              plan: new URLSearchParams(location.search).get('plan'),
            }).then(
              (res) => {
                setSubmitting(false);
                store.dispatch({
                  type: REQUIRE_VERIFY,
                  verifyUserId: res.verifyUserId,
                });
              },
              (error) => {
                if (error.response.status == 400) {
                  setErrors(error.response.data);
                }
                setSubmitting(false);
              }
            );
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4 items-center">
                <div className="w-80">
                  <WSTextField
                    name="email"
                    placeholder="Email"
                    required
                    autoFocus
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </div>
                {errors.email && (
                  <div className="formError">{errors.email}</div>
                )}
                <div className="w-80">
                  <WSTextField
                    name="password"
                    placeholder="Password"
                    type="password"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    autoFocus={false}
                  />
                </div>
                {errors.password && (
                  <div className="formError">{errors.password}</div>
                )}
                <div>
                  <WSGuestButton type="submit" size="sm">
                    Submit
                  </WSGuestButton>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <div className="text-base semi-active">
          Already have an account?&nbsp;
          <Link to="/guest/login" className="anchor">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}
