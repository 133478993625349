import React from 'react';
import ApiClient from '../../../utils/classes/ApiClient';
import store from '../../../store';
import { useContext } from 'react';
import { PageContext, WorkspaceContext } from '../../../utils/contexts';
import { PAGES } from '../../../utils/consts';
import { NavLink } from 'react-router-dom';

export default function WorkspaceAdmin(props) {
  let { membersMap, meId } = useContext(WorkspaceContext);

  let self = membersMap[meId];
  if (!self.isAdmin) {
    return (
      <div className="scroll-please cm grid-y grid-frame">
        <div className="cell">
          You must be an admin to access these functions!
          <br />
          Current Admins:
          {Object.values(membersMap).map((each) => {
            if (!each.isAdmin) {
              return null;
            }
            return <div key={each.id}>{each.displayName}</div>;
          })}
        </div>
      </div>
    );
  }

  let { match } = props;

  let navLinkMap = {
    WORKSPACE_MEMBERS: {
      key: 'WORKSPACE_MEMBERS',
      text: 'Workspace Members',
      subKeys: {
        ACTIVE: { key: 'ACTIVE', text: 'Active' },
        INACTIVE: { key: 'INACTIVE', text: 'Inactive' },
      },
    },
  };

  let {
    l1Nav = navLinkMap.WORKSPACE_MEMBERS.key,
    l2Nav = navLinkMap.WORKSPACE_MEMBERS.subKeys.ACTIVE.key,
  } = match.params;

  let l2NavMap = navLinkMap[l1Nav].subKeys;

  let workspaceMembersList = Object.values(membersMap);
  let memberList = [];
  if (l2Nav === navLinkMap.WORKSPACE_MEMBERS.subKeys.ACTIVE.key) {
    memberList = workspaceMembersList.filter((each) => each.active);
  } else {
    memberList = workspaceMembersList.filter((each) => !each.active);
  }

  const pageContext = {
    pageKey: PAGES.WORKSPACE_ADMIN.key,
    memberList: memberList,
  };

  return (
    <PageContext.Provider value={pageContext}>
      <div className="flex flex-col p-4 gap-4 h-full">
        <div className="flex flex-col gap-2">
          <div className="flex gap-16 glass-r1 elevated py-4 px-8">
            {Object.keys(navLinkMap).map((eachNavKey) => {
              return (
                <NavLink
                  to={PAGES.WORKSPACE_ADMIN.link + '/' + eachNavKey}
                  className={`cursor-pointer ${
                    l1Nav === eachNavKey ? 'active' : ''
                  }`}
                  key={eachNavKey}
                >
                  {navLinkMap[eachNavKey].text}
                </NavLink>
              );
            })}
          </div>
          <div className="flex gap-16 glass-r1 elevated py-4 px-8">
            {Object.keys(l2NavMap).map((eachNavKey) => {
              return (
                <NavLink
                  to={
                    PAGES.WORKSPACE_ADMIN.link + '/' + l1Nav + '/' + eachNavKey
                  }
                  className={`cursor-pointer ${
                    l2Nav === eachNavKey ? 'active' : ''
                  }`}
                  key={eachNavKey}
                >
                  {l2NavMap[eachNavKey].text}
                </NavLink>
              );
            })}
          </div>
        </div>
        <Main />
      </div>
    </PageContext.Provider>
  );
}

function Main() {
  let { memberList } = useContext(PageContext);

  function deactivateMember(memberId) {
    return () => {
      return ApiClient.post('workspace/deactivateMember', {
        memberid: memberId,
      }).then((res) => {
        store.dispatch({ activity: res.activity });
      });
    };
  }

  let { meId } = useContext(WorkspaceContext);

  if (!memberList.length) {
    return (
      <div className="nothing-here glass-r2 p-8 cm memberlist-container">
        Nothing here
      </div>
    );
  }

  memberList.sort((a, b) => {
    // me first
    if (a.id === meId) {
      return -1;
    } else if (b.id === meId) {
      return 1;
    }

    // registered members next
    if (a.registered && !b.registered) {
      return -1;
    } else if (!a.registered && b.registered) {
      return 1;
    }

    // sort by email
    if (a.email < b.email) {
      return -1;
    } else {
      return 1;
    }
  });

  return (
    <div className="flex flex-col gap-4 glass-r2 p-8 memberlist-container scroll-please">
      {memberList.map((member) => {
        return (
          <div className="flex justify-between member-row" key={member.id}>
            <div>
              {member.email}
              {member.id === meId ? (
                <span>
                  &nbsp;<b>(You)</b>
                </span>
              ) : member.registered || !member.active ? null : (
                <span>&nbsp;(Yet to Join)</span>
              )}
            </div>
            <div>
              {member.active && member.id !== meId ? (
                <span
                  className="anchor text-xs"
                  onClick={deactivateMember(member.id)}
                >
                  Deactivate
                </span>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}
