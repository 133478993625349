import { useRef, useEffect, useState } from 'react';

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export function useToggleState(defaultVal) {
  if (!defaultVal) {
    // handle undefined
    defaultVal = false;
  }

  const [state, setState] = useState(defaultVal);

  const toggle = () => {
    setState(!state);
  };

  return [state, toggle];
}

export function useClosureState(defaultVal) {
  const [state, setState] = useState(defaultVal);

  function stateSetter(val) {
    return () => {
      setState(val);
    };
  }

  return [state, stateSetter];
}

export function useTargetValueState(defaultValue) {
  const [value, setValue] = useState(defaultValue);

  const setTargetValue = (e) => {
    setValue(e.target.value);
  };

  const resetValue = () => {
    setValue(defaultValue);
  };

  return {
    value,
    setTargetValue,
    resetValue,
  };
}

export function useHoverState() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}
