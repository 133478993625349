import React from 'react';
import { NAV_KEYS, PAGES } from '../../../../utils/consts';
import store from '../../../../store';
import Logo from '../../../../images/logo-white-text.svg';
import { NavLink } from 'react-router-dom';
import {
  LOGOUT,
  DESELECT_WORKSPACE,
} from '../../../../redux/actions/AppActionTypes';
import { useContext } from 'react';
import { WorkspaceContext, AppContext } from '../../../../utils/contexts';
import { PersonAvatar } from '../../../UtilComponents/TinyComponents';
import {
  WS_AddIcon,
  WS_DropdownIconAlt,
  MyWorkIcon,
  WS_AddItemIcon,
  WS_SearchIcon,
  WS_NotifIcon,
} from '../../../UtilComponents/Icons';
import { WSPopover } from '../../../UtilComponents/MUICustom';
import { createItem } from '../../../../utils/api';
import { useTargetValueState } from '../../../../utils/hook';
import history from '../../../../history';
import InviteUserForm from './InviteUserForm';
import ChannelBlock from './ChannelBlock';
import MilestoneBlock from './MilestoneBlock';

export default function SuperNav() {
  return (
    <div className="flex flex-col justify-between super-nav h-full pt-4 semi-active">
      <div className="flex flex-col gap-6 scroll-please pb-16">
        <Profile />
        <PrimaryAction />
        <PrimaryLinks />
        <MilestoneBlock />
        <ChannelBlock />
        <InviteBlock />
      </div>
      <div className="">
        <Brand />
      </div>
    </div>
  );
}

function Brand() {
  // example eslint inline ignore
  // eslint-disable-next-line no-undef
  let title = 'WorkStudio v' + process.env.npm_package_version;
  return (
    <div className="grid-x align-left brand-area cm">
      <div className="cell shrink">
        <img src={Logo} className="h-8" title={title} />
      </div>
    </div>
  );
}

function PrimaryAction() {
  return (
    <WSPopover ContentComponent={NewItemForm}>
      <div className="flex justify-center px-4">
        <button className="glass w-full" id="item-create-link">
          <WS_AddItemIcon size={25} />
          <span className="pl-2 text-base active">New Item</span>
        </button>
      </div>
    </WSPopover>
  );
}

function NewItemForm(props) {
  let { handleClose } = props;
  let { setSuccessMsg } = useContext(WorkspaceContext);
  let { value: subject, setTargetValue } = useTargetValueState('');

  function saveItem(e) {
    e.preventDefault();
    createItem({
      subject: subject,
    }).then((work) => {
      setSuccessMsg('Item created successfully!');
      handleClose(e);
      let link = PAGES.ITEM.link + '/' + work.id + '?new=true';
      history.push(link);
    });
  }

  return (
    <form className="new-item-quick-form" onSubmit={saveItem}>
      <div className="flex flex-col gap-4 p-8">
        <div className="flex justify-between">
          <div className="text-lg active">New Item</div>
        </div>
        <div className="flex gap-4 items-center">
          <input
            type="text"
            value={subject}
            placeholder="Subject"
            onChange={setTargetValue}
            className="new-item-input"
            autoFocus
          />
        </div>
        <div>
          <button className="active" type="submit">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}

function PrimaryLinks() {
  return (
    <div>
      <NotifLink />
      <MyWorkLink />
      <SearchLink />
    </div>
  );
}

function MyWorkLink() {
  let { handleNavClick } = useContext(WorkspaceContext);

  let page = PAGES.MY_WORK;

  return (
    <NavLink
      to={page.link}
      className="nav-link hovered-active my-work-container"
      activeClassName="active glass"
      onClick={handleNavClick}
    >
      <div className="flex gap-2 items-center">
        <div className="w-4">
          <MyWorkIcon style={{ fontSize: '0.9rem', display: 'block' }} />
        </div>
        <div className="flex-1">{page.text}</div>
      </div>
    </NavLink>
  );
}

function NotifLink() {
  let { handleNavClick, notifListGroupedByImportance } = useContext(
    WorkspaceContext
  );

  let page = PAGES.NOTIF;

  let hasImpNotifs =
    notifListGroupedByImportance[NAV_KEYS.NOTIF_IMP.key].length;
  let hasGeneralNotifs =
    notifListGroupedByImportance[NAV_KEYS.NOTIF_GENERAL.key].length;

  return (
    <NavLink
      to={page.link}
      className="nav-link hovered-active my-work-container"
      activeClassName="active glass"
      onClick={handleNavClick}
    >
      <div className="flex gap-2 items-center">
        <div className="w-4">
          <WS_NotifIcon style={{ fontSize: '1.1rem', marginLeft: -3 }} />
        </div>
        <div className="flex-1">{page.text}</div>
        {hasGeneralNotifs || hasImpNotifs ? (
          <div className={`feed-dot ${hasImpNotifs ? 'imp' : ''}`} />
        ) : null}
      </div>
    </NavLink>
  );
}

function SearchLink() {
  let { handleNavClick } = useContext(WorkspaceContext);

  let page = PAGES.SEARCH_PLUS;

  return (
    <NavLink
      to={page.link}
      className="nav-link hovered-active"
      activeClassName="active glass"
      onClick={handleNavClick}
      id="search-n-history-link"
    >
      <div className="flex gap-2 items-center">
        <div className="w-4">
          <WS_SearchIcon style={{ fontSize: '1.1rem', marginBottom: -1 }} />
        </div>
        <div className="flex-1">{page.text}</div>
      </div>
    </NavLink>
  );
}

function InviteBlock() {
  return (
    <div className="nav-unit py-2 relative flex flex-col gap-2">
      <WSPopover ContentComponent={InviteUserForm}>
        <div className="px-3 mt-2 invite-user-link hovered-active">
          <WS_AddIcon color="inherit" style={{ fontSize: '1rem' }} />
          &nbsp;<span className="anchor">Invite Teammate</span>
        </div>
      </WSPopover>
    </div>
  );
}

function Profile() {
  let { membersMap, meId } = useContext(WorkspaceContext);
  let selfDisplayName = membersMap[meId].displayName;
  let selfDomainName = membersMap[meId].domainName;

  return (
    <div className="profile-link">
      <WSPopover
        ContentComponent={ProfileOptions}
        decoratorClass="ws-surface-2"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="flex cursor-pointer gap-2">
          <PersonAvatar id={meId} large />
          <div className="flex-auto user-name">
            <div className="active">{selfDisplayName}</div>
            <div className="domain-name">{selfDomainName}</div>
          </div>
          <WS_DropdownIconAlt style={{ fontSize: '1.3rem' }} />
        </div>
      </WSPopover>
    </div>
  );
}

function ProfileOptions() {
  let { membershipMap } = useContext(AppContext);
  let showSwitchWorkspaceOption = Object.keys(membershipMap).length > 1;

  function switchWorkspace() {
    store.dispatch({ type: DESELECT_WORKSPACE });
  }

  let logoutHandler = () => {
    store.dispatch({ type: LOGOUT });
  };

  return (
    <div className="profile-options">
      <div className="flex flex-col">
        <div className="border-b border-gray-700 py-4">
          <div className="option">
            <NavLink to="/workspace/settings" exact>
              Manage Workspace
            </NavLink>
          </div>
        </div>
        <div className="py-4">
          {showSwitchWorkspaceOption ? (
            <div onClick={switchWorkspace} className="option cursor-pointer">
              Switch Workspace
            </div>
          ) : null}
          <div onClick={logoutHandler} className="option cursor-pointer">
            Logout
          </div>
        </div>
      </div>
    </div>
  );
}
