import { PAGES } from '../../../utils/consts';
import { PageContext } from '../../../utils/contexts';
import BasicPage from './_Layout/BasicPage';

export default function Exp() {
  let pageKey = PAGES.EXP.key;
  const pageContext = {
    pageKey,
  };

  return (
    <PageContext.Provider value={pageContext}>
      <BasicPage Header={Header} Main={Main} />
    </PageContext.Provider>
  );
}

function Main() {
  return (
    <div className="flex flex-col pt-4 px-32 pb-32 scroll-please exp">
      <MailUIs />
      {/* <WSTime /> */}
    </div>
  );
}

function Header() {
  return (
    <div className="grid-x align-justify">
      <div className="cell shrink title">Exp</div>
    </div>
  );
}

function MailTemplate(props) {
  let { children } = props;

  let paperStyles = {
    backgroundColor: '#f9f9f9',
    width: '100%',
    padding: '30px',
    color: 'rgba(0, 0, 0, 0.9)',
    fontSize: '14px',
  };
  let contentContainerStyles = {
    border: '1px solid #e8e8e8',
    borderRadius: '2px',
    backgroundColor: 'white',
    width: '400px',
    padding: '30px',
    margin: 'auto',
  };
  let headerStyles = {
    margin: 'auto',
    width: 'fit-content',
  };
  let bodyStyles = { fontSize: '16px', margin: '40px 0 20px 0' };
  let footerStyles = {
    margin: '20px auto 0 auto',
    width: '400px',
    color: 'rgba(115, 115, 115)',
    textAlign: 'center',
  };
  let logoStyles = { display: 'inline-block', height: '40px' };

  return (
    <div style={paperStyles}>
      <div style={contentContainerStyles}>
        <div style={headerStyles}>
          <img src="https://workstudio.app/logo.png" style={logoStyles} />
        </div>
        <div style={bodyStyles}>{children}</div>
      </div>
      <div style={footerStyles}>support@workstudio.app</div>
    </div>
  );
}

function MailUIs() {
  return (
    <div className="flex flex-col gap-8">
      <InviteMailUI />
      <OTPMailUI />
    </div>
  );
}

function InviteMailUI() {
  let saluteStyles = { marginBottom: '10px' };
  let buttonContainerStyles = {
    margin: '50px auto',
    width: 'fit-content',
  };
  let anchorStyles = {
    textDecoration: 'none',
    color: 'white',
    backgroundColor: '#4d4d74',
    padding: '10px 30px',
    fontSize: '20px',
    borderRadius: '5px',
    display: 'inline-block',
  };

  let signUpUrl = '/guest/signup';

  return (
    <MailTemplate>
      <div style={saluteStyles}>Hi john@acme.com</div>
      mary@acme.com has invited you to their workspace on WorkStudio, a
      collaborative work management solution.
      <div style={buttonContainerStyles}>
        <a style={anchorStyles} href={signUpUrl}>
          Sign Up
        </a>
      </div>
      Please don't hesitate to reach out to us at support@workstudio.app, in
      case you need any help or are facing any difficulty signing up.
    </MailTemplate>
  );
}

function OTPMailUI() {
  let bodyHeadStyles = {
    fontSize: '24px',
    fontWeight: '500',
    margin: '0 auto 30px auto',
  };
  let otpStyles = {
    fontSize: '32px',
    marginTop: '30px',
  };

  return (
    <MailTemplate>
      <center>
        <div style={bodyHeadStyles}>Confirm your email address</div>
        <div>Use the code below to confirm your account</div>
        <div style={otpStyles}>414566</div>
      </center>
    </MailTemplate>
  );
}
