import React from 'react';
import { useContext } from 'react';
import { WorkspaceContext } from '../../../../utils/contexts';
import { Person } from '../../../UtilComponents/TinyComponents';
import { memberSorter } from '../../../../utils/sortUtils';
import { WS_DropdownIcon } from '../../../UtilComponents/Icons';
import { WSPopover } from '../../../UtilComponents/MUICustom';

export default function MemberSelectorMenu(props) {
  let {
    selected,
    handleUpdate,
    headerText,
    buttonDecorators = '',
    placeHolderText,
    icon,
    type = 'button',
  } = props;

  let { membersMap, meId } = useContext(WorkspaceContext);

  let memberIds = Object.keys(membersMap).filter(
    (eachId) => membersMap[eachId].active
  );

  memberIds.sort(memberSorter(membersMap, meId));

  return (
    <WSPopover
      ContentComponent={DropdownOptions}
      headerText={headerText}
      memberIds={memberIds}
      handleUpdate={handleUpdate}
      selected={selected}
      decoratorClass="ws-surface-2"
      anchorDecorationClass="cm"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {type === 'button' ? (
        <div
          className={`ws-dropdown-button flex items-center gap-1 ${buttonDecorators}`}
        >
          {icon ? { icon } : null}
          <div className={'button-text flex-auto ellipsis'}>
            {selected ? (
              <Person id={selected} decorators="limited" />
            ) : (
              <>{placeHolderText}</>
            )}
          </div>
          <div className="dropdown-icon">
            <WS_DropdownIcon aria-hidden="true" />
          </div>
        </div>
      ) : (
        <div className={`${buttonDecorators}`}>
          {selected ? <Person id={selected} /> : <>{placeHolderText}</>}
        </div>
      )}
    </WSPopover>
  );
}

function DropdownOptions(props) {
  let { headerText, memberIds, handleUpdate, handleClose, selected } = props;
  function handleChange(newMemberId) {
    return (e) => {
      if (newMemberId !== selected) {
        handleUpdate(newMemberId);
      }
      handleClose(e);
    };
  }

  return (
    <div className="ws-dropdown">
      <div className="ws-dropdown-list member-dropdown">
        {headerText ? (
          <div className="dropdown-header">{headerText}</div>
        ) : null}
        {memberIds.map((eachId) => {
          if (selected === eachId) {
            return null;
          }

          return (
            <div
              key={eachId}
              className={`${
                selected === eachId ? 'font-medium active' : ''
              } cursor-default select-none ws-dropdown-option px-4 py-2`}
              value={eachId}
              onClick={handleChange(eachId)}
            >
              <Person id={eachId} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
