import { useState } from 'react';
import { PAGES } from './consts';

export default function getSuggestionUtils(
  allMilestonesMap,
  newItemGoBackContext
) {
  const [ownerSelected, setOwnerSelected] = useState(false);
  const [assigneeSelected, setAssigneeSelected] = useState(false);

  let defaultSuggestedChannelIds = [];
  if (newItemGoBackContext.key === PAGES.CHANNEL.key) {
    defaultSuggestedChannelIds.push(newItemGoBackContext.pageId);
  }
  let [suggestedChannelIds, setSuggestedChannelIds] = useState(
    defaultSuggestedChannelIds
  );

  let defaultSuggestedMilestoneIds = [];
  if (newItemGoBackContext.key === PAGES.MILESTONE.key) {
    defaultSuggestedMilestoneIds.push(newItemGoBackContext.pageId);
  }
  let [suggestedMilestoneIds, setSuggestedMilestoneIds] = useState(
    defaultSuggestedMilestoneIds
  );

  let defaultSuggestedOwnerIds = [];
  let [suggestedOwnerIds, setSuggestedOwnerIds] = useState(
    defaultSuggestedOwnerIds
  );

  let defaultSuggestedAssigneeIds = [];
  let [suggestedAssigneeIds, setSuggestedAssigneeIds] = useState(
    defaultSuggestedAssigneeIds
  );

  function updateSuggestionsForNewChannel() {
    setSuggestedChannelIds([]);
  }

  function updateSuggestionsForNewAssignee(newAssigneeId) {
    setSuggestedAssigneeIds([]);
    setAssigneeSelected(true);
    if (!ownerSelected && !suggestedOwnerIds.length) {
      // suggest assignee for owner if no other suggestions
      setSuggestedOwnerIds([newAssigneeId]);
    }
  }

  function updateSuggestionsForNewOwner(newOwnerId) {
    setOwnerSelected(true);
    setSuggestedOwnerIds([]);

    if (!assigneeSelected && !suggestedAssigneeIds.length) {
      // suggest owner for assignee if no prior suggestions
      setSuggestedAssigneeIds([newOwnerId]);
    }
  }

  function getOwnerSuggestionsForNewMilestone(milestoneId) {
    if (ownerSelected) {
      return [];
    }

    // override owner suggestions as per channel irrespective of previous suggestions

    let milestoneOwnerId = milestoneId
      ? allMilestonesMap[milestoneId].owner
      : null;

    return milestoneOwnerId ? [milestoneOwnerId] : [];
  }

  function getAssigneeSuggestionsForNewMilestone(milestoneId) {
    if (assigneeSelected) {
      return [];
    }

    // override assignee suggestions on channel change,\
    // only if there are no previous suggestions
    if (suggestedAssigneeIds.length) {
      return suggestedAssigneeIds;
    }

    let milestoneOwnerId = milestoneId
      ? allMilestonesMap[milestoneId].owner
      : null;

    return milestoneOwnerId ? [milestoneOwnerId] : [];
  }

  function updateSuggestionsForNewMilestone(newMilestoneId) {
    setSuggestedMilestoneIds([]);

    let suggestedOwnerIds = getOwnerSuggestionsForNewMilestone(newMilestoneId);
    setSuggestedOwnerIds(suggestedOwnerIds);

    let suggestedAssigneeIds = getAssigneeSuggestionsForNewMilestone(
      newMilestoneId
    );
    setSuggestedAssigneeIds(suggestedAssigneeIds);
  }

  return {
    // channel stuff
    suggestedChannelIds,
    updateSuggestionsForNewChannel,
    // assignee stuff
    suggestedAssigneeIds,
    updateSuggestionsForNewAssignee,
    // owner stuff
    suggestedOwnerIds,
    updateSuggestionsForNewOwner,
    // milestone stuff
    suggestedMilestoneIds,
    updateSuggestionsForNewMilestone,
  };
}
