import React from 'react';
import Logo from '../../images/logo-white-text.svg';
import config from 'config';

export default function Header() {
  function getWebsiteUri() {
    return config.websiteUri;
  }

  return (
    <nav className=" glass flex items-center justify-center py-2">
      <a href={getWebsiteUri()}>
        <img src={Logo} className="h-12" />
      </a>
    </nav>
  );
}
