import React from 'react';
import { Formik } from 'formik';
import ApiClient from '../../../../utils/classes/ApiClient';
import store from '../../../../store';
import WSTextField from '../../../UtilComponents/WSTextField';
import { SaveCancel } from '../../../UtilComponents/TinyComponents';
import history from '../../../../history';
import { useContext } from 'react';
import { WorkspaceContext } from '../../../../utils/contexts';

export default function CreateMilestoneForm(props) {
  let { handleClose: hideForm } = props;

  let { meId } = useContext(WorkspaceContext);

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      onSubmit={(
        values,
        {
          setSubmitting,
          setErrors,
          resetForm /* setValues and other goodies */,
        }
      ) => {
        ApiClient.post('workspace/createMilestone', {
          name: values.name,
          owner: meId,
        }).then(
          (res) => {
            setSubmitting(false);
            store.dispatch({
              milestone: res.milestone,
              activity: res.activity,
            });
            resetForm({ name: '' });
            if (hideForm) {
              hideForm();
            }
            history.push('/milestone/' + res.milestone.id + '/');
          },
          (error) => {
            setSubmitting(false);
            if (error.response.status == 400) {
              setErrors(error.response.data);
            }
          }
        );
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col p-8 gap-4">
            <div className="text-lg active">New Milestone</div>
            <WSTextField
              name="name"
              label="Name"
              placeholder="Milestone name"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              autoFocus
              required
              style={{ width: 300 }}
            />
            {errors.name && <div className="formError">{errors.name}</div>}
            <SaveCancel handleCancel={hideForm} isSubmitting={isSubmitting} />
          </div>
        </form>
      )}
    </Formik>
  );
}
