import React from 'react';
import CreateChannelForm from './CreateChannelForm';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { WorkspaceContext } from '../../../../utils/contexts';
import {
  WS_AddIcon,
  WS_ChannelIcon,
  WS_PrivateChannelIcon,
} from '../../../UtilComponents/Icons';
import { WSPopover } from '../../../UtilComponents/MUICustom';

export default function ChannelBlock() {
  return (
    <div className="nav-unit py-2 relative">
      <div
        className="flex items-center justify-between w-full text-xs px-4"
        id="channel-section-head"
      >
        <div>TAGS</div>
        <WSPopover ContentComponent={CreateChannelForm}>
          <button className="transparent vsmall" id="channel-create-link">
            <WS_AddIcon color="inherit" style={{ fontSize: '1.1rem' }} />
          </button>
        </WSPopover>
      </div>
      <ChannelList />
    </div>
  );
}

function ChannelList() {
  let { meId, handleNavClick, orderedChannels, allChannelsMap } = useContext(
    WorkspaceContext
  );

  return (
    <>
      {orderedChannels.map((channelId) => {
        let channel = allChannelsMap[channelId];

        return (
          <NavLink
            to={'/channel/' + channel.id + '/'}
            className="nav-link hovered-active"
            activeClassName="active glass"
            key={channel.id}
            onClick={handleNavClick}
          >
            <ChannelLink channel={channel} meId={meId} />
          </NavLink>
        );
      })}
    </>
  );
}

function ChannelLink(props) {
  let { channel } = props;

  return (
    <div className="grid-x justify-between items-center">
      <div className="cell shrink mr-2 -mt-1">
        {channel.isPrivate ? (
          <WS_PrivateChannelIcon style={{ fontSize: '0.9rem' }} />
        ) : (
          <WS_ChannelIcon
            style={{
              fontSize: '0.9rem',
              verticalAlign: 'middle',
            }}
          />
        )}
      </div>
      <div className="cell auto">{channel.name}</div>
    </div>
  );
}
