import React from 'react';
import { Formik } from 'formik';
import ApiClient from '../../../../utils/classes/ApiClient';
import store from '../../../../store';
import WSTextField from '../../../UtilComponents/WSTextField';
import { SaveCancel } from '../../../UtilComponents/TinyComponents';
import { useContext } from 'react';
import { WorkspaceContext } from '../../../../utils/contexts';

export default function InviteUserForm(props) {
  let { handleClose: hideForm } = props;
  let { setSuccessMsg } = useContext(WorkspaceContext);

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        ApiClient.post('workspace/inviteUser', {
          email: values.email,
        }).then(
          (res) => {
            setSubmitting(false);
            store.dispatch({
              member: res.member,
              activity: res.activity,
            });
            if (hideForm) {
              hideForm();
            }

            setSuccessMsg('Invite sent successfully!');
          },
          (error) => {
            setSubmitting(false);
            if (error.response.status == 400) {
              setErrors(error.response.data);
            }
          }
        );
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4 p-8">
            <div className="text-lg active">Invite Teammate</div>
            <WSTextField
              placeholder="john@example.com"
              name="email"
              label="Email id*"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              style={{ width: 300 }}
              autoFocus
            />
            {errors.email && <div className="formError">{errors.email}</div>}
            <SaveCancel handleCancel={hideForm} isSubmitting={isSubmitting} />
          </div>
        </form>
      )}
    </Formik>
  );
}
