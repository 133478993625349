var Linkify = require('react-linkify').default;

export default function WSNoteContent(props) {
  let { txt } = props;

  if (!txt) {
    return null;
  }

  return (
    <Linkify properties={{ target: '_blank' }}>
      {txt.split('\n').map((item, key) => {
        return (
          <div key={key}>
            <TextWithHighlightedMentions txt={item} />
          </div>
        );
      })}
    </Linkify>
  );
}

function TextWithHighlightedMentions(props) {
  const { txt } = props;
  const tokens = txt.split(/(@\[.*?\]\(.*?\))/);

  return (
    <div>
      {tokens.map((each, key) => {
        if (!each) {
          return null;
        }
        if (each.match(/@\[.*\]\(.*\)/)) {
          // is a mention
          each = each.replace(/@\[(.*)\].*/, '@$1');
          return (
            <span key={`note_token_${key}`}>
              <span className="note-mention">{each}</span>&nbsp;
            </span>
          );
        }

        return <span key={`note_token_${key}`}>{each}</span>;
      })}
    </div>
  );
}
