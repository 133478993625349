import { useContext } from 'react';
import { PageContext } from '../../../../utils/contexts';
import { useToggleState } from '../../../../utils/hook';
import ApiClient from '../../../../utils/classes/ApiClient';
import store from '../../../../store';
import WSDescEditor from '../../../UtilComponents/WSDescEditor';
import {
  Person,
  WSDescriptionContent,
} from '../../../UtilComponents/TinyComponents';
import MemberSelectorMenu from '../Common/MemberSelectorMenu';
import moment from 'moment';
import { WS_MilestoneIcon } from '../../../UtilComponents/Icons';
import MilestoneMembersSection from './MilestoneMembersSection';
import MilestoneRenameForm from './MilestoneRenameForm';
import DateSelector from '../../../UtilComponents/DateSelector';
import { updateMilestoneDate } from '../../../../utils/api';

export default function MilestoneDetails() {
  return (
    <div className="flex flex-col gap-16 channel-details scroll-please">
      <Header />
      <LeadInfo />
      <div className="flex-auto flex flex-col gap-8">
        <MilestoneMembersSection />
      </div>
      <CreationInfo />
    </div>
  );
}

function Header() {
  let { milestone } = useContext(PageContext);

  const [renameMode, toggleRenameMode] = useToggleState(false);

  function handleDateChange(dateText) {
    updateMilestoneDate(milestone.id, dateText);
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-end justify-between">
        {renameMode ? (
          <MilestoneRenameForm
            milestone={milestone}
            hideForm={toggleRenameMode}
          />
        ) : (
          <div className="flex gap-2 items-center">
            <WS_MilestoneIcon
              style={{
                fontSize: '1.3rem',
                verticalAlign: 'middle',
              }}
            />
            <div
              className="text-lg semi-active cursor-pointer hovered-active"
              onClick={toggleRenameMode}
            >
              {milestone.name}
            </div>
          </div>
        )}
        <DateSelector
          processUpdate={handleDateChange}
          timestamp={milestone.endDateTs}
        />
      </div>
      <DescriptionSection />
    </div>
  );
}

function DescriptionSection() {
  let { milestone } = useContext(PageContext);

  const [descriptionEditMode, toggleDescriptionEditMode] = useToggleState(
    false
  );

  function handleDescriptionSave(newDesc) {
    ApiClient.post('milestone/editDescription', {
      description: newDesc,
      milestoneId: milestone.id,
    }).then((res) => {
      store.dispatch({
        activity: res.activity,
      });
      toggleDescriptionEditMode();
    });
  }

  return (
    <div className="flex flex-col gap-4 relative">
      {descriptionEditMode ? (
        <WSDescEditor
          handleSave={handleDescriptionSave}
          handleCancel={toggleDescriptionEditMode}
          initialData={milestone.description}
        />
      ) : !milestone.description ? (
        <div
          className="cursor-pointer unset py-2 w-32 opacity-60"
          onClick={toggleDescriptionEditMode}
        >
          Add description
        </div>
      ) : (
        <WSDescriptionContent
          description={milestone.description}
          handleEditClick={toggleDescriptionEditMode}
        />
      )}
    </div>
  );
}

function LeadInfo() {
  let { milestone } = useContext(PageContext);

  let milestoneId = milestone.id;

  function updateOwner(owner) {
    return ApiClient.post('milestone/editOwner', { milestoneId, owner }).then(
      (res) => {
        store.dispatch({
          activity: res.activity,
        });
      }
    );
  }

  return (
    <div className="flex items-center gap-4 hovered-semi-active opacity-60">
      <div>Led by</div>
      <div className="w-max">
        <MemberSelectorMenu
          selected={milestone.owner}
          handleUpdate={updateOwner}
          buttonDecorators="semi-active"
        />
      </div>
    </div>
  );
}

function CreationInfo() {
  let { milestone } = useContext(PageContext);
  return (
    <div className="flex justify-end gap-1 font-light text-xs tracking-wide hovered-semi-active">
      <div>Created by</div>
      <Person id={milestone.createdBy} />
      <div>|</div>
      <div>{moment(milestone.createdAt).fromNow()}</div>
    </div>
  );
}
