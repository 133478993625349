import moment from 'moment';
import { Person } from '../../../UtilComponents/TinyComponents';
import { useContext } from 'react';
import { WorkspaceContext } from '../../../../utils/contexts';
import { useToggleState } from '../../../../utils/hook';
import CommentForm from './CommentForm';
import WSNoteContent from '../../../UtilComponents/WSNoteContent';
import { WS_EditIcon } from '../../../UtilComponents/Icons';

export default function Note(props) {
  let { note } = props;
  let content = note.content;

  const { meId } = useContext(WorkspaceContext);

  let [editNoteMode, toggleEditNoteMode] = useToggleState(false);

  return (
    <div className="flex flex-col gap-2 comment px-2">
      <NoteHeader
        note={note}
        meId={meId}
        editNoteMode={editNoteMode}
        toggleEditNoteMode={toggleEditNoteMode}
      />
      {editNoteMode ? (
        <CommentForm note={note} hideForm={toggleEditNoteMode} />
      ) : (
        <WSNoteContent txt={content} />
      )}
    </div>
  );
}

function NoteHeader(props) {
  let { note, meId, editNoteMode, toggleEditNoteMode } = props;

  let creator = note.createdBy;
  let createdAt = note.createdAt;

  return (
    <div className="flex gap-2 items-end text-xs">
      <Person id={creator} />
      <div className="ml-2 font-light semi-active">
        {moment(createdAt).fromNow()}
      </div>
      <div className="ml-2 anchor font-light semi-active">
        {creator === meId ? (
          <span
            onClick={toggleEditNoteMode}
            className="note-edit-link hovered-active"
          >
            {editNoteMode ? (
              'Cancel'
            ) : (
              <WS_EditIcon style={{ fontSize: '1rem' }} />
            )}
          </span>
        ) : null}
      </div>
    </div>
  );
}
