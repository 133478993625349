import { PAGES, NAV_KEYS, LIST_TYPES } from '../../../utils/consts';
import { PageContext } from '../../../utils/contexts';
import { NavLink } from 'react-router-dom';
import V2PageWithItemList from './_Layout/V2PageWithItemList';
import {
  RecentItemsList,
  ScheduledItemsList,
  PlannedItemsList,
  UnplannedItemsList,
} from '../../UtilComponents/TinyComponents';
import {
  getDefaultPriorityGroupNav,
  getPriorityGroupCounts,
} from '../../../utils';
import ChannelDetails from '../Components/ChannelDetails/ChannelDetails';
import { useEffect, useState } from 'react';

export default function ChannelHome(props) {
  let { match, channel, pageData } = props;

  let { l1Nav = null } = match.params;

  let counts = getPriorityGroupCounts(pageData[NAV_KEYS.OPEN.key]);

  const [derivedL1Nav, setL1Nav] = useState(l1Nav);
  useEffect(() => {
    if (!l1Nav) {
      l1Nav = getDefaultPriorityGroupNav(counts);
    }
    setL1Nav(l1Nav);
  }, [l1Nav]);

  if (!derivedL1Nav) {
    return null;
  }

  let pageDataKey =
    derivedL1Nav === NAV_KEYS.CLOSED.key
      ? NAV_KEYS.CLOSED.key
      : NAV_KEYS.OPEN.key;
  let groupedEntries = pageData[pageDataKey];

  let pageContext = {
    pageKey: PAGES.CHANNEL.key,
    pageId: channel.id,
    blockKey: { type: derivedL1Nav },
    groupedListEntries: groupedEntries,
    channel: channel,
  };

  let l1NavLinks = [
    NAV_KEYS.SCHEDULED.key,
    NAV_KEYS.PLANNED.key,
    NAV_KEYS.UNPLANNED.key,
  ];

  let ListAreaComponent;
  if (NAV_KEYS[pageDataKey].listType === LIST_TYPES.ACTIVITY) {
    ListAreaComponent = RecentItemsList;
  } else {
    ListAreaComponent = null;
    if (derivedL1Nav === NAV_KEYS.SCHEDULED.key) {
      ListAreaComponent = ScheduledItemsList;
    } else if (derivedL1Nav === NAV_KEYS.PLANNED.key) {
      ListAreaComponent = PlannedItemsList;
    } else {
      ListAreaComponent = UnplannedItemsList;
    }
  }

  return (
    <PageContext.Provider value={pageContext}>
      <div className="flex flex-col px-4 py-4 gap-2 h-full">
        <div className="flex flex-col gap-2">
          <div className="nav-strip">
            {l1NavLinks.map((eachNavKey) => {
              let additionalClass = '';
              if (derivedL1Nav === eachNavKey) {
                additionalClass = 'active';
              } else if (!counts[eachNavKey]) {
                additionalClass = 'inactive';
              }
              return (
                <NavLink
                  to={PAGES.CHANNEL.link + channel.id + '/' + eachNavKey}
                  className={`cursor-pointer hovered-active ${additionalClass}`}
                  key={eachNavKey}
                >
                  {NAV_KEYS[eachNavKey].text} {counts[eachNavKey]}
                </NavLink>
              );
            })}
            <NavLink
              to={PAGES.CHANNEL.link + channel.id + '/' + NAV_KEYS.CLOSED.key}
              className={`flex-auto text-right cursor-pointer hovered-active ${
                derivedL1Nav === NAV_KEYS.CLOSED.key ? 'active' : ''
              }`}
            >
              {NAV_KEYS.CLOSED.text}
            </NavLink>
          </div>
        </div>
        <V2PageWithItemList
          MainArea={() => <ChannelDetails />}
          ListArea={ListAreaComponent}
        />
      </div>
    </PageContext.Provider>
  );
}
