'use strict';

import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './jsx/App.jsx';
import { Provider } from 'react-redux';
import store from './store';
import { Router } from 'react-router-dom';
import history from './history';
import 'normalize.css';
// import(/* webpackPreload: true */ './fonts/tarrgetital.ttf');
import '@fontsource/mulish/200.css';
import '@fontsource/mulish/400.css';
import '@fontsource/mulish/500.css';
import '@fontsource/mulish/600.css';
import '@fontsource/mulish/700.css';
import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
// import '@fontsource/roboto-condensed/400.css';
// import '@fontsource/roboto-condensed/700.css';
import './styles/foundation_custom.scss';
import './styles/tailwind.css';
import './styles/app.scss';
import 'intro.js/introjs.css';
// require('./icons/favicon.ico');

import 'easymde/dist/easymde.min.css';
import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    ss: '%ds',
    m: '%dm',
    mm: '%dm',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    w: '%dw',
    ww: '%dw',
    M: '%dM',
    MM: '%dM',
    y: '%dy',
    yy: '%dy',
  },
});

// Export React so the dev tools can find it
if (window === window.top) {
  window.React = React;
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
