import React from 'react';
import { PageContext, WorkspaceContext } from '../../utils/contexts';
import { useContext } from 'react';
import { ACTIVITY_CONTEXTS, getActivityText } from '../../utils/activityUtils';
import { useState } from 'react';
import moment from 'moment';
import history from '../../history';
import { useEffect } from 'react';
import { archiveNotif } from '../../utils/api';
import { Person, WS_ToolTip } from './TinyComponents';
import { WS_ItemDoneIcon } from './Icons';

export function NotificationsList() {
  let { workDetailsMap } = useContext(WorkspaceContext);

  let { filteredNotifList } = useContext(PageContext);

  let urlNotifId = new URLSearchParams(location.search).get('notif');

  const [selectedNotif, setSelectedNotif] = useState(urlNotifId);

  useEffect(() => {
    setSelectedNotif(urlNotifId);
  }, [urlNotifId]);

  function toggleNotifItem(itemId, notifId) {
    return () => {
      if (selectedNotif && selectedNotif === notifId) {
        history.push('?');
      } else {
        history.push('?item=' + itemId + '&notif=' + notifId);
      }
    };
  }

  if (!filteredNotifList.length) {
    return <div className="text-center">No New Notifications</div>;
  }

  return (
    <div className="notifications flex flex-col gap-2">
      {filteredNotifList.map((eachFeed) => {
        let work = workDetailsMap[eachFeed.workId];
        if (!work) {
          // eslint-disable-next-line no-console
          console.log('work not found for notif', eachFeed.workId);
          return null;
        }
        return (
          <div
            key={eachFeed.id}
            className={`notif-container ${
              selectedNotif === eachFeed.id ? 'selected' : ''
            }`}
            onClick={toggleNotifItem(eachFeed.workId, eachFeed.id)}
          >
            <NotificationCard
              notification={eachFeed}
              isSelected={selectedNotif === eachFeed.id}
            />
          </div>
        );
      })}
    </div>
  );
}

function NotificationCard(props) {
  let { notification, isSelected } = props;

  let { workDetailsMap, meId } = useContext(WorkspaceContext);

  let workId = notification.workId;
  let work = workDetailsMap[workId];

  let notificationText = getActivityText(
    notification,
    meId,
    ACTIVITY_CONTEXTS.NOTIFICATION.key
  );

  function handleArchiveNotif(e) {
    if (!isSelected) {
      e.stopPropagation();
    }
    archiveNotif(notification.id);
  }

  return (
    <div className="flex flex-col gap-2 notif relative">
      <div className="header mr-10 ellipsis">{work.subject}</div>
      <div className="text-base mr-10">{notificationText}</div>
      <div className="footer flex justify-between gap-4">
        <div>
          By <Person id={notification.createdBy} decorators="inline" />
        </div>
        <div>{moment(notification.createdAt).fromNow()}</div>
      </div>
      <div
        className={`absolute notif-archive-div ${
          isSelected ? '' : 'invisible'
        }`}
      >
        <WS_ToolTip contents="Archive" placement="left">
          <button
            className="transparent cursor-pointer"
            onClick={handleArchiveNotif}
          >
            <WS_ItemDoneIcon />
          </button>
        </WS_ToolTip>
      </div>
    </div>
  );
}
