import history from '../../history';
import UserSession from '../../utils/classes/UserSession';
import ApiClient from '../../utils/classes/ApiClient';
import { useEffect, useState } from 'react';
import { SimpleButton } from '../UtilComponents/Buttons';
import config from 'config';
import { useToggleState } from '../../utils/hook';

export default function SysAdminArea(props) {
  let { userProfile } = props;

  let [successMsg, setSuccessMsg] = useState('');

  let [showStats, toogleStats] = useToggleState(false);

  if (
    !UserSession.getCurrentMemberId() ||
    userProfile.email !== config.sysAdminEmail
  ) {
    history.push('/');
    return null;
  }

  function handleTestInviteEmail() {
    ApiClient.post('sysadmin/testInviteEmail', {}).then(() => {
      setSuccessMsg('Test invite email sent!');
      setTimeout(() => {
        setSuccessMsg('');
      }, 3000);
    });
  }

  function handleTestOTPEmail() {
    ApiClient.post('sysadmin/testOTPEmail', {}).then(() => {
      setSuccessMsg('Test OTP email sent!');
      setTimeout(() => {
        setSuccessMsg('');
      }, 3000);
    });
  }

  function handleTestWelcomeEmail() {
    ApiClient.post('sysadmin/testWelcomeEmail', {}).then(() => {
      setSuccessMsg('Test welcome email sent!');
      setTimeout(() => {
        setSuccessMsg('');
      }, 3000);
    });
  }

  return (
    <div className="flex flex-col gap-8 py-8 px-32 h-full">
      <div className="text-center text-xl">SysAdmin Area</div>
      <div className="flex w-full gap-8 flex-auto">
        <div className="flex flex-col gap-8 w-4/5 border-r">
          <div>
            <div className="w-max">
              <SimpleButton onClick={toogleStats}>
                {showStats ? 'Hide' : 'Show'} Stats
              </SimpleButton>
            </div>
          </div>
          <div className="cell-auto scroll-please pb-32">
            {showStats ? <Stats /> : null}
          </div>
        </div>
        <div className="flex flex-col gap-4 w-1/5 items-center">
          {successMsg ? (
            <div className="cell success-card">{successMsg}</div>
          ) : null}
          <div className="w-max">
            <SimpleButton onClick={handleTestInviteEmail}>
              Test Invite Email
            </SimpleButton>
          </div>
          <div className="w-max">
            <SimpleButton onClick={handleTestOTPEmail}>
              Test OTP Email
            </SimpleButton>
          </div>
          <div className="w-max">
            <SimpleButton onClick={handleTestWelcomeEmail}>
              Test Welcome Email
            </SimpleButton>
          </div>
        </div>
      </div>
    </div>
  );
}

function Stats() {
  let [statsData, setStatsData] = useState(null);

  useEffect(() => {
    ApiClient.get('sysadmin/stats', true).then((apiStatsData) => {
      setStatsData(apiStatsData);
    });
  }, []);

  if (!statsData) {
    return <div>Loading stats...</div>;
  }

  let userData = statsData.userData;

  return (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-bold">Stats</div>
      <div className="flex flex-wrap gap-8">
        {userData.map((each, i) => {
          return <UserDataCard counter={i} data={each} key={each.u.id} />;
        })}
      </div>
    </div>
  );
}

function UserDataCard(props) {
  let { data, counter } = props;

  return (
    <div className="border rounded p-4">
      <div className="text-xl">
        {counter + 1}) {data.u.email}
      </div>
      <div className="ml-2">
        Signup Client: {data.u.signupClient}
        <br />
        Registered: {data.u.registered ? 'Yes' : 'No'}
        <br />
        Verified: {data.u.verified ? 'Yes' : 'No'}
        <br />
        Workspace: {data.ws.id}
        <br />
        Admin: {data.m.isAdmin ? 'Yes' : 'No'}
        <br />
        Activity Count: {data.memberActCount}
      </div>
    </div>
  );
}
