import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import UserSession from '../../utils/classes/UserSession';
import Workspace from './Workspace/Workspace';
import history from '../../history';
import { useEffect } from 'react';
import ApiClient from '../../utils/classes/ApiClient';
import {
  INIT_WORKSPACE_STATE,
  ERR_ALERT,
} from '../../redux/actions/AppActionTypes';
import store from '../../store';
import { usePrevious } from '../../utils/hook';

export default function MemberArea(props) {
  let { appState } = props;

  if (!UserSession.getCurrentMemberId()) {
    history.push('/user/limbo');
    return null;
  }

  const currentMemberId = appState.currentMemberId;
  const prevCurrentMemberId = usePrevious(currentMemberId);

  const workspaceState = appState.workspaces[appState.currentWorkspaceId];

  const outOfSync = workspaceState.outOfSync;

  useEffect(() => {
    if (currentMemberId === prevCurrentMemberId && !outOfSync) {
      // preventing hot reloading from refiring this effect
      // checkout App.jsx for detailed comments
      return;
    }

    ApiClient.get('self/plan', true).then(
      (workspaceData) => {
        store.dispatch({ type: INIT_WORKSPACE_STATE, workspaceData });
      },
      () => {
        store.dispatch({
          type: ERR_ALERT,
        });
      }
    );
  }, [currentMemberId, outOfSync]);

  const isLoading =
    UserSession.getCurrentMemberId() && !workspaceState.initFetched;

  if (isLoading) {
    return (
      <div className="cm mt-8">
        {outOfSync ? (
          <>Out of sync, reloading workspace...</>
        ) : (
          <>Loading workspace...</>
        )}
      </div>
    );
  }

  return <Workspace workspaceState={workspaceState} />;
}
