import { MentionsInput, Mention } from 'react-mentions';
import { useState, useContext } from 'react';
import { handleShiftEnter } from '../../../../utils';
import { WorkspaceContext } from '../../../../utils/contexts';
import store from '../../../../store';
import ApiClient from '../../../../utils/classes/ApiClient';
import { WS_SendIcon } from '../../../UtilComponents/Icons';

export default function CommentForm(props) {
  let { workId, note, hideForm } = props;

  if (!workId) {
    workId = note.workId;
  }

  let initialContent = '';
  let initialMentions = [];
  if (note) {
    initialContent = note.content;
    initialMentions = note.mentions;
  }

  let [noteText, setNoteText] = useState(initialContent);
  let [mentions, setMentions] = useState(initialMentions);

  let { membersMap, meId } = useContext(WorkspaceContext);

  let membersForMentions = [];
  Object.values(membersMap).map((each) => {
    if (each.active && each.id !== meId) {
      membersForMentions.push({ id: each.id, display: each.displayName });
    }
  });

  function handleMentionsInput(
    event,
    newValue,
    newPlainTextValue,
    mentionsArr
  ) {
    setNoteText(newValue);

    let mentions = [];
    mentionsArr.map((each) => {
      mentions.push(each.id);
    });
    setMentions(mentions);
  }

  function render(entry /*, search, highlightedDisplay, index, focused*/) {
    return <div>{entry.display}</div>;
  }

  function displayTransform(id, display) {
    return '@' + display;
  }

  function handleNoteSubmit() {
    if (note) {
      // edit mode
      ApiClient.post('work/editNote', {
        workId,
        noteId: note.id,
        content: noteText,
        mentions,
      }).then((res) => {
        store.dispatch({ activity: res.activity });
        hideForm();
      });
    } else {
      ApiClient.post('work/addNote', {
        content: noteText,
        workId,
        mentions,
      }).then((res) => {
        setNoteText('');
        store.dispatch({
          note: res.note,
          activity: res.activity,
        });
      });
    }
  }

  return (
    <div className="relative">
      <MentionsInput
        value={noteText}
        onChange={handleMentionsInput}
        className="react-mention-main"
        onKeyPress={handleShiftEnter(handleNoteSubmit)}
        placeholder="Use @ to tag members"
        autoFocus
      >
        <Mention
          trigger="@"
          data={membersForMentions}
          renderSuggestion={render}
          displayTransform={displayTransform}
          className="note-mention"
          appendSpaceOnAdd={true}
        />
      </MentionsInput>
      <div
        className="ml-2 cursor-pointer absolute ws-send-icon-container mt-0.5"
        onClick={handleNoteSubmit}
      >
        <div className="ws-send-icon">
          <WS_SendIcon style={{ fontSize: '1.3rem', marginTop: -2 }} />
        </div>
      </div>
    </div>
  );
}

// function ShiftEnterSymbols() {
//   return           (
//     <div>
//       &#8679; &#9166;
//     </div>
//   );
// }
