import { MEMBER_ACTIVITY_TYPE } from '../../utils/activityUtils';
import { addMemberToWorkspace, CWP, saveSeenActivity } from './helpers';

export function handleMemberActivity(state, action) {
  switch (action.activity.type) {
    case MEMBER_ACTIVITY_TYPE.NEW_MEMBER.key: {
      let { member, activity } = action;

      state = addMemberToWorkspace(state, member);

      state = saveSeenActivity(state, activity);

      return state;
    }

    case MEMBER_ACTIVITY_TYPE.MEMBER_DEACTIVATE.key: {
      let { activity } = action;
      let { newVal: memberId } = activity;

      state = state.setIn(
        CWP(state).concat(['membersMap', memberId, 'active']),
        false
      );

      state = saveSeenActivity(state, activity);

      return state;
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn(
        'unknown action type',
        action.activity.type,
        '(category:',
        action.activity.category,
        ')'
      );
      return state;
    }
  }
}
