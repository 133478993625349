import without from 'lodash/without';
import { CREATE_ALERT, DISMISS_ALERT } from '../actions/AppActionTypes';

export default function alerts(state = [], action) {
  switch (action.type) {
    case CREATE_ALERT:
      return [...state, { ...action.alert }];
    case DISMISS_ALERT:
      return without(state, action.alert);
    default:
      return state;
  }
}
