import { takeEvery, put } from 'redux-saga/effects';
import UserSession from '../../utils/classes/UserSession';
import { CREATE_ALERT } from '../actions/AppActionTypes';
import uniqueId from 'lodash/uniqueId';
import isArray from 'lodash/isArray';

// NOTE
// For errors to display as alert, an action having "error" object needs to be dispatched
// which then gets caught here
// For showing api error as alert send message in "__message" key
// For all other types of errors, it should catch them automatically and show them

export default function* watchErrors() {
  yield takeEvery('*', errorWatcher);
}

function* errorWatcher(action) {
  if (action.error) {
    let errorResponse = action.error.response;

    let errorStatus = errorResponse.status;
    let message = 'Error!';
    let devError = '';

    switch (errorStatus) {
      case 498: {
        // invalid token
        UserSession.reset();
        window.location.reload();
        return;
      }

      case 500: {
        devError = 'Server Error - 500';
        break;
      }

      case 404: {
        devError = 'Invalid API path - 404';
        break;
      }

      case 400: {
        // for 400 errors, an alert is displayed only when data has __message field
        // for special handling one must handle error and call ERR_ALERT action explicitly
        message = errorResponse.data.__message;
        break;
      }

      case 401: {
        // 401, no or invalid authorization
        message = errorResponse.data.message;
        break;
      }

      default: {
        message = getErrorMsgFromServerResponse(errorResponse);
      }
    }

    // eslint-disable-next-line no-console
    console.error(devError);

    if (message) {
      yield put({
        type: CREATE_ALERT,
        alert: {
          id: uniqueId(),
          message,
        },
      });
    }
  }
}

function getErrorMsgFromServerResponse(err) {
  if (err.message) {
    return err.message;
  }

  let errMessages = [];
  if (err.data) {
    try {
      for (let prop in err.data) {
        let msg = err.data[prop];
        if (isArray(msg)) {
          msg = msg.join(' ');
        }

        if (prop === '__message') {
          errMessages.push(msg);
        } else {
          errMessages.push(`${prop}: ${msg}`);
        }
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(ex);
    }
  }

  let errMessage = '';
  if (errMessages.length === 1) {
    errMessage = errMessages[0];
  } else {
    errMessage = errMessages.join('\n\n');
  }

  if (!errMessage) {
    if (err.responseText) {
      // eslint-disable-next-line no-console
      console.log('Error: ' + err.responseText);
    } else {
      // eslint-disable-next-line no-console
      console.log('error message could not be derived!');
    }
  }

  return errMessage;
}
