import React from 'react';
import ApiClient from '../../utils/classes/ApiClient';
import { Formik } from 'formik';
import WSTextField from '../UtilComponents/WSTextField';
import history from '../../history';
import UserSession from '../../utils/classes/UserSession';
import { useState } from 'react';
import { useTargetValueState } from '../../utils/hook';
import { Link } from 'react-router-dom';
import { WSGuestButton } from '../UtilComponents/Buttons';

const STAGE = {
  FORGOT_PASS: 'FORGOT_PASS',
  RESET_PASS: 'RESET_PASS',
  RESET_DONE: 'RESET_DONE',
};

export default function ForgotPass() {
  if (UserSession.getApiKey()) {
    history.push('/');
    return null;
  }

  let { value: email, setTargetValue: setEmail } = useTargetValueState('');
  let [stage, setStage] = useState(STAGE.FORGOT_PASS);

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center items-center gap-24 pt-20">
        {stage === STAGE.RESET_DONE ? (
          <ResetPassSuccess />
        ) : (
          <>
            <h2 className="text-2xl active">
              {stage === STAGE.FORGOT_PASS
                ? 'Forgot Password'
                : 'Reset Password'}
            </h2>
            {stage === STAGE.FORGOT_PASS ? (
              <ForgotPassForm
                email={email}
                setEmail={setEmail}
                setStage={setStage}
              />
            ) : stage === STAGE.RESET_PASS ? (
              <ResetPassForm email={email} setStage={setStage} />
            ) : null}
            {stage === STAGE.FORGOT_PASS ? (
              <div className="semi-active">
                <Link to="/guest/login" className="anchor">
                  Back to Login
                </Link>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

function ResetPassSuccess() {
  return (
    <div className="flex flex-col glass-r2 p-16 gap-8 items-center mt-16">
      <div className="text-2xl text-green-400 font-bold">
        Password reset successful!
      </div>
      <div className="active text-base">
        Please{' '}
        <Link to="/guest/login" className="anchor">
          Login
        </Link>{' '}
        with the new password to continue
      </div>
    </div>
  );
}

function ForgotPassForm(props) {
  let { email, setEmail, setStage } = props;

  return (
    <Formik
      initialValues={{
        email,
        // eslint-diasble-next-line no-unused-vars
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        ApiClient.post('guest/forgotPass', {
          email: email,
        }).then(
          () => {
            setSubmitting(false);
            setStage(STAGE.RESET_PASS);
          },
          (error) => {
            if (error.response.status == 400) {
              setErrors(error.response.data);
            }
            setSubmitting(false);
          }
        );
      }}
    >
      {({ errors, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col items-center gap-4">
            <div className="w-80">
              <WSTextField
                name="email"
                placeholder="Enter your email"
                required
                autoFocus
                autoComplete="off"
                onChange={setEmail}
                onBlur={handleBlur}
                value={email}
              />
              {errors.email && <div className="formError">{errors.email}</div>}
            </div>
            <div>
              <WSGuestButton type="submit" size="sm">
                Submit
              </WSGuestButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

function ResetPassForm(props) {
  let { email, setStage } = props;

  return (
    <Formik
      initialValues={{
        email,
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        ApiClient.post('guest/setPass', {
          email: values.email,
          otp: values.otp,
          password: values.password,
        }).then(
          () => {
            setStage(STAGE.RESET_DONE);
            setSubmitting(false);
          },
          (error) => {
            if (error.response.status == 400) {
              setErrors(error.response.data);
            }
            setSubmitting(false);
          }
        );
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4 items-center">
            <div className="text-base active">
              Please enter the OTP received at your email
            </div>
            <div className="w-80">
              <WSTextField
                name="otp"
                placeholder="OTP"
                type="text"
                required
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.otp}
                autoFocus
              />
            </div>
            {errors.otp && <div className="formError">{errors.otp}</div>}
            <div className="w-80">
              <WSTextField
                name="password"
                placeholder="New Password"
                type="password"
                required
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </div>
            {errors.password && (
              <div className="formError">{errors.password}</div>
            )}
            <div>
              <WSGuestButton type="submit" size="sm">
                Submit
              </WSGuestButton>
            </div>
            {errors.form && <div className="formError">{errors.form}</div>}
          </div>
        </form>
      )}
    </Formik>
  );
}
