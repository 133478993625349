import React, { useContext, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { WorkspaceContext } from '../../../../utils/contexts';

export default function ChannelAutoSuggest(props) {
  let { work, handleAddToChannel } = props;
  const { allChannelsMap, orderedChannels } = useContext(WorkspaceContext);

  let myChannels = orderedChannels.map(
    (eachChannelId) => allChannelsMap[eachChannelId]
  );

  let existingChannelIds = work.channelIds;
  const existingChannels = existingChannelIds.map(
    (eachChannelId) => allChannelsMap[eachChannelId]
  );

  const defaultOptions = myChannels.filter(
    (each) => existingChannelIds.indexOf(each.id) === -1
  );

  const [availableOptions, setAvailableOptions] = useState(defaultOptions);
  const [showDropdownOptions, setShowDropdownOptions] = useState(false);

  function addWorkToChannel(channelId) {
    return () => {
      if (existingChannelIds.indexOf(channelId) !== -1) {
        // work already exists in channel, nothing to do
        return;
      }
      handleAddToChannel(channelId).then(() => {
        setInputVal('');
        updateAvailableOptions('');
        handleBlur();
      });

      // anchorEl.querySelector('#customized-hook-demo').blur();
    };
  }

  const [inputVal, setInputVal] = useState('');

  function handleInputChange(e) {
    let newInputVal = e.target.value;
    setInputVal(newInputVal);
    updateAvailableOptions(newInputVal);
  }

  function updateAvailableOptions(newInputVal) {
    let freshOptions = [];
    if (newInputVal) {
      freshOptions = myChannels.filter((each) => {
        return each.name.toLowerCase().includes(newInputVal.toLowerCase());
      });
    } else {
      freshOptions = defaultOptions;
    }
    setAvailableOptions(freshOptions);
  }

  const {
    getRootProps,
    getInputProps,
    // getTagProps,
    getListboxProps,
    getOptionProps,
    // groupedOptions,
    // value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    value: existingChannels,
    multiple: true,
    open: true,
    // blurOnSelect: true,
    options: myChannels,
    inputValue: inputVal,
    getOptionLabel: (option) => option.name,
    getOptionSelected: (option, value) => {
      return option.id === value.id;
    },
    // disablePortal: true,
    filterSelectedOptions: true,
    /** eslint-disable next-line no-unused-vars */
    onChange: (event, value) => {
      let additionalValue = null;

      for (let each of value) {
        if (existingChannelIds.indexOf(each.id) === -1) {
          // newly added channel
          additionalValue = each;
          break;
        }
      }

      addWorkToChannel(additionalValue.id)();
    },
  });

  function handleFocus() {
    setShowDropdownOptions(true);
  }

  function handleBlur() {
    setShowDropdownOptions(false);
  }

  return (
    <div className="flex flex-wrap items-center">
      <div {...getRootProps()}>
        <div
          ref={setAnchorEl}
          className={`channel-search-input-container ${
            focused ? 'focused mt-1' : 'mt-1'
          }`}
        >
          <input
            {...getInputProps()}
            placeholder="Apply Tags"
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={handleFocus}
            type="text"
            className="simple small"
            id="channel-search-input"
          />
        </div>
        {showDropdownOptions ? (
          <ul
            {...getListboxProps()}
            tabIndex="0"
            className="channel-search-listbox"
          >
            {inputVal.length === 0 ? (
              <li className="header">Start typing to filter tags</li>
            ) : null}
            {availableOptions.map((option, index) => {
              if (existingChannelIds.indexOf(option.id) !== -1) {
                return null;
              }

              return (
                <li
                  {...getOptionProps({ option, index })}
                  onClick={addWorkToChannel(option.id)}
                >
                  <span>{option.name}</span>
                </li>
              );
            })}
            {!availableOptions.length && inputVal.length > 0 ? (
              <li className="text-xs">No matches found</li>
            ) : null}
          </ul>
        ) : null}
      </div>
    </div>
  );
}
