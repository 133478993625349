import { Person } from '../../../UtilComponents/TinyComponents';
import {
  handleEditOwner,
  handleEditAssignee,
  editWorkMilestone,
} from '../../../../utils/api';
import MemberSelectorMenu from '../Common/MemberSelectorMenu';
import { useContext } from 'react';
import { PageContext, WorkspaceContext } from '../../../../utils/contexts';
import MilestoneSelectorMenu from '../Common/MilestoneSelectorMenu';
import StatusInfo from './StatusInfo';
import { getMilestoneName } from '../../../../utils';

export default function L1Details(props) {
  let { work } = props;
  return (
    <div className="grid-x grid-margin-x grid-margin-y justify-between items-stretch semi-active l1-details-container">
      <div className="cell small-6 large-3 hl-surface flex justify-center px-1 pt-4 pb-2">
        <AssignmentInfo work={work} />
      </div>
      <div className="cell small-6 large-3 hl-surface flex justify-center px-1 pt-4 pb-2">
        <StatusInfo work={work} />
      </div>
      <div className="cell small-6 large-3 hl-surface flex justify-center px-1 pt-4 pb-2">
        <MilestoneInfo work={work} />
      </div>
      <div className="cell small-6 large-3 hl-surface flex justify-center px-1 pt-4 pb-2">
        <OwnerInfo work={work} />
      </div>
    </div>
  );
}

function AssignmentInfo(props) {
  let { work } = props;

  let {
    isNewItem,
    updateSuggestionsForNewAssignee,
    suggestedAssigneeIds,
  } = useContext(PageContext);

  let { setItemSuccessSnackBarParams, activeItemCurrentPosition } = useContext(
    WorkspaceContext
  );

  function handleReassign(newMemberId) {
    if (newMemberId !== work.assignedTo) {
      handleEditAssignee(work.id, activeItemCurrentPosition, newMemberId).then(
        () => {
          if (isNewItem) {
            updateSuggestionsForNewAssignee(newMemberId);
          }
          setItemSuccessSnackBarParams({
            workId: work.id,
            message: 'Item updated successfully!',
          });
        }
      );
    }
  }

  function suggestionHandler(newMemberId) {
    return () => {
      handleReassign(newMemberId);
    };
  }

  let topSuggestion =
    suggestedAssigneeIds && suggestedAssigneeIds.length
      ? suggestedAssigneeIds[0]
      : null;
  let showSuggestion =
    isNewItem && topSuggestion && work.assignedTo !== topSuggestion;

  let currentAssignee = work.assignedTo;

  return (
    <div className="flex flex-col gap-2 items-center w-full">
      <div className="field-header small">Assignee</div>
      <div disabled={work.archived} className="hl-text w-full text-center">
        {work.archived ? (
          <div className="p-2 ellipsis">
            <Person id={currentAssignee} />
          </div>
        ) : (
          <MemberSelectorMenu
            handleUpdate={handleReassign}
            selected={currentAssignee}
            headerText="Assignee"
            buttonDecorators="centered"
          />
        )}
      </div>
      {showSuggestion ? (
        <div
          className="suggestion-box flex"
          onClick={suggestionHandler(topSuggestion)}
        >
          <Person id={topSuggestion} />
          <div>?</div>
        </div>
      ) : null}
    </div>
  );
}

function MilestoneInfo(props) {
  let { work } = props;

  let {
    setItemSuccessSnackBarParams,
    activeItemCurrentPosition,
    allMilestonesMap,
  } = useContext(WorkspaceContext);

  let {
    isNewItem,
    suggestedMilestoneIds,
    updateSuggestionsForNewMilestone,
  } = useContext(PageContext);

  let topSuggestion =
    suggestedMilestoneIds && suggestedMilestoneIds.length
      ? suggestedMilestoneIds[0]
      : null;
  let showSuggestion =
    isNewItem && topSuggestion && work.milestoneId !== topSuggestion;

  function handleMilestoneUpdate(milestoneId) {
    let isPrivate = work.isPrivate;
    if (milestoneId) {
      if (allMilestonesMap[milestoneId].memberIds.length > 1) {
        isPrivate = false;
      }
    }
    editWorkMilestone(
      work.id,
      milestoneId,
      isPrivate,
      activeItemCurrentPosition
    ).then(() => {
      if (isNewItem) {
        updateSuggestionsForNewMilestone(milestoneId);
      }
      setItemSuccessSnackBarParams({
        workId: work.id,
        message: 'Item updated successfully!',
      });
    });
  }

  function suggestionHandler(newMilestoneId) {
    return () => {
      handleMilestoneUpdate(newMilestoneId);
    };
  }

  let milestone = null;
  if (work.milestoneId) {
    milestone = allMilestonesMap[work.milestoneId];
  }

  let milestoneName = getMilestoneName(milestone);

  return (
    <div className="flex flex-col gap-2 items-center w-full">
      <div className="field-header small">Milestone</div>
      <div disabled={work.archived} className="hl-text w-full text-center">
        {work.archived ? (
          <div className={`p-2 ${work.milestoneId ? '' : 'unset'}`}>
            {milestoneName}
          </div>
        ) : (
          <MilestoneSelectorMenu
            handleUpdate={handleMilestoneUpdate}
            selected={work.milestoneId}
            buttonDecorators="centered"
          />
        )}
      </div>
      {showSuggestion ? (
        <div
          className="suggestion-box flex"
          onClick={suggestionHandler(topSuggestion)}
        >
          <div>{allMilestonesMap[topSuggestion].name}</div>
          <div>?</div>
        </div>
      ) : null}
    </div>
  );
}

function OwnerInfo(props) {
  let { work } = props;

  let { setItemSuccessSnackBarParams, activeItemCurrentPosition } = useContext(
    WorkspaceContext
  );

  let {
    isNewItem,
    suggestedOwnerIds,
    updateSuggestionsForNewOwner,
  } = useContext(PageContext);

  function handleOwnerUpdate(newMemberId) {
    handleEditOwner(work.id, activeItemCurrentPosition, newMemberId).then(
      () => {
        if (isNewItem) {
          updateSuggestionsForNewOwner(newMemberId);
        }
        setItemSuccessSnackBarParams({
          workId: work.id,
          message: 'Item updated successfully!',
        });
      }
    );
  }

  function suggestionHandler(newMemberId) {
    return () => {
      handleOwnerUpdate(newMemberId);
    };
  }

  let topSuggestion =
    suggestedOwnerIds && suggestedOwnerIds.length ? suggestedOwnerIds[0] : null;
  let showSuggestion =
    isNewItem && topSuggestion && work.owner !== topSuggestion;

  return (
    <div className="flex flex-col gap-2 items-center w-full">
      <div className="field-header small">Owner</div>
      <div disabled={work.archived} className="hl-text w-full text-center">
        {work.archived ? (
          <div className="p-2">
            <Person id={work.owner} />
          </div>
        ) : (
          <div title="Owner">
            {/* <SearchableMemberSelectorMenu
              handleUpdate={handleOwnerUpdate}
              selected={work.owner}
              headerText="Owner"
            /> */}
            <MemberSelectorMenu
              handleUpdate={handleOwnerUpdate}
              selected={work.owner}
              headerText="Owner"
              buttonDecorators="centered"
            />
          </div>
        )}
      </div>
      {showSuggestion ? (
        <div
          className="suggestion-box flex"
          onClick={suggestionHandler(topSuggestion)}
        >
          <Person id={topSuggestion} />
          <div>?</div>
        </div>
      ) : null}
    </div>
  );
}
