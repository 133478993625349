import { Formik } from 'formik';
import React from 'react';
import ApiClient from '../../../../utils/classes/ApiClient';
import { SaveCancel } from '../../../UtilComponents/TinyComponents';
import store from '../../../../store';
import WSTextField from '../../../UtilComponents/WSTextField';
import { WorkspaceContext } from '../../../../utils/contexts';
import { useContext } from 'react';

export default function EditSubjectForm(props) {
  let { work, hideForm } = props;

  let { setItemSuccessSnackBarParams } = useContext(WorkspaceContext);

  return (
    <Formik
      initialValues={{
        subject: work.subject,
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        if (values.subject === work.subject) {
          setSubmitting(false);
          hideForm();
          return;
        }
        ApiClient.post('work/editSubject', {
          subject: values.subject,
          workId: work.id,
        }).then(
          (res) => {
            store.dispatch({
              activity: res.activity,
            });
            setSubmitting(false);
            hideForm();
            setItemSuccessSnackBarParams({
              workId: work.id,
              message: 'Item updated successfully!',
            });
          },
          (error) => {
            if (error.response.status == 400) {
              setErrors(error.response.data);
            }
            setSubmitting(false);
          }
        );
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className="w-full">
          <div className="flex flex-wrap align-spaced items-center gap-4">
            <div className="flex-auto">
              <WSTextField
                autoFocus
                autoComplete="off"
                name="subject"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.subject}
              />
            </div>
            <SaveCancel handleCancel={hideForm} isSubmitting={isSubmitting} />
            {errors.subject && (
              <div className="formError w-full">{errors.subject}</div>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
}
