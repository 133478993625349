import EventIcon from '@material-ui/icons/Event';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ShortTextIcon from '@material-ui/icons/ShortText';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { HiCalendar } from 'react-icons/hi';
import { IoChatboxOutline } from 'react-icons/io5';
import { GiPin as Pin } from 'react-icons/gi';
import { MdWorkOutline } from 'react-icons/md';
import { MdCheck } from 'react-icons/md';
import { BsInfoCircle } from 'react-icons/bs';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiWindowOpen } from 'react-icons/bi';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { MdCheckBox } from 'react-icons/md';
import { BiDetail } from 'react-icons/bi';
import { BiArrowBack } from 'react-icons/bi';
import { VscLock } from 'react-icons/vsc';
import { BiHash } from 'react-icons/bi';
import { GiCheckMark } from 'react-icons/gi';
import { RiUserFollowFill } from 'react-icons/ri';
import { WORK_ACTIVITY_TYPE } from '../../utils/activityUtils';
import { RiSendPlaneFill } from 'react-icons/ri';
import { IoAlertCircle as Priority } from 'react-icons/io5';
import { VscChromeMinimize } from 'react-icons/vsc';
import GlassAddIcon from '../../icons/add.svg';
import { BiTargetLock } from 'react-icons/bi';
import { RiUserStarFill } from 'react-icons/ri';
import { IoFlag } from 'react-icons/io5';
import { IoMdNotificationsOutline } from 'react-icons/io';

function getFinalProps(props, defaultFontSize) {
  let defaultStyle = {
    fontSize: defaultFontSize ? defaultFontSize : '1rem',
    verticalAlign: 'text-bottom',
    display: 'inline',
  };

  let finalStyle = { ...defaultStyle, ...props.style };

  let finalProps = { ...props, style: finalStyle };

  return finalProps;
}

export function WS_NotifIcon(props) {
  return <IoMdNotificationsOutline {...getFinalProps(props)} />;
}

export function WS_OwnerIcon(props) {
  return <RiUserStarFill {...getFinalProps(props)} />;
}

export function WS_StatusIcon(props) {
  return <IoFlag {...getFinalProps(props)} />;
}

export function WS_Minimize(props) {
  return <VscChromeMinimize {...getFinalProps(props)} />;
}

export function WS_EditIcon(props) {
  return <AiOutlineEdit {...getFinalProps(props)} />;
}

export function WS_InfoIcon(props) {
  return <BsInfoCircle {...getFinalProps(props)} />;
}

export function WS_PinIcon(props) {
  return <Pin {...getFinalProps(props)} />;
}

export function WS_AssigneeIcon(props) {
  return <RiUserFollowFill {...getFinalProps(props)} />;
}

export function WS_SubjectIcon(props) {
  return <ShortTextIcon {...getFinalProps(props)} />;
}

export function WS_DescriptionIcon(props) {
  return <BiDetail {...getFinalProps(props)} />;
}

export function WS_CommentsIcon(props) {
  return <IoChatboxOutline {...getFinalProps(props)} />;
}

export function WS_PriorityIcon(props) {
  return <Priority {...getFinalProps(props)} />;
}

export function WS_CheckBoxChecked(props) {
  return <MdCheckBox {...getFinalProps(props, '1.2rem')} />;
}

export function WS_CheckBoxUnchecked(props) {
  return <MdCheckBoxOutlineBlank {...getFinalProps(props, '1.2rem')} />;
}

export function WS_ChannelIcon(props) {
  return <BiHash {...getFinalProps(props)} />;
}

export function WS_MilestoneIcon(props) {
  return <BiTargetLock {...getFinalProps(props)} />;
}

export function WS_AddItemIcon(props) {
  let { size } = props;
  if (!size) {
    size = 20;
  }
  return <img src={GlassAddIcon} style={{ width: size, height: size }} />;
}

export function WS_AddIcon(props) {
  return <AddIcon {...getFinalProps(props)} />;
}

export function WS_DropdownIcon(props) {
  return <ArrowDropDownIcon {...getFinalProps(props, '1.3rem')} />;
}

export function WS_DropdownIconAlt(props) {
  return <ArrowDropDownIcon {...getFinalProps(props)} />;
}

export function WS_HelpIcon(props) {
  return <HelpOutlineIcon {...getFinalProps(props)} />;
}

export function WS_SearchIcon(props) {
  return <SearchIcon {...getFinalProps(props)} />;
}

export function WS_PrivateChannelIcon(props) {
  return <VscLock {...getFinalProps(props)} />;
}

export function WS_EventIcon(props) {
  return <EventIcon {...getFinalProps(props)} />;
}

export function WS_Tick(props) {
  return <MdCheck {...getFinalProps(props)} />;
}

export function WS_GoBackIcon(props) {
  return <BiArrowBack {...getFinalProps(props)} />;
}

export function WS_ItemDoneIcon(props) {
  return <GiCheckMark {...getFinalProps(props)} />;
}

export function MyWorkIcon(props) {
  return <MdWorkOutline {...getFinalProps(props)} />;
}

export function WS_CloseIcon(props) {
  return <CloseIcon {...getFinalProps(props)} />;
}

export function WS_SendIcon(props) {
  return <RiSendPlaneFill {...getFinalProps(props)} />;
}

export function WS_DueDateIcon(props) {
  return <HiCalendar {...getFinalProps(props)} />;
}

export function WS_ReopenIcon(props) {
  return <BiWindowOpen {...getFinalProps(props)} />;
}

export function getActIcon(activityType) {
  let icon = <WS_HelpIcon />;

  switch (activityType) {
    case WORK_ACTIVITY_TYPE.NEW_WORK.key: {
      icon = <WS_AddItemIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.ADD_TO_CHANNEL.key: {
      icon = <WS_ChannelIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.REMOVE_FROM_CHANNEL.key: {
      icon = <WS_ChannelIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.ASSIGNEE_EDIT.key: {
      icon = <WS_AssigneeIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.CLOSE.key: {
      icon = <WS_ItemDoneIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.REOPEN.key: {
      icon = <WS_ReopenIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.SUBJECT_EDIT.key: {
      icon = <WS_SubjectIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.MILESTONE_EDIT.key: {
      icon = <WS_MilestoneIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.STATUS_EDIT.key: {
      icon = <WS_StatusIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.DESCRIPTION_EDIT.key: {
      icon = <WS_DescriptionIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.DESCRIPTION_ADD.key: {
      icon = <WS_DescriptionIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.OWNER_EDIT.key: {
      icon = <WS_OwnerIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.SCHEDULE_EDIT.key: {
      icon = <WS_DueDateIcon />;
      break;
    }

    case WORK_ACTIVITY_TYPE.PRIORITY_EDIT.key: {
      icon = <WS_PriorityIcon />;
      break;
    }
  }

  return icon;
}
