import { PAGES } from '../../../utils/consts';
import { Route, Switch, Redirect } from 'react-router-dom';
import WorkspaceAdmin from '../Pages/WorkspaceAdmin';
import ItemPage from '../Pages/ItemPage';
import WIP from '../Pages/WIP';
import Exp from '../Pages/Exp';
import MyWork from '../Pages/MyWork';
import SearchPlus from '../Pages/SearchPlus';
import Notifications from '../Pages/Notifications';

export default function DefaultRoutesResolver() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={PAGES.MY_WORK.link} />}
      />
      <Route
        path={`${PAGES.MY_WORK.link}/:l1Nav?/:l2Nav?`}
        render={(props) => <MyWork {...props} />}
      />
      <Route
        path={PAGES.NOTIF.link + '/:l1Nav?'}
        render={(props) => <Notifications {...props} />}
      />
      <Route
        path={PAGES.SEARCH_PLUS.link + '/:l1Nav?'}
        render={(props) => <SearchPlus {...props} />}
      />
      <Route exact path="/wip" render={(props) => <WIP {...props} />} />
      <Route
        path={PAGES.ITEM.link + '/:itemId'}
        render={(props) => <ItemPage {...props} />}
      />
      <Route
        path="/workspace/settings/:l1Nav?/:l2Nav?"
        render={(props) => <WorkspaceAdmin {...props} />}
      />
      <Route
        path={PAGES.EXP.link}
        exact
        render={(props) => <Exp {...props} />}
      />
      <Route
        path="*"
        render={() => (
          <div className="grid-y grid-frame cm text-center">
            Invalid address!
          </div>
        )}
      />
    </Switch>
  );
}
