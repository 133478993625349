export function TransparentButton(props) {
  let { onClick, children, decorators = '' } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`transparent active ${decorators}`}
    >
      {children}
    </button>
  );
}

export function SimpleButton(props) {
  let { onClick, children } = props;

  return (
    <button type="button" onClick={onClick} className="simple">
      {children}
    </button>
  );
}

export function FormSubmitButton(props) {
  let { children } = props;

  return (
    <button type="submit" className="form-submit-button">
      {children}
    </button>
  );
}

export function WSGuestButton(props) {
  let { children, className = '', size, onClick, type = 'button' } = props;

  const sizes = {
    sm: 'py-2 px-6',
    default: 'py-2 px-6',
    lg: 'py-4 px-12',
    xl: 'py-5 px-16 text-lg',
  };

  return (
    <button
      type={type}
      onClick={onClick}
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        rounded
        active
        guest-button
        text-base
    `}
    >
      {children}
    </button>
  );
}
