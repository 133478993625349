import Login from './Login';
import Signup from './Signup';
import ForgotPass from './ForgotPass';
import { Route, Switch } from 'react-router-dom';
import UserSession from '../../utils/classes/UserSession';
import history from '../../history';
import { useEffect } from 'react';
import Header from './Header';

export default function GuestArea(props) {
  let { appState } = props;

  if (UserSession.getApiKey()) {
    if (UserSession.getCurrentMemberId()) {
      history.push('/');
      return null;
    } else {
      history.push('/user/limbo');
      return null;
    }
  }

  const verifyUserId = appState.verifyUserId;
  useEffect(() => {
    if (verifyUserId) {
      history.push('/verify');
    }
  }, [verifyUserId]);

  return (
    <>
      <Header />
      <Switch>
        <Route path="/guest/login" render={(props) => <Login {...props} />} />
        <Route path="/guest/signup" render={(props) => <Signup {...props} />} />
        <Route
          path="/guest/forgotpass"
          render={(props) => <ForgotPass {...props} />}
        />
        <Route
          path="*"
          render={() => (
            <div className="cm w-full h-screen text-center">
              Invalid address!
            </div>
          )}
        />
      </Switch>
    </>
  );
}
