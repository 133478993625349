import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotFound } from '../../UtilComponents/TinyComponents';
import { WorkspaceContext } from '../../../utils/contexts';
import { useContext } from 'react';
import { PAGES, PRIMARY_DATA_KEYS } from '../../../utils/consts';
import ChannelHome from '../Pages/ChannelHome';

export default function ChannelRoutesResolver(props) {
  let { match } = props;

  let { allChannelsMap, setNewItemGoBackContext, pageData } = useContext(
    WorkspaceContext
  );

  let channelId = match.params.channelId;

  let channel = allChannelsMap[channelId];

  useEffect(() => {
    if (channel) {
      setNewItemGoBackContext({
        key: PAGES.CHANNEL.key,
        path: '/channel/' + channel.id,
        title: channel.name,
        pageId: channel.id,
      });
    }
  }, [channelId]);

  if (!channelId || !channel) {
    return <NotFound />;
  }

  return (
    <Switch>
      <Route
        path="/channel/:channelId/:l1Nav?"
        render={(props) => (
          <ChannelHome
            {...props}
            channel={channel}
            pageData={pageData[PRIMARY_DATA_KEYS.CHANNEL][channelId]}
            key={'channel_home_' + channelId}
          />
        )}
      />
    </Switch>
  );
}
