import { useContext } from 'react';
import { PageContext } from '../../../../utils/contexts';
import { useToggleState } from '../../../../utils/hook';
import ChannelMembersSection from './ChannelMembersSection';
import ApiClient from '../../../../utils/classes/ApiClient';
import store from '../../../../store';
import WSDescEditor from '../../../UtilComponents/WSDescEditor';
import {
  Person,
  WSDescriptionContent,
} from '../../../UtilComponents/TinyComponents';
import moment from 'moment';
import { WS_ChannelIcon } from '../../../UtilComponents/Icons';
import ChannelRenameForm from './ChannelRenameForm';

export default function ChannelDetails() {
  return (
    <div className="flex flex-col gap-16 channel-details scroll-please">
      <Header />
      <div className="flex-auto flex flex-col gap-8">
        <ChannelMembersSection />
      </div>
      <CreationInfo />
    </div>
  );
}

function Header() {
  let { channel } = useContext(PageContext);

  const [renameMode, toggleRenameMode] = useToggleState(false);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-start justify-between">
        {renameMode ? (
          <ChannelRenameForm channel={channel} hideForm={toggleRenameMode} />
        ) : (
          <div className="flex items-center">
            <WS_ChannelIcon
              style={{
                fontSize: '1.3rem',
                verticalAlign: 'middle',
              }}
            />
            <div
              className="text-lg semi-active cursor-pointer hovered-active"
              onClick={toggleRenameMode}
            >
              {channel.name}
            </div>
          </div>
        )}
      </div>
      <DescriptionSection />
    </div>
  );
}

function DescriptionSection() {
  let { channel } = useContext(PageContext);

  const [descriptionEditMode, toggleDescriptionEditMode] = useToggleState(
    false
  );

  function handleDescriptionSave(newDesc) {
    ApiClient.post('channel/editDescription', {
      description: newDesc,
      channelId: channel.id,
    }).then((res) => {
      store.dispatch({
        activity: res.activity,
      });
      toggleDescriptionEditMode();
    });
  }

  return (
    <div className="flex flex-col gap-4 relative">
      {descriptionEditMode ? (
        <WSDescEditor
          handleSave={handleDescriptionSave}
          handleCancel={toggleDescriptionEditMode}
          initialData={channel.description}
        />
      ) : !channel.description ? (
        <div
          className="cursor-pointer unset py-2 w-32 opacity-60"
          onClick={toggleDescriptionEditMode}
        >
          Add description
        </div>
      ) : (
        <WSDescriptionContent
          description={channel.description}
          handleEditClick={toggleDescriptionEditMode}
        />
      )}
    </div>
  );
}

function CreationInfo() {
  let { channel } = useContext(PageContext);
  return (
    <div className="flex justify-end gap-1 font-light text-xs tracking-wide">
      <div>Created by</div>
      <Person id={channel.createdBy} />
      <div>|</div>
      <div>{moment(channel.createdAt).fromNow()}</div>
    </div>
  );
}
