import { STATUS_OPTIONS_MAP } from './consts';

export const SCHEDULE_SLOT_KEYS = {
  TODAY: 'TODAY',
  THIS_WEEK: 'THIS_WEEK',
  NEXT_WEEK: 'NEXT_WEEK',
  WEEK_3: 'WEEK_3',
  WEEK_4: 'WEEK_4',
  UNSCHEDULED: 'UNSCHEDULED',
};

export const SCHEDULED_LIST_SLOTS = [
  SCHEDULE_SLOT_KEYS.THIS_WEEK,
  SCHEDULE_SLOT_KEYS.NEXT_WEEK,
  SCHEDULE_SLOT_KEYS.WEEK_3,
  SCHEDULE_SLOT_KEYS.WEEK_4,
];

export const BACKWARD_TIME_GROUP_KEY_MAP = {
  THIS_WEEK: 'THIS_WEEK',
  LAST_WEEK: 'LAST_WEEK',
  EARLIER: 'EARLIER',
};

export const UNSCHEDULED_TIMESTAMP = 10000000000000; // some date in 2603 (unix time in ms)

export const MOMENT_CUSTOM_CAL = {
  lastWeek: 'MMM Do',
  lastDay: '[Yesterday]',
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'ddd, MMM Do',
  sameElse: 'MMM Do',
  /*
  This lets you modify the tokens used by calendar.

  moment.calendarFormat = function (myMoment, now) {
      var diff = myMoment.diff(now, 'days', true);
      var nextMonth = now.clone().add(1, 'month');

      var retVal =  diff < -6 ? 'sameElse' :
          diff < -1 ? 'lastWeek' :
          diff < 0 ? 'lastDay' :
          diff < 1 ? 'sameDay' :
          diff < 2 ? 'nextDay' :
          diff < 7 ? 'nextWeek' :
          // introduce thisMonth and nextMonth
          (myMoment.month() === now.month() && myMoment.year() === now.year()) ? 'thisMonth' :
          (nextMonth.month() === myMoment.month() && nextMonth.year() === myMoment.year()) ? 'nextMonth' : 'sameElse';
      return retVal;
  };
  */
};

export const ONE_DAY_MS = 86400000;

export function getTodayClientStartTime() {
  let d = new Date();
  d.setHours(0, 0, 0, 0);
  return d.getTime();
}

export function getThisWeekMondayClientStartTime() {
  let d = new Date();
  d.setHours(0, 0, 0, 0);
  if (d.getDay() !== 1) {
    d.setDate(d.getDate() + ((1 + 7 - d.getDay()) % 7) - 7);
  }

  return d.getTime();
}

export function getActivityTimeGroupKey(eachActivity, backwardTimeGroupsMap) {
  let thisActivityTimeKey = backwardTimeGroupsMap.EARLIER.key;

  let activityTime = eachActivity.createdAt;

  if (activityTime >= backwardTimeGroupsMap.THIS_WEEK.startTime) {
    thisActivityTimeKey = backwardTimeGroupsMap.THIS_WEEK.key;
  } else if (activityTime >= backwardTimeGroupsMap.LAST_WEEK.startTime) {
    thisActivityTimeKey = backwardTimeGroupsMap.LAST_WEEK.key;
  }

  return thisActivityTimeKey;
}

export function getTodayDeadlineTime(todayStartTimeClient) {
  return todayStartTimeClient + ONE_DAY_MS - 1;
}

export function getScheduleTimestamp(
  scheduleKey,
  thisWeekMondayStartTime_Client,
  todayStartTimeClient
) {
  if (scheduleKey === SCHEDULE_SLOT_KEYS.UNSCHEDULED) {
    return UNSCHEDULED_TIMESTAMP;
  }

  let thisWeekFridayEndTimeClient =
    thisWeekMondayStartTime_Client + ONE_DAY_MS * 5 - 1;

  let todayEndTime = getTodayDeadlineTime(todayStartTimeClient);

  if (SCHEDULE_SLOT_KEYS.TODAY === scheduleKey) {
    return todayEndTime;
  } else if (SCHEDULE_SLOT_KEYS.THIS_WEEK === scheduleKey) {
    let isSatOrSun = todayEndTime > thisWeekFridayEndTimeClient;
    return isSatOrSun ? todayEndTime : thisWeekFridayEndTimeClient;
  } else if (SCHEDULE_SLOT_KEYS.NEXT_WEEK === scheduleKey) {
    return thisWeekFridayEndTimeClient + ONE_DAY_MS * 7 - 1;
  } else if (SCHEDULE_SLOT_KEYS.WEEK_3 === scheduleKey) {
    return thisWeekFridayEndTimeClient + ONE_DAY_MS * 7 * 2 - 1;
  } else if (SCHEDULE_SLOT_KEYS.WEEK_4 === scheduleKey) {
    return thisWeekFridayEndTimeClient + ONE_DAY_MS * 7 * 3 - 1;
  }

  throw Error('unhandled');
}

export function getItemScheduleKey(eachItem, scheduleSlotMap) {
  let scheduleKey;

  let dueTime = eachItem.scheduleTimestamp;
  for (let eachKey of Object.keys(scheduleSlotMap)) {
    let eachSlot = scheduleSlotMap[eachKey];
    if (dueTime <= eachSlot.endTime) {
      scheduleKey = eachKey;
      break;
    }
  }

  return scheduleKey;
}

export function isScheduled(item) {
  if (
    item.status === STATUS_OPTIONS_MAP.SCHEDULED.key ||
    item.status === STATUS_OPTIONS_MAP.URGENT.key ||
    item.status === STATUS_OPTIONS_MAP.REOPENED.key
  ) {
    return true;
  }

  return false;
}

export function isScheduledOld(item) {
  if (item.scheduleTimestamp === UNSCHEDULED_TIMESTAMP) {
    return false;
  }

  return true;
}
