import { getMemberDisplayNames } from '../../../utils';
import UserSession from '../../../utils/classes/UserSession';
import {
  BACKWARD_TIME_GROUP_KEY_MAP,
  getThisWeekMondayClientStartTime,
  getTodayClientStartTime,
  ONE_DAY_MS,
  UNSCHEDULED_TIMESTAMP,
  SCHEDULE_SLOT_KEYS,
} from '../../../utils/timeUtils';
var Immutable = require('immutable');
import moment from 'moment';
import centralisedListManager from './CentralisedListManager';

export function CWP(state) {
  // Current Workspace Path
  return ['workspaces', state.get('currentWorkspaceId')];
}

export function getInitialAppState() {
  let todayStartTimeClient = getTodayClientStartTime();
  let thisWeekMondayStartTime_Client = getThisWeekMondayClientStartTime();
  return Immutable.fromJS({
    currentUserId: UserSession.getCurrentUserId(),
    verifyUserId: UserSession.getVerifyUserId(),
    membershipMap: {},
    currentWorkspaceId: null,
    currentMemberId: null,
    userProfile: {},
    membership: {},
    workspaces: {},
    showAppLoader: false,
    backwardTimeGroupsMap: getBackwardTimeGroupsMap(
      thisWeekMondayStartTime_Client
    ),
    todayStartTimeClient,
    thisWeekMondayStartTime_Client,
    scheduleSlotMap: getScheduleSlotMap(
      thisWeekMondayStartTime_Client,
      todayStartTimeClient
    ),
  });
}

function getBackwardTimeGroupsMap(thisWeekMondayStartTime_Client) {
  let lastWeekStartTime = thisWeekMondayStartTime_Client - ONE_DAY_MS * 7;

  return {
    [BACKWARD_TIME_GROUP_KEY_MAP.EARLIER]: {
      key: BACKWARD_TIME_GROUP_KEY_MAP.EARLIER,
      text: 'EARLIER',
      startTime: 0,
      endTime: lastWeekStartTime - 1,
    },
    [BACKWARD_TIME_GROUP_KEY_MAP.LAST_WEEK]: {
      key: BACKWARD_TIME_GROUP_KEY_MAP.LAST_WEEK,
      text: 'LAST WEEK',
      startTime: lastWeekStartTime,
      endTime: thisWeekMondayStartTime_Client - 1,
    },
    [BACKWARD_TIME_GROUP_KEY_MAP.THIS_WEEK]: {
      key: BACKWARD_TIME_GROUP_KEY_MAP.THIS_WEEK,
      text: 'THIS WEEK',
      startTime: thisWeekMondayStartTime_Client,
      endTime: getTimeNow(),
    },
  };
}

function getTimeNow() {
  let d = new Date();
  return d.getTime();
}

function getScheduleSlotMap(
  thisWeekMondayStartTime_Client,
  todayStartTimeClient
) {
  let todayEndTime = todayStartTimeClient + ONE_DAY_MS - 1;

  let nextWeekStartTime = thisWeekMondayStartTime_Client + ONE_DAY_MS * 7;
  let thisWeekEndTime = nextWeekStartTime - 1;
  let nextWeekEndTime = thisWeekEndTime + ONE_DAY_MS * 7;
  let thirdWeekEndTime = thisWeekEndTime + ONE_DAY_MS * 14;
  let fourthWeekEndTime = thisWeekEndTime + ONE_DAY_MS * 21;

  return {
    [SCHEDULE_SLOT_KEYS.TODAY]: {
      key: SCHEDULE_SLOT_KEYS.TODAY,
      startTime: 0,
      endTime: todayEndTime,
      text: 'Today',
      buttonText: 'Today',
    },
    [SCHEDULE_SLOT_KEYS.THIS_WEEK]: {
      key: SCHEDULE_SLOT_KEYS.THIS_WEEK,
      startTime: todayEndTime + 1,
      endTime: thisWeekEndTime,
      text: 'This Week',
      buttonText: 'This Week',
    },
    [SCHEDULE_SLOT_KEYS.NEXT_WEEK]: {
      key: SCHEDULE_SLOT_KEYS.NEXT_WEEK,
      startTime: thisWeekEndTime + 1,
      endTime: nextWeekEndTime,
      text: 'Next Week',
      buttonText: 'Next Week',
    },
    [SCHEDULE_SLOT_KEYS.WEEK_3]: {
      key: SCHEDULE_SLOT_KEYS.WEEK_3,
      startTime: nextWeekEndTime + 1,
      endTime: thirdWeekEndTime,
      text: moment(nextWeekEndTime + 1).format('MMM D') + ' Week',
      buttonText: moment(nextWeekEndTime + 1).format('MMM D') + ' Week',
    },
    [SCHEDULE_SLOT_KEYS.WEEK_4]: {
      key: SCHEDULE_SLOT_KEYS.WEEK_4,
      startTime: thirdWeekEndTime + 1,
      endTime: fourthWeekEndTime,
      text: moment(thirdWeekEndTime + 1).format('MMM D') + ' Week',
      buttonText: moment(thirdWeekEndTime + 1).format('MMM D') + ' Week',
    },
    [SCHEDULE_SLOT_KEYS.UNSCHEDULED]: {
      key: SCHEDULE_SLOT_KEYS.UNSCHEDULED,
      startTime: fourthWeekEndTime + 1,
      endTime: UNSCHEDULED_TIMESTAMP,
      text: 'Unscheduled',
      buttonText: 'Unscheduled',
    },
  };
}

export function addMemberToWorkspace(state, member) {
  let myEmail = state.getIn(['userProfile', 'email']);
  let { displayName, relativeName, domainName } = getMemberDisplayNames(
    member.email,
    myEmail
  );

  if (!member.displayName) {
    member.displayName = displayName;
  }
  member.relativeName = relativeName;
  member.domainName = domainName;

  state = state.setIn(
    CWP(state).concat(['membersMap', member.id]),
    Immutable.fromJS(member)
  );

  centralisedListManager.processNewMember(member.id);
  state = centralisedListManager.saveState(state);

  return state;
}

export function IAP(state, workId, propName) {
  // Item Attribute Path
  return CWP(state).concat(['workDetailsMap', workId, propName]);
}

export function IP(state, workId) {
  // Item Path
  return CWP(state).concat(['workDetailsMap', workId]);
}

export function addWorkParticipant(state, workId, participantId) {
  state = state.updateIn(IAP(state, workId, 'participantIds'), (list) => {
    if (list.indexOf(participantId) !== -1) {
      return list;
    }
    return list.push(participantId);
  });

  return state;
}

export function saveSeenActivity(state, activity) {
  if (!activity) {
    return state;
  }

  state = state.setIn(
    CWP(state).concat(['activitiesSinceLastSync', activity.id]),
    true
  );

  return state;
}

export function logInvalidWork() {
  // eslint-disable-next-line no-console
  console.log('action received for non existing work');
}
