import store from '../store';
import axios from 'axios';
import humps from 'humps';
import UserSession from './classes/UserSession';
import { ERR_ALERT } from '../redux/actions/AppActionTypes';

axios.interceptors.request.use(function (request) {
  let isUserApiReq = request.url.indexOf('/api/user') !== -1;
  let isNotGuestApiReq = request.url.indexOf('/api/guest') === -1;
  let isSysAdminApiReq = request.url.indexOf('/api/sysadmin') !== -1;

  if (isUserApiReq || isSysAdminApiReq) {
    var apiKey = UserSession.getApiKey();
    if (
      request.headers &&
      (!request.headers.Authorization ||
        request.headers.Authorization.indexOf('ApiKey'))
    ) {
      request.headers['Authorization'] = `ApiKey ${apiKey}`;
    }
  } else if (isNotGuestApiReq) {
    var memberToken = UserSession.getMemberToken();
    if (
      request.headers &&
      (!request.headers.Authorization ||
        request.headers.Authorization.indexOf('MemberToken') === -1)
    ) {
      request.headers['Authorization'] = `MemberToken ${memberToken}`;
    }
  }

  /*
  if (request.data) {
    console.log(request.data);
    request.data = JSON.stringify(humps.decamelizeKeys(JSON.parse(request.data)));
  }
  */
  return request;
});

axios.interceptors.response.use(
  function (response) {
    if (response.data) {
      return humps.camelizeKeys(response.data);
    }
  },
  function (error) {
    if (error.response) {
      store.dispatch({ type: ERR_ALERT, error });
    }

    throw error;
  }
);

export default axios;
