import { useState, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import SuperNav from '../Components/SuperNav/SuperNav';
import DefaultRoutesResolver from './DefaultRoutesResolver';
import { AppContext, WorkspaceContext } from '../../../utils/contexts';
import config from 'config';
import {
  ItemSuccessSnackBar,
  GenericSuccessSnackBar,
} from '../../UtilComponents/TinyComponents';
import { useToggleState } from '../../../utils/hook';
import { NAV_KEYS, PAGES } from '../../../utils/consts';
import { getSearchResults } from '../../../utils';
import { useContext } from 'react';
import store from '../../../store';
import {
  CLEAR_MOVED_ITEM_PLACEHOLDER,
  LOGOUT,
  RECON_ACTIVITY,
  SEEN_INTRO,
} from '../../../redux/actions/AppActionTypes';
import ChannelRoutesResolver from './ChannelRoutesResolver';
import Logo from '../../../images/logo.svg';
import MilestoneRoutesResolver from './MilestoneRoutesResolver';
import introJs from 'intro.js';
import ApiClient from '../../../utils/classes/ApiClient';
import { WORK_ACTIVITY_TYPE } from '../../../utils/activityUtils';

export default function Workspace(props) {
  const { isMobile, isWindows, seenIntroStep } = useContext(AppContext);

  if (isMobile && config.isProd) {
    return <MobileViewMessage />;
  }

  let { workspaceState } = props;

  let {
    meId,
    membersMap,
    workDetailsMap,
    allChannelsMap,
    orderedChannels,
    allMilestonesMap,
    orderedMilestones,
    refreshListFlag,
    pageData,
    feedMap,
    notifList,
    pinsMap,
    lastUpdatedWork,
    lastActivitySyncTime,
  } = workspaceState;

  let { prevPosKeyInSourceList } = lastUpdatedWork;

  let allWorkIds = Object.keys(workDetailsMap);
  let allOpenWorkIds = [];

  allWorkIds.map((eachWorkId) => {
    let work = workDetailsMap[eachWorkId];
    if (!work.archived) {
      allOpenWorkIds.push(eachWorkId);
    }
  });

  useEffect(() => {
    // example: setTimeout and clearTimeout | timed effect
    // reset prevPosKeyInSourceList after 5 secs
    if (prevPosKeyInSourceList) {
      const FIVE_SECS = 1000 * 5;
      let timer = setTimeout(() => {
        store.dispatch({ type: CLEAR_MOVED_ITEM_PLACEHOLDER });
      }, FIVE_SECS);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [prevPosKeyInSourceList]);

  useEffect(() => {
    const FIVE_MINS = 1000 * 5 * 60;
    let delay = FIVE_MINS;
    if (!config.isProd) {
      delay = FIVE_MINS / 10; // every 30 secs for dev setups
    }

    let timer = setTimeout(() => {
      ApiClient.get(
        `self/getNewActivity?startTime=${lastActivitySyncTime}`
      ).then((res) => {
        store.dispatch({
          type: RECON_ACTIVITY,
          newActivity: res.newActivity,
        });
      });
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [lastActivitySyncTime]);

  let location = useLocation();

  let urlItemId = new URLSearchParams(location.search).get('item');
  let initialActiveItemState = {
    workId: urlItemId,
  };

  let {
    activeItemState,
    setActiveItem,
    clearActiveItem,
    activeItemCurrentPosition,
    setActiveItemCurrentPosition,
  } = useActiveItemState(initialActiveItemState);

  useEffect(() => {
    setActiveItem(urlItemId);
    if (!urlItemId) {
      setActiveItemCurrentPosition(null);
    }
  }, [urlItemId]);

  let defaultSnackBarParams = { workId: null, message: '' };
  let [itemSnackBarParams, setItemSuccessSnackBarParams] = useState(
    defaultSnackBarParams
  );

  let [successMsg, setSuccessMsg] = useState(null);

  let [newItemGoBackContext, setNewItemGoBackContext] = useState({
    key: PAGES.MY_WORK.key,
    path: PAGES.MY_WORK.link,
    title: PAGES.MY_WORK.text,
    pageId: null,
  });

  let [createTaskMode, toggleCreateTaskMode] = useToggleState(false);

  let {
    searchObject,
    resetSearchTerm,
    updateSearchTerm,
    toggleSearchPortal,
  } = getSearchStuff(workDetailsMap);

  const handleNavClick = () => {
    resetSearchTerm();
    clearActiveItem();
    if (createTaskMode) {
      toggleCreateTaskMode();
    }
  };

  let notifListGroupedByImportance = {
    [NAV_KEYS.NOTIF_IMP.key]: [],
    [NAV_KEYS.NOTIF_GENERAL.key]: [],
  };

  for (let eachNotif of notifList) {
    let isImp = false;
    let work = workDetailsMap[eachNotif.workId];
    if (!work) {
      // eslint-disable-next-line no-console
      console.log(
        'work item not found for notif',
        eachNotif.workId,
        eachNotif.id
      );
      continue;
    }

    if (
      eachNotif.type === WORK_ACTIVITY_TYPE.NOTE_ADD.key ||
      eachNotif.type === WORK_ACTIVITY_TYPE.NOTE_EDIT.key
    ) {
      // all mentions are important
      isImp = true;
    } else {
      if (work.assignedTo === meId) {
        // if item is assigned to me, it's important
        isImp = true;
      }
    }

    let notifKey = NAV_KEYS.NOTIF_GENERAL.key;
    if (isImp) {
      notifKey = NAV_KEYS.NOTIF_IMP.key;
    }

    notifListGroupedByImportance[notifKey].push(eachNotif);
  }

  let workItemProps = {
    clearActiveItem,
    activeItemState,
    activeItemCurrentPosition,
    setActiveItemCurrentPosition,
  };

  let searchProps = {
    updateSearchTerm,
    resetSearchTerm,
    toggleSearchPortal,
  };

  let createItemProps = {
    createTaskMode,
    toggleCreateTaskMode,
    newWorkCancelHandler: toggleCreateTaskMode,
  };

  let newItemNavigationStuff = {
    newItemGoBackContext,
    setNewItemGoBackContext,
  };

  let superNavStuff = {
    handleNavClick,
  };

  let successMsgStuff = {
    setItemSuccessSnackBarParams,
    setSuccessMsg,
  };

  let workspaceContext = {
    membersMap,
    workDetailsMap,
    meId,
    allOpenWorkIds,
    searchObject,
    feedMap,
    notifListGroupedByImportance,
    pinsMap,
    allChannelsMap,
    orderedChannels,
    allMilestonesMap,
    orderedMilestones,
    ...successMsgStuff,
    ...superNavStuff,
    ...newItemNavigationStuff,
    ...workItemProps,
    ...searchProps,
    ...createItemProps,
    refreshListFlag,
    pageData,
    lastUpdatedWork,
  };

  useEffect(() => {
    if (seenIntroStep) {
      return;
    }

    introJs()
      .onexit(function () {
        return ApiClient.post('self/setSeenIntroStep', {
          stepNum: this._currentStep + 1,
        }).then(() => {
          store.dispatch({ type: SEEN_INTRO, stepNum: this._currentStep });
        });
      })
      .setOptions({
        tooltipClass: 'step-tooltip',
        highlightClass: 'step-layer',
        exitOnOverlayClick: false,
        steps: [
          {
            title: 'Welcome to WorkStudio!',
            intro: 'Let me show you around. Click "Next" to begin.',
            position: 'right',
          },
          {
            element: '.my-work-container',
            intro: `
            At WorkStudio, you have three kinds of views available to you.
            <br/><br/>Your starting point is the <span class="font-medium active">My Work</span> view.
            It's home to all things currently assigned to and owned by you.
            `,
            position: 'right',
          },
          {
            element: '#milestone-section-head',
            intro: `
              Next up is the <span class="font-medium active">Milestones</span> view.
              <br/><br/>Milestones are great for planning initiatives / releases and, tracking their progress.
              <br/><br/>Use the plus button here to create milestones.`,
            position: 'right',
          },
          {
            element: '#channel-section-head',
            intro: `
            Tags let you organise and share work items.`,
            position: 'right',
          },
          {
            title: 'The Workflow',
            element: '#my-work-sec-nav',
            intro: `
            <div class="tracking-wide leading-relaxed">
              <div class="text-lg hl-text mb-4 flex justify-center gap-4">
                <div>Organise</div>
                <div>&#8594;</div>
                <div>Plan</div>
                <div>&#8594;</div>
                <div>Schedule</div>
                <div>&#8594;</div>
                <div>Do</div>
              </div>
                Items are organised using tags, and planned through milestones. Scheduling indicates that items are ready to be picked for execution.
              <br/>
              <br/>
              Accordingly, they are grouped into four separate lists.
            </div>
            `,
            position: 'right',
            tooltipClass: 'step-tooltip workflow-step',
          },
          {
            element: '#item-create-link',
            intro: `
            You can create new items using this button here.`,
            position: 'right',
          },
          {
            element: '#search-n-history-link',
            intro: `
            Search the entire workspace for items from here.
            <br/><br/>
            Everything you have ever been involved with will also be available here, neatly organised by recency.
            `,
            position: 'right',
          },
          {
            element: '.invite-user-link',
            intro: `
            WorkStudio is a team tool. Invite teammates to get the best out of WorkStudio.`,
            position: 'right',
          },
          {
            title: 'Good luck!',
            intro: `
            We are listening.<br/>
            Don't hesitate to reach us at
            <span class="hl-text">support@workstudio.app</span> for feature requests, feedback, a demo or any other assistance.`,
          },
        ],
      })
      .start()
      .goToStep(1); // default step is 1
  }, []);

  return (
    <WorkspaceContext.Provider value={workspaceContext}>
      <GenericSuccessSnackBar message={successMsg} setMessage={setSuccessMsg} />
      <ItemSuccessSnackBar
        params={itemSnackBarParams}
        setParams={setItemSuccessSnackBarParams}
      />
      <div
        className={`flex w-full h-full relative ${isWindows ? 'windows' : ''}`}
        style={{ zoom: isWindows ? '90%' : '100%' }}
      >
        <SuperNav />
        <div className="flex-auto h-full">
          <Switch>
            <Route
              path={PAGES.CHANNEL.link + ':channelId/'}
              render={(props) => <ChannelRoutesResolver {...props} />}
            />
            <Route
              path={PAGES.MILESTONE.link + ':milestoneId/'}
              render={(props) => <MilestoneRoutesResolver {...props} />}
            />
            <Route
              path="*"
              render={(props) => <DefaultRoutesResolver {...props} />}
            />
          </Switch>
        </div>
      </div>
    </WorkspaceContext.Provider>
  );
}

function MobileViewMessage() {
  function logout() {
    store.dispatch({ type: LOGOUT });
  }

  return (
    <div className="flex-cm flex-col gap-8 h-full px-20">
      <div className="mb-24">
        <img src={Logo} className="h-12" />
      </div>
      <p>Desktop browsers only</p>
      <p className="text-lg font-bold">Mobile app coming soon</p>
      <p>
        WorkStudio is not mobile ready yet. We are working on it and will keep
        you posted.
      </p>
      <p>Thanks for your patience!</p>
      <div className="cell mt-8">
        <div onClick={logout} className="option anchor">
          Logout
        </div>
      </div>
    </div>
  );
}

function getSearchStuff(workDetailsMap) {
  let defaultSearchObject = {
    searchTerm: '',
    searchDone: false,
    activeItems: [],
    archivedItems: [],
    on: false,
  };

  let [searchObject, setSearchObject] = useState(defaultSearchObject);

  function toggleSearchPortal() {
    setSearchObject({ ...defaultSearchObject, on: !searchObject.on });
  }

  function resetSearchTerm() {
    setSearchObject(defaultSearchObject);
  }

  function updateSearchTerm(e) {
    let searchTerm = e.target.value;
    if (!searchTerm.length) {
      resetSearchTerm();
      return;
    }

    if (searchTerm.length < 3) {
      setSearchObject({ ...searchObject, searchTerm: searchTerm });
      return;
    }

    let items = getSearchResults(searchTerm, workDetailsMap);
    setSearchObject({
      ...searchObject,
      searchTerm,
      searchDone: true,
      activeItems: items.active,
      archivedItems: items.archived,
      on: true,
    });
  }

  return {
    searchObject,
    resetSearchTerm,
    updateSearchTerm,
    toggleSearchPortal,
  };
}

// active item is the one that is currently selected and details being viewed
function useActiveItemState(initialActiveItemState) {
  const [activeItemState, setActiveItemState] = useState(
    initialActiveItemState
  );

  const [activeItemCurrentPosition, setActiveItemCurrentPosition] = useState(
    null
  );

  function clearActiveItem() {
    setActiveItemState({
      workId: null,
    });
  }

  function setActiveItem(workId) {
    setActiveItemState({
      workId,
    });
  }

  return {
    activeItemState,
    setActiveItem,
    clearActiveItem,
    activeItemCurrentPosition,
    setActiveItemCurrentPosition,
  };
}
