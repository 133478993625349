import { CWP, saveSeenActivity } from '.';
import centralisedListManager from './CentralisedListManager';
import { MILESTONE_ACTIVITY_TYPE } from '../../../utils/activityUtils';

const Immutable = require('immutable');

export function handleMilestoneActivity(state, action) {
  switch (action.activity.type) {
    case MILESTONE_ACTIVITY_TYPE.NEW_MILESTONE.key: {
      return handleNewMilestone(state, action);
    }

    case MILESTONE_ACTIVITY_TYPE.UPDATE_MILESTONE_NAME.key: {
      return editMilestoneName(state, action);
    }

    case MILESTONE_ACTIVITY_TYPE.UPDATE_MILESTONE_DATE.key: {
      return editMilestoneDate(state, action);
    }

    case MILESTONE_ACTIVITY_TYPE.UPDATE_MILESTONE_OWNER.key: {
      return updateMilestoneOwner(state, action);
    }

    case MILESTONE_ACTIVITY_TYPE.ADD_MILESTONE_MEMBER.key: {
      return addMilestoneMember(state, action);
    }

    case MILESTONE_ACTIVITY_TYPE.REMOVE_MILESTONE_MEMBER.key: {
      return removeMilestoneMember(state, action);
    }

    case MILESTONE_ACTIVITY_TYPE.UPDATE_MILESTONE_DESCRIPTION.key: {
      return updateMilestoneDescription(state, action);
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn(
        'unknown activity type',
        action.activity.type,
        '(category:',
        action.activity.category,
        ')'
      );
      return state;
    }
  }
}

function editMilestoneName(state, action) {
  let { activity } = action;

  let newName = activity.newVal;
  let milestoneId = activity.milestoneId;

  state = state.setIn(
    CWP(state).concat(['allMilestonesMap', milestoneId, 'name']),
    newName
  );

  state = saveMilestonesOrder(state);

  state = saveSeenActivity(state, activity);

  return state;
}

function editMilestoneDate(state, action) {
  let { activity } = action;

  let newEndDateTs = activity.newVal;
  let milestoneId = activity.milestoneId;

  state = state.setIn(
    CWP(state).concat(['allMilestonesMap', milestoneId, 'endDateTs']),
    newEndDateTs
  );

  state = saveMilestonesOrder(state);

  state = saveSeenActivity(state, activity);

  return state;
}

function updateMilestoneOwner(state, action) {
  let { activity } = action;

  let newOwnerId = activity.newVal;
  let milestoneId = activity.milestoneId;

  state = state.setIn(
    CWP(state).concat(['allMilestonesMap', milestoneId, 'owner']),
    newOwnerId
  );

  // Add to member list if needed
  let milestoneMembers = state
    .getIn(CWP(state).concat(['allMilestonesMap', milestoneId, 'memberIds']))
    .toJS();

  if (milestoneMembers.indexOf(newOwnerId) === -1) {
    state = state.updateIn(
      CWP(state).concat(['allMilestonesMap', milestoneId, 'memberIds']),
      (list) => list.push(newOwnerId)
    );

    // could be fired via sync, handle addition of self too
    let meId = state.getIn(CWP(state).concat(['meId']));
    if (newOwnerId === meId) {
      // set mine flag on milestone
      state = state.setIn(
        CWP(state).concat(['allMilestonesMap', milestoneId, 'isMine']),
        true
      );
    }
  }

  state = updateVisibility(state, milestoneId);

  state = saveSeenActivity(state, activity);

  return state;
}

function addMilestoneMember(state, action) {
  let { activity } = action;

  let memberId = activity.newVal;
  let milestoneId = activity.milestoneId;

  let milestoneMembers = state.getIn(
    CWP(state).concat(['allMilestonesMap', milestoneId, 'memberIds'])
  );

  if (milestoneMembers.indexOf(memberId) === -1) {
    state = state.updateIn(
      CWP(state).concat(['allMilestonesMap', milestoneId, 'memberIds']),
      (list) => list.push(memberId)
    );

    state = updateVisibility(state, milestoneId);
  }

  // can be fired via syncer, so handle addition of self too
  let meId = state.getIn(CWP(state).concat(['meId']));
  if (memberId === meId) {
    // set mine flag on milestone
    state = state.setIn(
      CWP(state).concat(['allMilestonesMap', milestoneId, 'isMine']),
      true
    );
  }

  state = saveSeenActivity(state, activity);

  return state;
}

export function addMilestone(state, milestone) {
  let meId = state.getIn(CWP(state).concat(['meId']));

  milestone.isPrivate = milestone.memberIds.length === 1;
  milestone.isAbandoned = !milestone.memberIds.length;
  milestone.isMine = milestone.memberIds.indexOf(meId) !== -1;

  state = state.setIn(
    CWP(state).concat(['allMilestonesMap', milestone.id]),
    Immutable.fromJS(milestone)
  );

  centralisedListManager.processNewMilestone(milestone.id);
  state = centralisedListManager.saveState(state);

  return state;
}

function removeMilestoneMember(state, action) {
  let { activity } = action;

  let { milestoneId, newVal: memberId } = activity;

  state = state.updateIn(
    CWP(state).concat(['allMilestonesMap', milestoneId, 'memberIds']),
    (list) => list.delete(list.indexOf(memberId))
  );

  let memberIds = state.getIn(
    CWP(state).concat(['allMilestonesMap', milestoneId, 'memberIds'])
  );

  if (memberIds.size === 1) {
    state = state.setIn(
      CWP(state).concat(['allMilestonesMap', milestoneId, 'isPrivate']),
      true
    );
  } else if (!memberIds.size) {
    state = state.setIn(
      CWP(state).concat(['allMilestonesMap', milestoneId, 'isAbandoned']),
      true
    );
  }

  let meId = state.getIn(CWP(state).concat(['meId']));
  if (memberId === meId) {
    state = state.setIn(
      CWP(state).concat(['allMilestonesMap', milestoneId, 'isMine']),
      false
    );
  }

  state = saveSeenActivity(state, activity);

  return state;
}

function updateMilestoneDescription(state, action) {
  let { activity } = action;

  let { milestoneId, newVal } = activity;

  if (!state.hasIn(CWP(state).concat(['allMilestonesMap', milestoneId]))) {
    // eslint-disable-next-line no-console
    console.log('action received for non existing milestone');
    return state;
  }

  state = state.setIn(
    CWP(state).concat(['allMilestonesMap', milestoneId, 'description']),
    newVal
  );

  return state;
}

function milestoneSorter(a, b) {
  if (a.endDateTs && b.endDateTs) {
    if (a.endDateTs < b.endDateTs) {
      return -1;
    } else if (a.endDateTs > b.endDateTs) {
      return 1;
    }
  } else if (a.endDateTs) {
    return -1;
  } else if (b.endDateTs) {
    return 1;
  }

  // fallback to name if no timestamp
  if (a.name < b.name) {
    return -1;
  } else {
    return 1;
  }
}

export function saveMilestonesOrder(state) {
  // sort and build new map
  let allMilestonesMap = state
    .getIn(CWP(state).concat(['allMilestonesMap']))
    .toJS();
  let allMilestones = Object.values(allMilestonesMap);
  allMilestones.sort(milestoneSorter);

  let orderedMilestones = [];
  for (let milestone of allMilestones) {
    orderedMilestones.push(milestone.id);
  }

  state = state.setIn(
    CWP(state).concat(['orderedMilestones']),
    Immutable.fromJS(orderedMilestones)
  );

  return state;
}

function updateVisibility(state, milestoneId) {
  let isPrivate = state.getIn(
    CWP(state).concat(['allMilestonesMap', milestoneId, 'isPrivate'])
  );

  if (!isPrivate) {
    // already shared, nothing to do
    return state;
  }

  // update milestone.isPrivate
  state = state.setIn(
    CWP(state).concat(['allMilestonesMap', milestoneId, 'isPrivate']),
    false
  );

  // update all work items
  let workDetailsMap = state
    .getIn(CWP(state).concat(['workDetailsMap']))
    .toJS();
  let allWork = Object.values(workDetailsMap);
  let milestoneWork = allWork.filter(
    (each) => each.milestoneId === milestoneId
  );
  for (let each of milestoneWork) {
    state = state.setIn(
      CWP(state).concat(['workDetailsMap', each.id, 'isPrivate']),
      false
    );
  }

  return state;
}

function handleNewMilestone(state, action) {
  let { milestone, activity } = action;

  state = addMilestone(state, milestone);

  state = saveMilestonesOrder(state);

  state = saveSeenActivity(state, activity);

  return state;
}
