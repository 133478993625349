import React, { useContext } from 'react';
import { NAV_KEYS, PAGES } from '../../../utils/consts';
import { PageContext, WorkspaceContext } from '../../../utils/contexts';
import { WS_GoBackIcon } from '../../UtilComponents/Icons';
import ItemDetails from '../Components/ItemDetails/ItemDetails';
import { Link, useLocation } from 'react-router-dom';
import getSuggestionUtils from '../../../utils/suggestionUtils';
import { isScheduled } from '../../../utils/timeUtils';
import history from '../../../history';
import { getMyStreamLocateLink } from '../../../utils';

export default function ItemPage(props) {
  let { allMilestonesMap, newItemGoBackContext } = useContext(WorkspaceContext);
  let { match } = props;
  let itemId = match.params.itemId;

  let {
    suggestedChannelIds,
    updateSuggestionsForNewChannel,
    suggestedMilestoneIds,
    updateSuggestionsForNewMilestone,
    suggestedOwnerIds,
    updateSuggestionsForNewOwner,
    suggestedAssigneeIds,
    updateSuggestionsForNewAssignee,
  } = getSuggestionUtils(allMilestonesMap, newItemGoBackContext);

  let query = new URLSearchParams(useLocation().search);
  let isNewItem = Boolean(query.get('new'));

  let parentContext = {
    pageKey: PAGES.ITEM.key,
    itemId,
    suggestedChannelIds,
    updateSuggestionsForNewChannel,
    suggestedMilestoneIds,
    updateSuggestionsForNewMilestone,
    suggestedOwnerIds,
    updateSuggestionsForNewOwner,
    suggestedAssigneeIds,
    updateSuggestionsForNewAssignee,
    isNewItem,
  };

  return (
    <PageContext.Provider value={parentContext}>
      <div className="p-8 w-full lg:w-2/3 h-screen">
        <Main />
      </div>
    </PageContext.Provider>
  );
}

function Main() {
  let { itemId } = useContext(PageContext);
  let { workDetailsMap, meId, allChannelsMap } = useContext(WorkspaceContext);

  let item = workDetailsMap[itemId];

  let isMyWork = [item.assignedTo, item.owner].indexOf(meId) !== -1;

  let isScheduledItem = isScheduled(item);

  let myStreamLocateLink = getMyStreamLocateLink(
    item,
    isMyWork,
    isScheduledItem,
    meId
  );

  // todo: each of the channelLocations below will render one button
  // probably better handle differently;
  // came about as quick fix to multi-channel support
  let channelLocations = {};
  for (let eachChannelId of item.channelIds) {
    let location = PAGES.CHANNEL.link + eachChannelId;
    if (isScheduledItem) {
      location += '/' + NAV_KEYS.SCHEDULED.key;
    } else if (item.milestoneId) {
      location += '/' + NAV_KEYS.PLANNED.key;
    } else {
      location += '/' + NAV_KEYS.UNPLANNED.key;
    }
    location += '?item=' + itemId;
    channelLocations[eachChannelId] = location;
  }

  return (
    <div className="flex flex-col gap-4 h-full scroll-please">
      <div className="flex gap-8">
        <button
          type="button"
          className="flex gap-2 items-center active px-4"
          onClick={history.goBack}
        >
          <WS_GoBackIcon style={{ fontSize: '1.2rem' }} />
          <div>Back</div>
        </button>
        {myStreamLocateLink ? (
          <Link to={myStreamLocateLink}>
            <button
              className="glass w-full flex gap-2 px-4 items-center semi-active"
              type="button"
            >
              Locate in {isMyWork ? 'My Work' : 'History'}
            </button>
          </Link>
        ) : null}
        {Object.keys(channelLocations).map((channelId) => {
          return (
            <Link
              to={channelLocations[channelId]}
              key={`channel_locate_${channelId}`}
            >
              <button
                type="button"
                className="glass w-full flex gap-2 px-4 items-center semi-active"
              >
                Locate in #{allChannelsMap[channelId].name}
              </button>
            </Link>
          );
        })}
      </div>
      <div className="work-details-container">
        <ItemDetails itemId={itemId} key={itemId} />
      </div>
    </div>
  );
}
