import React from 'react';
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Popover,
  Fab,
  CircularProgress,
  DialogContentText,
  DialogActions,
  Avatar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {
  getAvatarStyler,
  getLoaderStyles,
  loaderDialogPaperStyles,
} from '../../utils/styles';

export function WSSuccessSnackBar(props) {
  let { open, close } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    close();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity="success"
        elevation={6}
        variant="filled"
      >
        {props.children}
      </Alert>
    </Snackbar>
  );
}

export function AppErrorSnackBar(props) {
  let { open, close } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    close();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity="error"
        elevation={6}
        variant="filled"
        style={{ width: 300 }}
      >
        {props.children}
      </Alert>
    </Snackbar>
  );
}

export function WSAvatarGroup(props) {
  let { children, max } = props;

  return <AvatarGroup max={max}>{children}</AvatarGroup>;
}

export function WSDialog(props) {
  let { anchor, title, content, handleClose, open } = props;
  return (
    <div>
      {anchor}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ws-surface"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </div>
  );
}

export function WSIconButton(props) {
  let { children, onClick, diameter, style = {}, className = '' } = props;

  if (!onClick) {
    onClick = () => {};
  }

  if (diameter) {
    style.width = diameter;
    style.height = diameter;
    style.padding = '6px';
    if (diameter < 30) {
      style.padding = '5px';
    }
  }

  return (
    <IconButton
      disableRipple
      size="medium"
      style={style}
      onClick={onClick}
      className={className}
    >
      {children}
    </IconButton>
  );
}

export function WSPopover(props) {
  let {
    ContentComponent,
    children,
    anchorOrigin,
    transformOrigin,
    decoratorClass = 'ws-surface',
    anchorDecorationClass = '',
    ...others
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    if (e) {
      e.stopPropagation();
    }
  };

  if (!anchorOrigin) {
    anchorOrigin = {
      vertical: 'center',
      horizontal: 'right',
    };
  }

  if (!transformOrigin) {
    transformOrigin = {
      vertical: 'center',
      horizontal: 'left',
    };
  }

  return (
    <>
      <div className={`${anchorDecorationClass}`} onClick={handleOpen}>
        {children}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={decoratorClass}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <ContentComponent handleClose={handleClose} {...others} />
      </Popover>
    </>
  );
}

export function WSMenu(props) {
  let { contents, children } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  return (
    <>
      <div onClick={handleOpen}>{children}</div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className="ws-surface"
      >
        <div onClick={handleClose}>{contents}</div>
      </Popover>
    </>
  );
}

export function WSFloatingButton(props) {
  let { children } = props;

  return (
    <Fab aria-label="add" size="small">
      {children}
    </Fab>
  );
}

export function ApiLoader(props) {
  const { show } = props;

  return (
    <Dialog
      open={show}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      PaperProps={{ style: loaderDialogPaperStyles }}
    >
      <LoaderIcon />
    </Dialog>
  );
}

export function LoaderIcon(props) {
  let { size } = props;

  if (!size) {
    size = 40;
  }

  let styles = getLoaderStyles(size);

  return (
    <div style={styles.container}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={size}
        className="circular-loader-1"
      />
      <CircularProgress
        style={styles.loader2}
        size={size}
        className="circular-loader-2"
      />
    </div>
  );
}

export function DeleteDialog(props) {
  let {
    handleDelete,
    handleClose,
    dialogMessage,
    open,
    buttonText,
    titleText,
    className = '',
  } = props;

  if (!buttonText) {
    buttonText = 'Delete';
  }

  if (!titleText) {
    titleText = 'Are you sure?';
  }

  function handleCloseLocal(e) {
    handleClose(e);
    e.stopPropagation();
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseLocal}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      className={className}
    >
      <div className="p-4 dialog-box">
        <DialogTitle id="delete-dialog-title" className="active">
          {titleText}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained" color="secondary">
            {buttonText}
          </Button>
          <Button
            onClick={handleCloseLocal}
            variant="contained"
            autoFocus
            disableRipple
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export function DescImageDialog(props) {
  let { handleClose, open, className = '', children } = props;

  function handleCloseLocal(e) {
    handleClose(e);
    e.stopPropagation();
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseLocal}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      className={className + ' desc-dialog'}
    >
      <div className="p-8 flex min-h-0 flex-col items-end gap-8">
        <div className="flex-auto scroll-please">{children}</div>
        <Button
          onClick={handleCloseLocal}
          variant="contained"
          autoFocus
          disableRipple
          color="secondary"
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
}

export function GenericConfirmationDialog(props) {
  let { handleConfirm, handleClose, dialogMessage, open } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="p-4">
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export function WSAvatar(props) {
  let { children, size, fontSize, selfColor, group } = props;

  const avatarClasses = makeStyles(
    getAvatarStyler(size, fontSize, selfColor)
  )();

  return (
    <Avatar
      className={`${avatarClasses.colors} ${
        group ? 'MuiAvatarGroup-avatar' : ''
      }`}
    >
      {children}
    </Avatar>
  );
}
