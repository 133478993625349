import React from 'react';
import PageFrame from './PageFrame';

export default function BasicPage(props) {
  let { Header, Main } = props;

  return (
    <PageFrame>
      <div className="grid-y grid-frame overflow-y-auto">
        <div className="cell shrink page-header middle relative">
          <Header />
        </div>
        <div className="cell auto h-full">
          {Main ? (
            <div className="h-full">
              <Main />
            </div>
          ) : null}
        </div>
      </div>
    </PageFrame>
  );
}
