export const PAGES = {
  MY_WORK: {
    key: 'MY_WORK',
    text: 'My Work',
    link: '/my-work',
  },
  SEARCH_PLUS: {
    key: 'SEARCH_PLUS',
    text: 'Search & History',
    link: '/search-n-history',
  },
  NOTIF: {
    key: 'NOTIF',
    text: 'Notifications',
    link: '/notifications',
  },
  ITEM: {
    key: 'ITEM',
    text: '',
    link: '/item',
  },
  CHANNEL: {
    key: 'CHANNEL',
    text: '',
    link: '/channel/',
  },
  MILESTONE: {
    key: 'MILESTONE',
    text: '',
    link: '/milestone/',
  },
  WORKSPACE_ADMIN: {
    key: 'WORKSPACE_ADMIN',
    text: '',
    link: '/workspace/settings',
  },
  EXP: {
    key: 'EXP',
    text: '',
    link: '/exp',
  },
};

export const ARCHIVE_FEED_ENDPOINT = 'self/archiveFeed';
export const ARCHIVE_NOTIF_ENDPOINT = 'self/archiveNotif';

export const DATE_SELECTOR_TYPE = {
  ICON: 'ICON',
  TEXT: 'TEXT',
};

export const WORK_ITEM_SECTION_TYPE = {
  DESCRIPTION: 'DESCRIPTION',
  SUBITEMS: 'SUBITEMS',
  NOTES: 'NOTES',
  FOLLOW_UPS: 'FOLLOW_UPS',
};

export const WS_UPDATE_TYPE = {
  WORK_ACTIVITY: 'WORK_ACTIVITY',
  NEW_WORK: 'NEW_WORK',
  ADD_WS_MEMBER: 'ADD_WS_MEMBER',
  //
  NEW_CHANNEL: 'NEW_CHANNEL',
  ADD_CHANNEL_MEMBER: 'ADD_CHANNEL_MEMBER',
  UPDATE_CHANNEL_NAME: 'UPDATE_CHANNEL_NAME',
  //
  NEW_MILESTONE: 'NEW_MILESTONE',
  ADD_MILESTONE_MEMBER: 'ADD_MILESTONE_MEMBER',
  UPDATE_MILESTONE_OWNER: 'UPDATE_MILESTONE_OWNER',
  UPDATE_MILESTONE_DATE: 'UPDATE_MILESTONE_DATE',
  UPDATE_MILESTONE_NAME: 'UPDATE_MILESTONE_NAME',
};

// todo: not an exhaustive list
export const NON_BUSINESS_EMAIL_DOMAINS = ['gmail.com', 'outlook.com'];

export const UNKNOWN_CHANNEL = {
  key: '?',
  name: '?',
};

// counter sub keys for items
export const SCHEDULED = 'SCHEDULED';

export const OTHER_FEEDS = 'OTHER_FEEDS';

export const LIST_TYPES = {
  PRIORITY: 'PRIORITY',
  ACTIVITY: 'ACTIVITY',
  NOTIFICATIONS: 'NOTIFICATIONS',
  FEED: 'FEED',
};

export const PRIMARY_DATA_KEYS = {
  MY_STREAM: 'MY_STREAM',
  CHANNEL: 'CHANNEL',
  MILESTONE: 'MILESTONE',
  PEOPLE: 'PEOPLE',
};

export const MY_STREAM_DATA_KEYS = {
  ASSIGNED_TO_ME: {
    key: 'ASSIGNED_TO_ME',
    listType: LIST_TYPES.PRIORITY,
  },
  OWNED_BY_ME: {
    key: 'OWNED_BY_ME',
    listType: LIST_TYPES.PRIORITY,
  },
  MY_WORK_HISTORY: {
    key: 'MY_WORK_HISTORY',
    listType: LIST_TYPES.ACTIVITY,
  },
  MY_CREATIONS_OPEN: {
    key: 'MY_CREATIONS_OPEN',
    listType: LIST_TYPES.ACTIVITY,
  },
  MY_CREATIONS_CLOSED: {
    key: 'MY_CREATIONS_CLOSED',
    listType: LIST_TYPES.ACTIVITY,
  },
  MY_PARTICIPATIONS_ALL: {
    key: 'MY_PARTICIPATIONS_ALL',
    listType: LIST_TYPES.ACTIVITY,
  },
};

export const CHANNEL_DATA_KEYS = {
  OPEN: {
    key: 'OPEN',
    listType: LIST_TYPES.PRIORITY,
  },
  CLOSED: {
    key: 'CLOSED',
    listType: LIST_TYPES.ACTIVITY,
  },
};

export const MILESTONE_DATA_KEYS = {
  OPEN: {
    key: 'OPEN',
    listType: LIST_TYPES.PRIORITY,
  },
  CLOSED: {
    key: 'CLOSED',
    listType: LIST_TYPES.ACTIVITY,
  },
};

export const PEOPLE_DATA_KEYS = {
  OPEN: {
    key: 'OPEN',
    listType: LIST_TYPES.PRIORITY,
  },
};

export const NAV_KEYS = {
  ALL_ITEMS: {
    key: 'ALL_ITEMS',
    // listType: LIST_TYPES.ITEM,
    listTitle: 'All Items',
    text: 'All Items',
  },
  ALL: {
    key: 'ALL',
    // listType: LIST_TYPES.ITEM,
    listTitle: 'All',
    text: 'All',
  },
  NOTIF_IMP: {
    key: 'NOTIF_IMP',
    // listType: LIST_TYPES.ITEM,
    listTitle: 'Important',
    text: 'Important',
  },
  NOTIF_GENERAL: {
    key: 'NOTIF_GENERAL',
    // listType: LIST_TYPES.ITEM,
    listTitle: 'General',
    text: 'General',
  },
  NOTIF_READ: {
    key: 'NOTIF_READ',
    // listType: LIST_TYPES.ITEM,
    listTitle: 'Read',
    text: 'Read',
  },
  ALL_MY_ITEMS: {
    key: 'ALL_MY_ITEMS',
    // listType: LIST_TYPES.ITEM,
    listTitle: 'All My Items',
    text: 'All My Items',
  },
  UNORGANISED: {
    key: 'UNORGANISED',
    // listType: LIST_TYPES.ITEM,
    listTitle: 'Unorganised',
    text: 'Unorganised',
  },
  OPEN: {
    key: 'OPEN',
    listType: LIST_TYPES.PRIORITY,
    listTitle: 'Open Items',
    text: 'Open Items',
  },
  SCHEDULED: {
    key: 'SCHEDULED',
    listType: LIST_TYPES.PRIORITY,
    listTitle: 'Scheduled Items',
    text: 'Scheduled',
  },
  UNSCHEDULED: {
    key: 'UNSCHEDULED',
    listType: LIST_TYPES.PRIORITY,
    listTitle: 'Unscheduled Items',
    text: 'Unscheduled',
  },
  CLOSED: {
    key: 'CLOSED',
    listType: LIST_TYPES.ACTIVITY,
    listTitle: 'Closed Items',
    text: 'Closed Items',
  },
  ASSIGNED_TO_ME: {
    key: 'ASSIGNED_TO_ME',
    listType: LIST_TYPES.PRIORITY,
    listTitle: 'Assigned To Me',
    text: 'Assigned To Me',
  },
  OWNED_BY_ME: {
    key: 'OWNED_BY_ME',
    listType: LIST_TYPES.PRIORITY,
    listTitle: 'Owned By Me',
    text: 'Owned By Me',
  },
  MY_WORK_HISTORY: {
    key: 'MY_WORK_HISTORY',
    listType: LIST_TYPES.ACTIVITY,
    listTitle: 'My Assignment History',
    text: 'My Assignment History',
  },
  MY_CREATIONS_OPEN: {
    key: 'MY_CREATIONS_OPEN',
    listType: LIST_TYPES.ACTIVITY,
    listTitle: 'Created By Me - Open',
    text: 'Created By Me - Open',
  },
  MY_CREATIONS_CLOSED: {
    key: 'MY_CREATIONS_CLOSED',
    listType: LIST_TYPES.ACTIVITY,
    listTitle: 'Created By Me - Closed',
    text: 'Created By Me - Closed',
  },
  MY_PARTICIPATIONS_ALL: {
    key: 'MY_PARTICIPATIONS_ALL',
    listType: LIST_TYPES.ACTIVITY,
    listTitle: 'All My Items',
    text: 'All My Items',
  },
  NOTIFICATIONS: {
    key: 'NOTIFICATIONS',
    listType: LIST_TYPES.NOTIFICATIONS,
    listTitle: 'Notifications',
    text: 'Notifications',
  },
  SEARCH: {
    key: 'SEARCH',
    // listType: LIST_TYPES.ITEM,
    listTitle: 'Search',
    text: 'Search',
  },
  UNPLANNED: {
    // listType: LIST_TYPES.ACTIVITY,
    key: 'UNPLANNED',
    listTitle: 'Unplanned',
    text: 'Unplanned',
  },
  PLANNED: {
    // listType: LIST_TYPES.ACTIVITY,
    key: 'PLANNED',
    listTitle: 'Planned',
    text: 'Planned',
  },
};

export const STATUS_OPTIONS_MAP = {
  UNSCHEDULED: {
    key: 'UNSCHEDULED',
    text: 'Unscheduled',
  },
  SCHEDULED: {
    key: 'SCHEDULED',
    text: 'Scheduled',
  },
  URGENT: {
    key: 'URGENT',
    text: 'Urgent',
  },
  CLOSED: {
    key: 'CLOSED',
    text: 'Closed',
  },
  REOPENED: {
    key: 'REOPENED',
    text: 'Reopened',
  },
};

export const LANDING_URI =
  PAGES.MY_WORK.link +
  '/' +
  NAV_KEYS.ASSIGNED_TO_ME.key +
  '/' +
  NAV_KEYS.SCHEDULED.key;
