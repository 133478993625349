import { Person, WS_ToolTip } from '../../../UtilComponents/TinyComponents';
import { useContext } from 'react';
import { WorkspaceContext, PageContext } from '../../../../utils/contexts';
import { memberSorter } from '../../../../utils/sortUtils';
import MemberSelectorMenu from '../Common/MemberSelectorMenu';
import ApiClient from '../../../../utils/classes/ApiClient';
import store from '../../../../store';
import { useToggleState } from '../../../../utils/hook';
import { LANDING_URI } from '../../../../utils/consts';
import history from '../../../../history';
import { DeleteDialog } from '../../../UtilComponents/MUICustom';
import { WS_InfoIcon } from '../../../UtilComponents/Icons';

export default function MilestoneMembersSection() {
  let { milestone } = useContext(PageContext);

  let { membersMap, meId } = useContext(WorkspaceContext);

  let milestoneMemberIds = milestone.memberIds;
  milestoneMemberIds.sort(memberSorter(membersMap, meId));

  let milestoneId = milestone.id;
  function memberSelectionHandler(memberId) {
    ApiClient.post('milestone/addMember', {
      memberId,
      milestoneId,
    }).then(
      (res) => {
        store.dispatch({
          activity: res.activity,
        });
      },
      (error) => {
        throw error;
      }
    );
  }

  return (
    <div className="flex flex-col gap-4 hovered-semi-active">
      <div className="flex justify-between header">
        <div className="flex gap-4">
          <div className="field-header">Members</div>
          <MemberSelectorMenu
            handleUpdate={memberSelectionHandler}
            placeHolderText="Add New Member"
            type="text"
            buttonDecorators="font-light text-xs anchor tracking-wide hovered-active"
          />
        </div>
        <div className="text-xs px-4">
          <WS_ToolTip
            contents={
              <div>
                Only members can add
                <br />
                items to milestones or access
                <br />
                milestone view
              </div>
            }
            className="glass"
            placement="left"
          >
            <div>
              <WS_InfoIcon />
            </div>
          </WS_ToolTip>
        </div>
      </div>
      <div className="flex flex-col gap-4 channel-members">
        {milestoneMemberIds.map((each) => {
          return (
            <div className="flex gap-4" key={`milestone_member_${each}`}>
              <Person id={each} />
              {each === meId ? (
                <LeaveMilestoneOptionWithDialog milestoneId={milestone.id} />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function LeaveMilestoneOptionWithDialog(props) {
  let { milestoneId } = props;

  const [open, toggle] = useToggleState();

  const handleLeave = () => {
    return ApiClient.post('milestone/leave', { milestoneId }).then((res) => {
      store.dispatch({ activity: res.activity });
      history.push(LANDING_URI);
    });
  };

  let dialogMessage = (
    <>You will not be able to access the milestone view or add items to it.</>
  );
  return (
    <div>
      <span
        onClick={toggle}
        className="anchor text-xs tracking-wide hovered-active"
      >
        Leave Milestone
      </span>
      <DeleteDialog
        handleDelete={handleLeave}
        handleClose={toggle}
        dialogMessage={dialogMessage}
        open={open}
        buttonText="Confirm"
        titleText="Leave Milestone?"
        className="ws-surface"
      />
    </div>
  );
}
