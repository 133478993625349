import { editWorkStatus } from '../../../../utils/api';
import { useContext } from 'react';
import { WorkspaceContext } from '../../../../utils/contexts';
import ItemStatusSelector from './ItemStatusSelector';

export default function StatusInfo(props) {
  let { work } = props;

  let { setItemSuccessSnackBarParams, activeItemCurrentPosition } = useContext(
    WorkspaceContext
  );

  function handleStatusUpdate(newStatus) {
    return () => {
      editWorkStatus(work.id, newStatus, activeItemCurrentPosition).then(() => {
        setItemSuccessSnackBarParams({
          workId: work.id,
          message: 'Item updated successfully!',
        });
      });
    };
  }

  return (
    <div className="flex flex-col gap-1 items-center w-full">
      <div className="field-header darker pb-1">Status</div>
      <div className="hl-text w-full text-center">
        <ItemStatusSelector
          placeHolderText="Select Status"
          selectHandler={handleStatusUpdate}
          selectedId={work.status}
          buttonDecorators="centered"
        />
      </div>
    </div>
  );
}
