import React from 'react';
import { connect } from 'react-redux';
import { DISMISS_ALERT } from '../../redux/actions/AppActionTypes';
import store from '../../store';
import { AppErrorSnackBar } from './MUICustom';

const AlertContainer = ({ alerts }) => {
  return (
    <div className="alert-container">
      {alerts.map((alert, index) => (
        <Alert key={index} alert={alert} />
      ))}
    </div>
  );
};

function Alert(props) {
  let { alert } = props;

  if (!alert) {
    return null;
  }

  function dismissAlert() {
    store.dispatch({
      type: DISMISS_ALERT,
      alert,
    });
  }

  return (
    <AppErrorSnackBar open={true} close={dismissAlert}>
      {alert.message}
    </AppErrorSnackBar>
  );
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts,
  };
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(AlertContainer);
