import axios from '../axios';
import config from 'config';
import store from '../../store';
import {
  CLOSE_LOADER,
  TOGGLE_LOADER,
} from '../../redux/actions/AppActionTypes';
import { ARCHIVE_FEED_ENDPOINT, ARCHIVE_NOTIF_ENDPOINT } from '../consts';

const apiBaseURL = config.apiBaseURL;

export default class ApiClient {
  static post(endPoint, data) {
    let showLoader = true;
    if (
      endPoint === ARCHIVE_FEED_ENDPOINT ||
      endPoint === ARCHIVE_NOTIF_ENDPOINT
    ) {
      showLoader = false;
    }

    if (showLoader) {
      store.dispatch({ type: TOGGLE_LOADER });
    }

    return axios
      .post(apiBaseURL + '/' + endPoint, data)
      .then((res) => {
        if (showLoader) {
          store.dispatch({ type: TOGGLE_LOADER });
        }

        return res;
      })
      .catch((e) => {
        store.dispatch({ type: CLOSE_LOADER });
        throw e;
      });
  }

  static get(endPoint, showLoader = false) {
    if (showLoader) {
      store.dispatch({ type: TOGGLE_LOADER });
    }

    return axios.get(apiBaseURL + '/' + endPoint).then((res) => {
      if (showLoader) {
        store.dispatch({ type: TOGGLE_LOADER });
      }

      return res;
    });
  }
}
