import { PAGES, NAV_KEYS, LIST_TYPES } from '../../../utils/consts';
import { PageContext } from '../../../utils/contexts';
import { NavLink } from 'react-router-dom';
import V2PageWithItemList from './_Layout/V2PageWithItemList';
import {
  RecentItemsList,
  ScheduledItemsList,
  PlannedItemsList,
} from '../../UtilComponents/TinyComponents';
import {
  getDefaultPriorityGroupNav,
  getPriorityGroupCounts,
} from '../../../utils';
import MilestoneDetails from '../Components/MilestoneDetails/MilestoneDetails';
import { useEffect, useState } from 'react';

export default function MilestoneHome(props) {
  let { match, milestone, pageData } = props;

  let { l1Nav = null } = match.params;

  let counts = getPriorityGroupCounts(pageData[NAV_KEYS.OPEN.key]);

  const [derivedL1Nav, setL1Nav] = useState(derivedL1Nav);

  useEffect(() => {
    if (!l1Nav) {
      l1Nav = getDefaultPriorityGroupNav(counts);
    }
    setL1Nav(l1Nav);
  }, [l1Nav]);

  if (!derivedL1Nav) {
    return null;
  }

  let pageDataKey =
    derivedL1Nav === NAV_KEYS.CLOSED.key
      ? NAV_KEYS.CLOSED.key
      : NAV_KEYS.OPEN.key;
  let groupedEntries = pageData[pageDataKey];

  let pageContext = {
    pageKey: PAGES.MILESTONE.key,
    pageId: milestone.id,
    blockKey: { type: derivedL1Nav },
    groupedListEntries: groupedEntries,
    milestone,
  };

  let l1NavLinks = [NAV_KEYS.SCHEDULED.key, NAV_KEYS.PLANNED.key];

  let ListAreaComponent;
  if (NAV_KEYS[pageDataKey].listType === LIST_TYPES.ACTIVITY) {
    ListAreaComponent = RecentItemsList;
  } else {
    ListAreaComponent =
      derivedL1Nav === NAV_KEYS.SCHEDULED.key
        ? ScheduledItemsList
        : PlannedItemsList;
  }

  return (
    <PageContext.Provider value={pageContext}>
      <div className="flex flex-col px-4 py-4 gap-2 h-full">
        <div className="nav-strip">
          {l1NavLinks.map((eachNavKey) => {
            let additionalClass = '';
            if (derivedL1Nav === eachNavKey) {
              additionalClass = 'active';
            } else if (!counts[eachNavKey]) {
              additionalClass = 'inactive';
            }
            return (
              <NavLink
                to={PAGES.MILESTONE.link + milestone.id + '/' + eachNavKey}
                className={`cursor-pointer hovered-active ${additionalClass}`}
                key={eachNavKey}
              >
                {NAV_KEYS[eachNavKey].text} {counts[eachNavKey]}
              </NavLink>
            );
          })}
          <NavLink
            to={PAGES.MILESTONE.link + milestone.id + '/' + NAV_KEYS.CLOSED.key}
            className={`flex-auto text-right cursor-pointer hovered-active ${
              derivedL1Nav === NAV_KEYS.CLOSED.key ? 'active' : ''
            }`}
          >
            {NAV_KEYS.CLOSED.text}
          </NavLink>
        </div>
        <V2PageWithItemList
          MainArea={() => <MilestoneDetails />}
          ListArea={ListAreaComponent}
        />
      </div>
    </PageContext.Provider>
  );
}
