import { useContext, useEffect, useState } from 'react';
import { PAGES, NAV_KEYS, PRIMARY_DATA_KEYS } from '../../../utils/consts';
import { PageContext, WorkspaceContext } from '../../../utils/contexts';
import { NavLink } from 'react-router-dom';
import V2PageWithItemList from './_Layout/V2PageWithItemList';
import {
  ScheduledItemsList,
  PlannedItemsList,
  UnplannedItemsList,
  UnorganisedItemsList,
} from '../../UtilComponents/TinyComponents';
import {
  getDefaultPriorityGroupNav,
  getPriorityGroupCounts,
} from '../../../utils';

export default function MyWork(props) {
  let { match } = props;

  let { l1Nav = NAV_KEYS.ASSIGNED_TO_ME.key } = match.params;

  let l1NavLinksAndIds = {
    [NAV_KEYS.ASSIGNED_TO_ME.key]: 'assigned-to-me-link',
    [NAV_KEYS.OWNED_BY_ME.key]: 'owned-by-me-link',
  };

  return (
    <div className="flex flex-col px-4 py-4 gap-2 h-full">
      <div className="nav-strip my-work-main-nav">
        {Object.keys(l1NavLinksAndIds).map((eachNavKey) => {
          let elementId = l1NavLinksAndIds[eachNavKey];
          return (
            <NavLink
              to={PAGES.MY_WORK.link + '/' + eachNavKey}
              className={`cursor-pointer hovered-active ${
                l1Nav === eachNavKey ? 'active' : ''
              }`}
              key={eachNavKey}
              id={elementId}
            >
              {NAV_KEYS[eachNavKey].text}
            </NavLink>
          );
        })}
      </div>
      <MyWorkSub {...props} />
    </div>
  );
}

function MyWorkSub(props) {
  let { match } = props;

  let { l1Nav = NAV_KEYS.ASSIGNED_TO_ME.key, l2Nav = null } = match.params;

  let { pageData, workDetailsMap } = useContext(WorkspaceContext);

  let myPageData = pageData[PRIMARY_DATA_KEYS.MY_STREAM];
  let groupedEntries = myPageData[l1Nav];
  let counts = getPriorityGroupCounts(groupedEntries);

  const [derivedL2Nav, setL2Nav] = useState(l2Nav);

  useEffect(() => {
    if (!l2Nav) {
      l2Nav = getDefaultPriorityGroupNav(counts);
    }
    setL2Nav(l2Nav);
  }, [l2Nav, l1Nav]);

  let filteredGroupedEntries = {};

  let [filterChannelId, setFilterChannelId] = useState(NAV_KEYS.ALL_ITEMS.key);

  if (!derivedL2Nav) {
    return null;
  }

  let channelGroupedItemList = {
    ...groupedEntries[NAV_KEYS.UNPLANNED.key],
  };

  let unplannedItemList =
    groupedEntries[NAV_KEYS.UNPLANNED.key][NAV_KEYS.ALL_ITEMS.key];
  if (!unplannedItemList) {
    unplannedItemList = [];
  }

  if (derivedL2Nav === NAV_KEYS.UNPLANNED.key) {
    for (let itemId of unplannedItemList) {
      let workChannelIds = workDetailsMap[itemId].channelIds;
      for (let eachChannelId of workChannelIds) {
        if (!channelGroupedItemList[eachChannelId]) {
          channelGroupedItemList[eachChannelId] = [];
        }
        channelGroupedItemList[eachChannelId].push(itemId);
      }
    }
  }

  // overwrite unplanned item list
  filteredGroupedEntries = {
    ...groupedEntries,
    [NAV_KEYS.UNPLANNED.key]: channelGroupedItemList,
  };

  let pageContext = {
    pageKey: PAGES.MY_WORK.key,
    blockKey: { type: l1Nav + '_' + derivedL2Nav },
    groupedListEntries: filteredGroupedEntries,
    filterChannelId,
  };

  let ListAreaComponent;

  if (derivedL2Nav === NAV_KEYS.SCHEDULED.key) {
    ListAreaComponent = ScheduledItemsList;
  } else if (derivedL2Nav === NAV_KEYS.PLANNED.key) {
    ListAreaComponent = PlannedItemsList;
  } else if (derivedL2Nav === NAV_KEYS.UNPLANNED.key) {
    ListAreaComponent = UnplannedItemsList;
  } else {
    ListAreaComponent = UnorganisedItemsList;
  }

  let l1NavLinksAndIds = {
    [NAV_KEYS.SCHEDULED.key]: 'scheduled-link',
    [NAV_KEYS.PLANNED.key]: 'planned-link',
    [NAV_KEYS.UNPLANNED.key]: 'unplanned-link',
    [NAV_KEYS.UNORGANISED.key]: 'unorganised-link',
  };

  return (
    <PageContext.Provider value={pageContext}>
      <div className="flex flex-col gap-2 h-full flex-auto min-h-0">
        <div className="nav-strip" id="my-work-sec-nav">
          {Object.keys(l1NavLinksAndIds).map((eachNavKey) => {
            let additionalClass = '';
            if (derivedL2Nav === eachNavKey) {
              additionalClass = 'active';
            } else if (!counts[eachNavKey]) {
              additionalClass = 'inactive';
            }

            return (
              <NavLink
                to={PAGES.MY_WORK.link + '/' + l1Nav + '/' + eachNavKey}
                className={`cursor-pointer hovered-active ${additionalClass}`}
                key={eachNavKey}
                id={l1NavLinksAndIds[eachNavKey]}
              >
                {NAV_KEYS[eachNavKey].text} ({counts[eachNavKey]})
              </NavLink>
            );
          })}
        </div>
        {derivedL2Nav === NAV_KEYS.UNPLANNED.key && unplannedItemList.length ? (
          <ChannelFilter
            channelGroupedItemList={channelGroupedItemList}
            filterChannelId={filterChannelId}
            setFilterChannelId={setFilterChannelId}
          />
        ) : null}
        <V2PageWithItemList
          MainArea={() => <div />}
          ListArea={ListAreaComponent}
        />
      </div>
    </PageContext.Provider>
  );
}

function ChannelFilter(props) {
  let { allChannelsMap } = useContext(WorkspaceContext);

  let { channelGroupedItemList, setFilterChannelId, filterChannelId } = props;

  function handleChannelClick(selectedChannelId) {
    return () => {
      setFilterChannelId(selectedChannelId);
    };
  }

  return (
    <div className="nav-strip flex-wrap">
      {Object.keys(channelGroupedItemList).map((eachKey) => {
        let count = channelGroupedItemList[eachKey].length;
        let text;
        if (eachKey === NAV_KEYS.ALL_ITEMS.key) {
          text = NAV_KEYS.ALL_ITEMS.text;
        } else if (eachKey === NAV_KEYS.UNORGANISED.key) {
          text = NAV_KEYS.UNORGANISED.text;
        } else {
          text = allChannelsMap[eachKey].name;
        }

        return (
          <div
            className={`w-1/10 flex gap-1 cursor-pointer hovered-active ${
              filterChannelId === eachKey ? 'active' : ''
            }`}
            key={eachKey}
            onClick={handleChannelClick(eachKey)}
          >
            <div className="ellipsis">{text}</div>
            <div>({count})</div>
          </div>
        );
      })}
    </div>
  );
}
