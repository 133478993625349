import ApiClient from './classes/ApiClient';
import store from '../store';
import { ARCHIVE_FEED, ARCHIVE_NOTIF } from '../redux/actions/AppActionTypes';
import { ARCHIVE_FEED_ENDPOINT, ARCHIVE_NOTIF_ENDPOINT } from './consts';
import { WORK_ACTIVITY_TYPE } from './activityUtils';

export function handleEditAssignee(workId, itemPlaceholderPosId, newMemberId) {
  return ApiClient.post('work/editAssignee', {
    memberid: newMemberId,
    workId,
  }).then(
    (res) => {
      store.dispatch({
        activity: res.activity,
        itemPlaceholderPosId,
      });
    },
    (error) => {
      throw error;
    }
  );
}

export function handleEditOwner(workId, itemPlaceholderPosId, newMemberId) {
  return ApiClient.post('work/editOwner', {
    memberid: newMemberId,
    workId,
  }).then(
    (res) => {
      store.dispatch({
        activity: res.activity,
        itemPlaceholderPosId,
      });
    },
    (error) => {
      throw error;
    }
  );
}

export function editWorkSchedule(
  workId,
  newScheduleTimestamp,
  itemPlaceholderPosId
) {
  return ApiClient.post('work/editSchedule', {
    workId,
    scheduleTimestamp: newScheduleTimestamp,
  }).then((res) => {
    store.dispatch({
      activity: res.activity,
      itemPlaceholderPosId,
    });
  });
}

export function editWorkMilestone(
  workId,
  milestoneId,
  isPrivate,
  itemPlaceholderPosId
) {
  return ApiClient.post('work/editMilestone', {
    workId,
    milestoneId,
    isPrivate,
  }).then((res) => {
    store.dispatch({
      activity: res.activity,
      isPrivate,
      itemPlaceholderPosId,
    });
  });
}

export function createItem(data) {
  return ApiClient.post('workspace/addWork', data).then((res) => {
    store.dispatch({ work: res, activity: res.activity[0] });
    return res;
  });
}

export function updateMilestoneDate(milestoneId, dateText) {
  return ApiClient.post('milestone/editEndDate', {
    milestoneId,
    dateText,
  }).then((res) => {
    store.dispatch({
      activity: res.activity,
    });
    return res;
  });
}

export function archiveFeedForItem(workIds, feedTypeKey, blockKeyType) {
  let activityTypes = [feedTypeKey];
  if (WORK_ACTIVITY_TYPE[feedTypeKey].derived) {
    // override activity type with parent's in case this is a derived one
    activityTypes = WORK_ACTIVITY_TYPE[feedTypeKey].derivedFromKeys;
  }

  ApiClient.post(ARCHIVE_FEED_ENDPOINT, {
    workIds,
    activityTypes,
  }).then(
    () => {
      store.dispatch({
        type: ARCHIVE_FEED,
        workIds,
        activityTypes,
        blockKeyType,
      });
    },
    (error) => {
      throw error;
    }
  );
}

export function archiveNotif(activityId) {
  ApiClient.post(ARCHIVE_NOTIF_ENDPOINT, {
    activityId,
  }).then(
    () => {
      store.dispatch({
        type: ARCHIVE_NOTIF,
        activityId,
      });
    },
    (error) => {
      throw error;
    }
  );
}

export function editWorkStatus(workId, status, itemPlaceholderPosId) {
  // Example custom promise return
  // let res = {
  //   workId,
  //   newVal: status,
  //   oldVal: STATUS_OPTIONS.CLOSED,
  //   createdBy: UserSession.getCurrentMemberId(),
  //   createdAt: +new Date(),
  //   type: WORK_ACTIVITY_TYPE.STATUS_EDIT.key,
  //   id: uniqueId(),
  // };
  // return Promise.resolve().then(() => {
  return ApiClient.post('work/editStatus', {
    workId,
    status,
  }).then((res) => {
    store.dispatch({
      activity: res.activity,
      itemPlaceholderPosId,
    });
  });
}
