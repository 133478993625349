class UserSession {
  static setCurrentUser(userId, apiKey) {
    window.localStorage.setItem('userId', userId);
    window.localStorage.setItem('apiKey', apiKey);
    window.localStorage.removeItem('verifyUserId');
  }

  static getApiKey() {
    return window.localStorage.getItem('apiKey');
  }

  static getCurrentUserId() {
    return window.localStorage.getItem('userId');
  }

  static getVerifyUserId() {
    return window.localStorage.getItem('verifyUserId');
  }

  static reset() {
    window.localStorage.clear();
  }

  static setVerifyUser(verifyUserId) {
    window.localStorage.setItem('verifyUserId', verifyUserId);
  }

  static setCurrentMember(memberId, workspaceId, token) {
    window.localStorage.setItem('currentMemberId', memberId);
    window.localStorage.setItem('currentWorkspaceId', workspaceId);
    window.localStorage.setItem('memberToken', token);
  }

  static getMemberToken() {
    return window.localStorage.getItem('memberToken');
  }

  static getCurrentWorkspaceId() {
    return window.localStorage.getItem('currentWorkspaceId');
  }

  static getCurrentMemberId() {
    return window.localStorage.getItem('currentMemberId');
  }

  static clearMembership() {
    window.localStorage.removeItem('currentMemberId');
    window.localStorage.removeItem('currentWorkspaceId');
    window.localStorage.removeItem('memberToken');
  }
}

export default UserSession;
